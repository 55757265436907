import { useState, useRef, useEffect } from "react";
import Button from "../../elements/Button";
import Input from "../../elements/Input";
import Title from "../../elements/Title";
import SubTitle from "../../elements/SubTitle";
import Modal from "../../component/modal";
import { BackArrow, Locksvg, Spinner } from "../../assest/svgs";
import OTP from "../../component/OTP";
import { useLocation, useNavigate } from "react-router-dom";
import { PageLabel } from "../../constant/PageLabels";
import { useIsMutating } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import {
    GenerateOTPData,
    ResetPasswordData,
    VerifyOTPData,
} from "../../service/api/auth";
import { validateEmail, validateIsNumAndAlp } from "../../utils";
import RouteNames from "../../constant/RouteNames";

const OtpForgotPassword = () => {
    const location = useLocation()
    const { state } = location
    const [timer, setTimer] = useState(60);
    const [startTimer, setStartTimer] = useState(false);

    useEffect(() => {
        setStartTimer(true)
        let intervalId;

        if (startTimer) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        if (timer === 0) {
            clearInterval(intervalId);
        }

        // Clear the interval when the component is unmounted or when startTimer is set to false
        return () => clearInterval(intervalId);
    }, [startTimer, timer]);


    const [count, setcount] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const [subtitle, setSubTitles] = useState("");
    const [resendClick, setResendClick] = useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [errors, setErrors] = useState({
        email: { state: null, errorMessage: "" },
        password: { state: null, errorMessage: "" },
        cnfpassword: { state: null, errorMessage: "" },
    });
    const [forgotpwdModel, setforgotpwdModel] = useState({
        email: state?.email,
    });

    const handleBack = () => {
        setOTP(["", "", "", "", "", ""])
    };

    const formSubmit = (e) => {
        e.preventDefault();
        if (otp.join("").length == 6) {
            verifyotpapi.mutate({
                email: state?.email,
                otp: 1234,
                // otp.join(''),
                context: "RESET_PASSWORD",
            });
            return;
        } else {
            return;
        }
    }


    const handleModal = () => {
        setShowModal(false);
        if (count == 1 && !resendClick) {
            setcount(2);
            return;
        }
        if (count == 2) {
            navigate("/");
        }
    };

    const generateotpapiResp = (data) => {
        if (data?.success === true) {
            setIsBtnDisabled(true);
            setStartTimer(true);
            let title = PageLabel?.modalText?.OTP_TITLE;
            let subtitle = PageLabel?.modalText?.OTP_DESC;
            setTitle(title);
            setSubTitles(subtitle);
            setShowModal(true);
        } else {
            toast.error("Something went wrong Please try After Sometime");
        }
    };
    const verifyotpapiResp = (data) => {
        if (data?.success === true) {
            setIsBtnDisabled(true);
            setResendClick(false);
            let authdata = {
                accessToken: data?.data?.data?.temporaryToken,
            };
            localStorage.setItem("authdata", JSON.stringify(authdata));
            navigate("/" + RouteNames.FORGOT_PASSWORD + "/" + RouteNames.SET_NEW_PASSWORD, { state: forgotpwdModel })
        }
    };

    const generateotpapi = GenerateOTPData(generateotpapiResp);
    const verifyotpapi = VerifyOTPData(verifyotpapiResp);



    const [otp, setOTP] = useState(["", "", "", "", "", ""]);

    const inputRefs = useRef([]);

    // Handle OTP change event
    const handleOTPChange = (e, index) => {
        const value = e.target.value;
        const updatedOTP = [...otp];
        updatedOTP[index] = value;
        setOTP(updatedOTP);

        // If the input value is numeric and not empty,
        // move focus to the next input field
        if (/^\d*$/.test(value) && value.length < 2 && index < 5) {
            const nextInputRef = inputRefs.current[index + 1];
            if (nextInputRef) {
                nextInputRef.focus();
            }
        }
    };

    // Handle keydown event for backspace/delete key
    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" || e.key === "Delete") {
            if (index > 0 && e.target.value === "") {
                e.preventDefault();
                const previousInputRef = inputRefs.current[index - 1];
                if (previousInputRef) {
                    previousInputRef.focus();
                }
            }
        }
    };
    useEffect(() => {
        const value = otp.join("");

        if (value.length < 6) {
            setIsBtnDisabled(true);
        } else {
            setIsBtnDisabled(false);
        }
    }, [otp]);


    const otpResend = () => {
        setResendClick(true);
        setTimer(60)
        if (timer === 0) {
            setOTP(["", "", "", "", "", ""]);
            generateotpapi.mutate({
                email: forgotpwdModel?.email,
                context: "VERIFY_EMAIL",
            });
        } else {
            toast("Please wait for timer to finish");
        }
    };

    const isMutating = useIsMutating();
    return (
        <>
            <Modal
                type="confirmation"
                showModal={showModal}
                setShowModal={setShowModal}
                handleModal={handleModal}
                title={title}
                SVG={Locksvg}
                subtitle={subtitle}
            />
            <section className="w-full grid items-center ">
                <form className="mt-20 p-4" onSubmit={formSubmit}>
                    <div
                        className="flex items-center gap-1  w-fit cursor-pointer mb-6"
                        onClick={handleBack}
                    >
                        <BackArrow />
                        <SubTitle className="font-semibold ">
                            {PageLabel?.buttonText?.BACK}
                        </SubTitle>
                    </div>
                    <Title className="text-3xl font-semibold not-italic">
                        {PageLabel?.buttonText?.VERIFY_OTP}
                    </Title>
                    <SubTitle className="text-['#555555']">
                        {PageLabel?.formHeaders?.FGT_PWD_SUBTEXT}
                    </SubTitle>
                    <OTP
                        otp={otp}
                        handleOTPChange={handleOTPChange}
                        handleKeyDown={handleKeyDown}
                        inputRefs={inputRefs}
                        timer={timer}
                        resendClick={otpResend}
                    />
                    <div className="flex justify-end">
                        <Button className="bg-primaryblack text-white w-1/6 max-w-fit"
                            disabled={isBtnDisabled}
                        >
                            {PageLabel?.formHeaders?.VERIFY_OTP}
                            {isMutating > 0 ? <Spinner /> : <></>}
                        </Button>
                    </div>
                </form>
            </section>
        </>
    );
};

export default OtpForgotPassword;
