import React, { ReactNode, MouseEventHandler, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  Ripple,
  initTE,
} from "tw-elements";


interface ButtonProps {
  name?:string;
  type: "button" | "submit" | "reset" | undefined;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
  disabled: boolean|undefined;
}

const Button = (props: ButtonProps): JSX.Element => {
  const { type, onClick, children,name ,disabled} = props;
  const disabledClasses = disabled ? 'opacity-75 cursor-not-allowed' : '';

  const classes = twMerge(
    `relative m-0 block min-x-fit flex-auto whitespace-nowrap rounded-lg flex justify-center items-center border border-solid border-neutral-300 bg-clip-padding p-1 text-sm my-2 leading-[2.15] text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary px-6`,
    props.className,
    disabledClasses
  );
    useEffect(() => {
    initTE({ Ripple });
  }, [])

  return (<button
      type={type}
      data-te-ripple-init
      data-te-ripple-color="light"
      onClick={onClick}
      className={classes}
      name={name}
      disabled={disabled} 
      
      >
      {children}
    </button>
  );
}

export default Button;