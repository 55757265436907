import React, { useState } from 'react'
import Card from '../../../../elements/Cards'
import Title from '../../../../elements/Title'
import SubTitle from '../../../../elements/SubTitle'
import { PlusSvg, DownArrowSvg, MinusSvg } from '../../../../assest/svgs'
import Button from '../../../../elements/Button'
import AddRemoveCard from '../../../AddRemoveCard'
import { Tree, TreeNode } from 'react-organizational-chart';
import DialogTooltip from '../../../Dialog'
import OnboardingPresenter from '../OnboardingPresenter'
import Input from '../../../../elements/Input'
const OrganisationStructure = (props) => {
    const {
        datas,
        data,
        onClickNodes,
        NestedList,
        setisEditableTree
    } = AddremoveTree()

    return (
        <>
            <Card className="w-full m-auto bg-[#F4F4F4]  overflow-auto ">
                <div className=' p-4'>
                    <Tree
                        lineHeight={'20px'}
                        lineWidth={'2px'}
                        lineColor={'black'}
                        lineBorderRadius={'10px'}
                        label={
                            <div className='w-full flex items-center justify-center'
                            >
                                <div className=' w-fit mx-auto min-w-fit max-w-full w-30%'>
                                    <DialogTooltip
                                        data={datas}
                                        keys={data}
                                    >
                                        <div className='p-4  w-fit mx-auto min-w-fit max-w-full w-30%  bg-primaryblack text-white rounded-lg '>
                                            {data[0]?.key}

                                        </div>
                                    </DialogTooltip>

                                    <div className='w-full flex justify-center my-1 '
                                    >
                                        <div
                                            className='cursor-pointer'
                                            onClick={() => onClickNodes(data)}
                                        >
                                            <PlusSvg />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    >
                        <NestedList data={data[0]?.children} />
                    </Tree>

                </div>
            </Card>

        </>
    )
}

export default OrganisationStructure


const AddremoveTree = () => {
    const [isEditable, setisEditable] = useState(false);

    const [data, setData] = useState([
        {
            "hierarchyId": "3ccace30-12a2-11ee-a1a5-fbdefcf9de3c",
            "parentNode": "0",
            "name": "Template2",
            "templateId": "3c973b10-12a2-11ee-a1a5-fbdefcf9de3c",
            "leavePolicyId": null,
            "status": null,
            "level": null,
            "createdAt": null,
            "updatedAt": null,
            "id": "3ccace30-12a2-11ee-a1a5-fbdefcf9de3c",
            "key": "Template2",
            "parentId": "0",
            "children": [
                {
                    "hierarchyId": "4b670a30-12a2-11ee-a1a5-fbdefcf9de3c",
                    "parentNode": "3ccace30-12a2-11ee-a1a5-fbdefcf9de3c",
                    "name": " joe",
                    "templateId": "3c973b10-12a2-11ee-a1a5-fbdefcf9de3c",
                    "leavePolicyId": null,
                    "status": null,
                    "level": null,
                    "createdAt": null,
                    "updatedAt": null,
                    "id": "4b670a30-12a2-11ee-a1a5-fbdefcf9de3c",
                    "key": " joe",
                    "parentId": "3ccace30-12a2-11ee-a1a5-fbdefcf9de3c",
                    "children": [
                        {
                            "hierarchyId": "4f16f2d0-12a2-11ee-a1a5-fbdefcf9de3c",
                            "parentNode": "4b670a30-12a2-11ee-a1a5-fbdefcf9de3c",
                            "name": " joan",
                            "templateId": "3c973b10-12a2-11ee-a1a5-fbdefcf9de3c",
                            "leavePolicyId": null,
                            "status": null,
                            "level": null,
                            "createdAt": null,
                            "updatedAt": null,
                            "id": "4f16f2d0-12a2-11ee-a1a5-fbdefcf9de3c",
                            "key": " joan",
                            "parentId": "4b670a30-12a2-11ee-a1a5-fbdefcf9de3c",
                            "children": [
                                {
                                    "hierarchyId": "a55f1d20-12a2-11ee-a1a5-fbdefcf9de3c",
                                    "parentNode": "4f16f2d0-12a2-11ee-a1a5-fbdefcf9de3c",
                                    "name": " sdsd",
                                    "templateId": "3c973b10-12a2-11ee-a1a5-fbdefcf9de3c",
                                    "leavePolicyId": null,
                                    "status": null,
                                    "level": null,
                                    "createdAt": null,
                                    "updatedAt": null,
                                    "id": "a55f1d20-12a2-11ee-a1a5-fbdefcf9de3c",
                                    "key": " sdsd",
                                    "parentId": "4f16f2d0-12a2-11ee-a1a5-fbdefcf9de3c",
                                    "children": null
                                }
                            ]
                        }
                    ]
                },
            {
                    "hierarchyId": "ace0f550-12a2-11ee-a1a5-fbdefcf9de3c",
                    "parentNode": "3ccace30-12a2-11ee-a1a5-fbdefcf9de3c",
                    "name": " hi",
                    "templateId": "3c973b10-12a2-11ee-a1a5-fbdefcf9de3c",
                    "leavePolicyId": null,
                    "status": null,
                    "level": null,
                    "createdAt": null,
                    "updatedAt": null,
                    "id": "ace0f550-12a2-11ee-a1a5-fbdefcf9de3c",
                    "key": " hi",
                    "parentId": "3ccace30-12a2-11ee-a1a5-fbdefcf9de3c",
                    "children": null
                }
            ]
        }
    ]);

    const onClickNodes = (id) => {
        const newObj = {
            id: parseInt(Math.random() * 1000000),
            key: `Child ${parseInt(Math.random() * 100)}`,
        };
        addChildObject(id?.id, newObj);
    };

    const deleteNode = (node) => {
        let nodeId = node?.id;
        const removeNode = (nodes) => {
            return nodes.filter((node) => {
                if (node.id === nodeId) {
                    return false; // Exclude the node from the resulting array
                } else if (node.children) {
                    node.children = removeNode(node.children);
                }
                return true; // Include the node in the resulting array
            });
        };

        setData((prevData) => {
            const newData = { ...prevData };
            newData.children = removeNode(newData.children);
            return newData;
        });
    };

    const updateNode = (id, newKey) => {
        const findAndModifyKey = (node) => {
          if (node.id === id) {
            // Update the key value
            return { ...node, key: newKey, name: newKey };
          }
      
          if (node.children) {
            // Recursively search in children
            return {
              ...node,
              children: node.children.map((child) => findAndModifyKey(child)),
            };
          }
      
          return node;
        };
      
        // Find and modify the key in the data object
        const updatedData = findAndModifyKey(data[0]);
        setData([updatedData]);
      };
    const NestedList = ({ data }) => {
        return data.map((item,index) => {
            const { key, id, children } = item;
            return (
                <TreeNode
                    label={
                        <div className="w-full" key={index}>
                            <div className="w-fit mx-auto min-w-fit max-w-full w-30%">
                                <div className="w-full flex justify-center">
                                    <DownArrowSvg />
                                </div>
                                <div className="w-fit relative">
                                    {isEditable === false ? (
                                        <div className="absolute -right-2 -top-2 z-10">
                                            <MinusSvg handleClick={() => deleteNode(item)} />
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div>
                                        <DialogTooltip data={datas} keys={item}>
                                            <div className="p-4 w-fit mx-auto min-w-fit max-w-full w-30% bg-primaryblack text-white rounded-lg z-0 opacity-90">
                                                {key}
                                            </div>
                                        </DialogTooltip>
                                    </div>
                                </div>
                                {isEditable === false ? (
                                    <div className="w-full flex justify-center my-1 ">
                                        <div
                                            className="cursor-pointer"
                                            onClick={() => onClickNodes(item)}
                                        >
                                            <PlusSvg />
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    }
                >
                    {children && <NestedList data={children} />}
                </TreeNode>
            );
        });
    };


    const addChildObject = (id, object) => {
        const findChild = (node) => {
          if (node.id === id) {
            if (node.children) node.children.push(object);
            else node.children = [object];
            return true;
          }
          if (node.children) {
            return node.children.some(findChild);
          }
          return false;
        };
      
        setData((prevData) => {
          const newData = { ...prevData };
          if (Array.isArray(newData)) {
            // Handle case when data is an array
            newData.some(findChild);
          } else {
            // Handle case when data is a single object
            findChild(newData);
          }
          return newData;
        });
      };
      
    const [NodeText, setNodeText] = useState("");
    const HandleNodeText = (e) => {
        setNodeText(e?.target?.value);
    };

    const UpdateNodeText = (keyid) => {
        updateNode(keyid, NodeText);
    };

    const datas = (key) => {
        return (
            <>
                <div className=" flex gap-3 items-center">
                    <div>
                        <Input
                            type="text"
                            placeholder={key?.key}
                            value={NodeText}
                            onChange={HandleNodeText}
                            labeltext="Name the Tile"
                        />
                    </div>
                    <div onClick={() => UpdateNodeText(key?.id)}>
                        <Button
                            type="button"
                            className="border bg-primaryblack text-white h-fit mt-6"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </>
        );
    };

    return {
        datas,
        data,
        onClickNodes,
        NestedList,
        isEditable,
        setisEditable,
    };
};
