const formHeaders = {
  SIGNIN_HEADER: 'Welcome Back !',
  SIGNUP_HEADER: 'Sign up Account',
  HAVE_ACCOUNT: 'Have an account?',
  DONT_HAVE_ACCOUNT: 'Don’t have an account?',
  SIGN_IN: 'Sign in',
  SIGN_UP: 'Sign up',
  SIGN_UP_CREATE_SUBTEXT:
    'Creating an account allows you to access exclusive features and personalize your experience on our website. Simply provide the following details to get started.',
  ORG_DETAILS: 'Organization Details',
  ORG_SUBTEXT:
    'Provide us with key information about your organization and set up your account password to get started',
  FGT_PWD: 'Forgot Password',
  FGT_PWD_SUBTEXT: 'Enter your Email ID to get OTP to Reset Your Password.',
  VERIFY_OTP: 'Verify OTP',
  VERIFY_OTP_SUBTEXT:
    'Please enter the OTP received to your email/phone number',
  SET_PWD: 'Set Password',
  SET_PWD_SUBTEXT:
    'Set up a password that complies with our policy to keep your account secure.',
  FGT_USR_ID: 'Forgot User ID',
  FGT_USR_ID_SUBTEXT: 'Enter your Email ID to get OTP to Retrieve your User ID',
  ARCHICTECTURE: 'Architecture Creation  ',
  ARCHICTECTURE_SUBTEXT:
    'Choose from the pre-made organization framework templates. Existing templates can be used directly, edited, or new structures can be made.  ',
  DO_YOU_HAVE_A_ACCOUNT: 'Dont have a Account ?',
  HAVE_AN_ACCOUNT: 'Have an account?',
  SET_UP_YOUR_PASSWORD:
    ' Set up a password that complies with our policy to keep your account secure.',
}

const buttonText = {
  SIGNUP_UP: 'Sign Up',
  SIGNUP_UP_GOOGLE: 'Sign up with Google',
  SIGNUP_UP_OUTLOOK: 'Sign up with Microsoft',
  SIGNIN: 'Sign in',
  SIGNIN_GOOGLE: 'Sign in with Google',
  SIGNIN_OUTLOOK: 'Sign in with Microsoft',
  NEXT: 'Next',
  BACK: 'Back',
  SUBMIT: 'Submit',
  VERIFY_OTP: 'Verify OTP',
  PROCEED: 'Proceed',
  SAVE_DRAFT: 'Save Draft',
  SKIP: 'Skip',
  RESTORE: 'Restore',
  APPLY: 'Apply',
  YES: 'Yes',
  NO:'No',
  SAVE: 'Save',
  UPDATE:'Update',
  CLOSE:"Close"
}

const modalText = {
  OTP_TITLE: 'OTP Sent',
  OTP_DESC:
    'A One-Time-Password sent to your registered Email ID. Please check.',
  SUCCESSFUL_TITLE: 'Verified Successfully',
  SUCCESSFUL_TITLE_DESC:
    'Your User ID is _ . Please login your account by using User ID.',
  WELCOME_TITLE: 'Welcome To FRS Retail',
  WELCOME_SUBTITLE:
    "To onboard your organisation, Please follow the steps outlined on this page, which include filling out the organisation profile form, creating an organisation structure, setting up attendance policies and, customising organisation settings. We're here to help you every step of the way.",
  EXPORT: 'Export',
  EXPORT_TYPE: 'Export Type',
  SYNC_WITH_FTP_SERVER: 'Sync with FTP Server',
  HOST: 'Host',
  CONGRATULATIONS: 'Congratulations',
  CONGRATULATIONS_SUBTITLE:
    'Congratulations on completing onboarding!The next step is Role Management and User Management, where you will define roles, manage access, and add users.',
  DELETE_TEMPLATE: 'Are You Sure You Want to delete Template',
}

const inputlabels = {
  USER_ID: 'Login ID',
  PWD: 'Password',
  CAPTCHA: 'Captcha',
  REMEMBER_ME: 'Remember me',
  EMAIL_PLACEHOLDER: 'Enter your email',
  ORG_NAME: 'Organization Name',
  ORG_NAME_PLACEHOLDER: 'Enter your organization name',
  ORG_SECTOR: 'Organization Sector',
  ORG_SIZE: 'Organization Size',
  SUB_DOMAIN: 'Sub Domain',
  OTP: 'OTP',
  NEW_PWD: 'New Password',
  CNF_PWD: 'Confirm Password',
  USER_ID_PLACEHOLDER: 'Enter your Email ID/ User ID',
  PWD_PLACEHOLDER: 'Enter Password',
  ENTER_CAPTCHA: "Enter Captcha",
  TERMS_AND_CONDITION: 'Terms and Conditions',
  EMAIL_ID_MOBILE: 'Email ID/Mobile',
  EMAIL_ID_MOBILE_PLACEHOLDER: 'Enter Your Email/Mobile',

  CLOCK_PLACEHOLDER: 'HH:MM am',
  EXCEPTIONAL_DAYS: 'Exceptional Days',
  EXCEPTION_TYPE: 'Exception Type',
  MONTH_FROM: 'Month From',
  MONTH_TO: 'Month To',
  DAY: 'Day',
  TIME: 'Time',
  WORKING_NON_WORKING:
    'Set working day/ non working days timings that deviate from the original time table',
  EXCEPTIONAL: 'Exceptional',
  WEEK: 'Week',
  ADD_EXCEPTIONAL_DAYS: 'Add Exceptional day',
  BREAK_TIME: 'Break time',
  FIXED: 'Fixed',
  FLEXIBLE: 'Flexible',
  NO_OF_HOURS_FOR_PRESENT: 'No.of Hours for Present',
  MIN_ATTENDANCE_PER: 'Minimum Attendance percentage(%)',
  PERCENTAGE: 'Percentage(%)',
  LONGITUDE: 'Longitude',
  LATITUDE: 'Latitude',
  ENTER_LONGITUDE: 'Enter Longitude',
  ENTER_LATITUDE: 'Enter Latitude',
  LOCATION_RESTRICTION: 'location Restriction ',
  METERS: 'Meters',
  MAIN_BRANCH_LOCATION: 'Main Branch Location',
  MAIN_BRANCH_LOCATION_PLACEHOLDER: 'Enter your main branch location',
  ADMIN_NAME: 'Admin Name',
  OFFICIAL_EMAIL_ID: 'Offical Email ID',
  OFFICIAL_MOBILE_NUMBER: 'Offical Mobile Number',
  OFFICIAL_MOBILE_NUMBER_PLACEHOLDER: 'Enter your offical mobile number',
  OFFICIAL_LANDLINE_NUMBER: 'Offical Landline Number',

  OFFICIAL_LANDLINE_NUMBER_PLACEHOLDER: 'Enter your offical landline number',
  SCHOOL_ADDRESS_1: 'School Address 1',
  SCHOOL_ADDRESS_1_PLACEHOLDER: 'Enter Address 1',
  SCHOOL_ADDRESS_2: 'School Address 2',
  SCHOOL_ADDRESS_2_PLACEHOLDER: 'Enter Address 2',
  ORGANIZATION_IMAGE_LOGO: 'Organisation image logo',
  LOGIN_IMAGE: 'login image',
  NEW_TILE_1: 'New Tile 1',
  NAME_THE_NEW_NODE: 'Name the New Node',
  NAME_THE_NEW_TILE: 'Name the New Tile',
  NAME_THE_TILE: 'Name the Tile',
}

const commonLabels = {
  EMAIL: 'Email',
  OTP: 'OTP',
  BACK: 'Back',
  NAME: 'Name',
  MOBILE: 'Mobile Number',
  PWD: 'Password',
  CNFPWD: 'Confirm Password',
  PWD_PLCY: 'Password policy',
  USERID: 'User id',
  REMEMBERME: 'Remember me',
  CREATE_ACCOUNT: 'Create Account',
  RESEND: 'Resend',
  PRIVACY_POLICY: 'Privacy Policy',
  FORGOT: 'Forgot',
  DASHBOARD: 'Dashboard',
  ENROLLMENT: 'Enrollment',
  TODAY_ATTENDANCE: 'Today Attendance',
  DETAIL_ANALYTICS: 'Detail Analytics',
  PERSON_NAME: 'Person Name',
  SUBJECT_ROLE: 'subject/role',
  REPORTS: 'Reports',
  PERFORMANCE_REPORT: 'Performance Report',
  HIGH: 'High',
  LOW: 'Low',
  WORK_SCHEDULE: 'Work Schedule',
  WORK_DAYS: 'Working Days',
  SELECT_WORKING_DAYS:
    'Select the working days of your organization. You can configure the timings to each day or for all.',
  APPLY_TO_ALL: 'Apply to all',
  CONFIGURE_THE_BREAK:
    'Configure the break timings on a working day. If there are multiple breaks to be configured click on add.',
  ADD_FIXED_TIME: 'Add Fixed Time',
  NO_PERIODS_PER_DAY: 'No.of Periods per day',
  DURATION_OF_EACH_PERIOD: 'Duration of Each Period',
  ATTENDANCE: 'Attendance',
  NUMBER_OF_HRS_TO_BE_PRESENT: 'Number of hours to be marked present',
  NUMBER_OF_HRS_FOR_HALF_DAY: 'No.of Hours for Half Day Present',
  NUMBER_OF_HRS_MARKED_PRESENT:
    'Number of hours to be marked present for half day',
  SET_THE_MIN_PER:
    'Set the minimum percentage needed for attendance as well as the duration for which the attendance percentage will be calculated.',
  GEO_LOCATION: 'Geo Location',
  MAP_CONFIG_MUL_COR:
    'Configure multiple coordinates at which attendees can mark attendance.',
  MULTI_GEO_COR_1: 'Multi geo coordinates 1',
  ADD_MULTI_GEO: 'Add Multi Geo',
  CONFIG_MUL_3_COR:
    'Configure multiple coordinates with in which attendees can mark attendance. Minimum required is 3 coordinates.',
  POLY_GEO_COR_1: 'Polygon geo coordinates 1',
  POLY_GEO_COR: 'Polygon geo coordinates',

  SHIFT_MANAGEMENT: 'Shift Management',
  SHIFT_MAPPING: 'Shift Mapping',
  CONFIG_THE_SHIFT_TIMING: 'Configure the shift timings for the employees',
  SHIFT_MAPPING_PAGE: 'Click here to Shift Mapping page.',
  TO: 'to',
  ON_BOARDING_PROCESS: 'On-boarding Process',
  SETTINGS: 'Settings',
  BASIC_DETAILS: 'Basic Details',
  ONBOARD_YOUR_ORGANISATION_PROFILE:
    'To onboard your organization, we require basic information. We keep all information confidential and use it to tailor our services to meet your business needs.',
  NO_DATA_FOUND: 'No data Found',
  GEO_FENCING: 'Geo Fencing',
  LOGOUT:'Logout'
}

export const PageLabel = Object.freeze({
  formHeaders,
  buttonText,
  modalText,
  inputlabels,
  commonLabels,
})
