import Axios from "./index";

export async function  getAPI  (url, body)  {
  const { data}=await Axios.get(url)
  return data
};

export async function  postAPI  (url, body)  {
  const { data}=await Axios.post(url, body)
  return data
};
