import * as actionTypes from './types';

export const storeDynamicImages = (payload) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.DYNAMIC_IMAGE_LOGIN,
            payload: payload
        })
    }
}

export const storeOnboardingSteps = (payload) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.ONBAORDING_STEPS,
            payload: payload
        })
    }
}
