import React, { useState, useEffect } from 'react';
import { NavLink, Outlet, } from 'react-router-dom';
import Card from '../../../elements/Cards';
import Title from '../../../elements/Title';
import { useSelector } from 'react-redux';
import CommonStatuesObj from '../../../constant/CommonStatues';
import { GreenTicksvg, PrevStep } from '../../../assest/svgs';
import tabsdata from '../../../routes/tabsdata';

const TabLayout = (props) => {
    const onboardingSteps = useSelector(
        (state) => state.MainReducer.onboardingSteps,
    )

    const { flag, type } = props
    const [tabMenu, setabMenu] = useState([])

    useEffect(() => {
        let data = tabsdata[type].tabmenu
        setabMenu([...data])
    }, [props])



    const tabMenuUpdate = tabMenu.map(item => {
        item.status = (onboardingSteps[item?.path]?.status === CommonStatuesObj?.COMPLETED)
        return item
    });


    return (
        <div className="">
            <Title className="mb-2">{tabsdata[type].title}</Title>
            <ul className="flex flex-wrap text-[14px] font-medium text-center text-gray-500  border-b-[0.5px] border-[#CBCBCB] mb-2">
                {tabMenuUpdate?.map((i, idx) => {
                    return <>
                        <NavLink
                            to={i.path}
                            children={({ isActive }) => {
                                return <>
                                    {flag === true ? <li className={`${isActive ? "border-b-2 border-black " : ""} mr-2`}
                                        key={idx}
                                        title={i?.name}
                                        >
                                        <div className='flex flex-wrap items-center gap-2 hover:bg-slate-100 hover:text-black px-2 '>
                                            {(i.status === false) ? <span className={`border-2 w-6 h-6 rounded-full ${isActive ? 'border-black text-black' : ""} flex justify-center items-center `}>
                                                {idx + 1}
                                            </span>
                                                : <div> <GreenTicksvg className="w-4 h-4 " /></div>
                                            }
                                            <div className={`inline-block py-2   rounded-t-lg ${isActive ? ' text-black' : ""}`}  >{i?.name}  </div>
                                            <PrevStep />
                                        </div>
                                    </li> : <li className={`${isActive ? "text-white bg-primaryblack  " : "hover:bg-slate-100 hover:text-black"} mr-2 py-2 px-4 rounded-t-lg`}
                                        key={idx}
                                        title={i?.name}
                                        >
                                        {i.name}
                                    </li>
                                    }

                                </>
                            }


                            }
                        />
                    </>


                })}

            </ul>

            {props?.islayout===false?<></>:<Outlet />}
        </div>
    )
}

export default TabLayout;