import React, { useState } from 'react'
import DashboardFeatures from './DashboardFeatures'
import OneOneAppFeatures from './OneOneAppFeatures'
import OneNAppFeatures from './OneNAppFeatures'
import NNAppFeatures from './NNAppFeatures'

export const RolesAndPrivilegesPresenter = () => {
  const tabmenu = [
        { name: "Dashboard Features", component: <DashboardFeatures /> },
        { name: "1:1 App Features", component: <OneOneAppFeatures /> },
        { name: "1:N App Features", component: <OneNAppFeatures /> },
        { name: "N:N App Features", component: <NNAppFeatures /> },
  ]

  const [tabMenu, setabMenu] = useState(tabmenu)
  const [selectedTab, setselectedTab] = useState(tabmenu[0])

  const handleTab = (params) => {
    const temp = [...tabmenu].map((i, idx) => {
      if (i?.name === params?.name) i.selected = true
      else i.selected = false
      return i
    })
    setabMenu([...temp])
    setselectedTab(params)
  }

 
return { tabMenu, selectedTab, handleTab }
}
