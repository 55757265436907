import React, { useRef,useState, useEffect } from 'react';
import { LoadScript, GoogleMap, Marker } from "@react-google-maps/api";

const LIBRARIES = ["places"];

function Map(props) {
  return (
    <div className="rounded-lg overflow-hidden">
      <LoadScript googleMapsApiKey="AIzaSyBFI_nvfWLoRAzplSmuC68pmNOZ7EwonHE" libraries={LIBRARIES}>
        <GoogleMap
          id="map"
          center={props.currentLocation}
          zoom={15}
          mapContainerStyle={{ height: "30vh" }}
          onClick={props?.handleMapClick}
        >
          {/*  Marker component */}
          <Marker position={props?.center} draggable={true} onDragEnd={props?.handleMapClick} />
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

export default Map;