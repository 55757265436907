import React from "react";
import Input from "../../elements/Input";
import SubTitle from "../../elements/SubTitle";
import Title from "../../elements/Title";

const TitleSwitchParagraph = (props) => {
  const { text, subtext, toggle } = props;

  return (
    <>
      <div className="flex w-full md:w-10/12 lg:w-3/5 items-center justify-between ">
        <Title className="text-sm mt-4"> {text}</Title>
        {toggle && (
          <Input
            labeltext={""}
            placeholder={"••••••••"}
            type={"toggle"}
            // value={model.orgname}
            // onChange={HandleChange}
            name={"orgname"}
          />
        )}
      </div>
      <SubTitle className=" w-full md:w-3/5 lg:w-3/5 mt-2  text-justify"> {subtext}</SubTitle>
      <div className="w-full mt-4">{props.children}</div>
    </>
  );
};
export default TitleSwitchParagraph;
