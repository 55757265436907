import React from "react";
import Card from "../../../elements/Cards";
import Title from "../../../elements/Title";
import SubTitle from "../../../elements/SubTitle";
import Button from "../../../elements/Button";
import {
  ExportSvg,
  ImportSvg,
  DownloadSvg,
  OopsEmpty,
  PlusSvg,

} from '../../../assest/svgs'
import Select from "../../../elements/Input/Select";
import InputSearch from "../../../component/InputSearch";
import UserManagementPresenter from "../user-management-presenter";
import Tables from "../../../component/Table";

const Users = () => {
  const { userCreationData,usercreationColumns } = UserManagementPresenter();
  const handleClick = (second,third,fourth) => {  }
  return (
    <>
      <Card className="p-4  mt-4">
        <div className="flex justify-between">
          <div>
            <Title>Users</Title>
            <SubTitle className="w-4/5">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Delectus
              quibusdam quo consectetur sequi incidunt temporibus optio rem
              animi? Quasi, quam. Lorem ipsum dolor, sit amet consectetur
              adipisicing elit. Doloribus quis asperiores consequuntur
            </SubTitle>
            <div className="mt-1">
              <SubTitle className="flex gap-2 underline underline-offset-1">
                <DownloadSvg />
                Download Sample Excel File
              </SubTitle>
            </div>
          </div>
          <div className="flex gap-4">
            <Button className="flex gap-2 border max-h-[40px] ">
              <ExportSvg /> Export
            </Button>
            <Button className="flex gap-2 border max-h-[40px]">
              <ImportSvg />
              Import
            </Button>
          </div>
        </div>
        <div>
          <div className=" flex justify-center mt-4">
            <OopsEmpty />
          </div>
          <div className="text-center my-2">
            <Title>Oops! It’s Empty</Title>
            <SubTitle>Looks like you haven’t added any lead yet..!</SubTitle>
            <div className=" flex justify-center mt-4">
              <Button className="flex max-w-fit gap-2 bg-primaryblack ">
                <PlusSvg />
                <span className="text-white">Add</span>
              </Button>
            </div>
          </div>
        </div>
      </Card>
      <Card className="p-4 mt-4">
        <div className="flex justify-between">
          <div className="mb-3">
            <Title>Users</Title>
            <SubTitle className="w-4/5">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Delectus
              quibusdam quo consectetur sequi incidunt temporibus optio rem
              animi? Quasi, quam. Lorem ipsum dolor, sit amet consectetur
              adipisicing elit. Doloribus quis asperiores consequuntur
            </SubTitle>
            <div className="mt-1">
              <SubTitle className="flex gap-2 underline underline-offset-1">
                <DownloadSvg />
                Download Sample Excel File
              </SubTitle>
            </div>
          </div>
          <div className="flex gap-4 mb-3">
            <Button className="flex gap-2 border max-h-[40px] ">
              <ExportSvg /> Export
            </Button>
            <Button className="flex gap-2 border max-h-[40px]">
              <ImportSvg />
              Import
            </Button>
            <Button
              type="button"
              className="flex gap-2 max-h-[40px] items-center bg-primaryblack text-white"
            >
              <PlusSvg />
              Add
            </Button>
          </div>
        </div>
        <div className="flex justify-center overflow-x-auto">
          <Tables
            data={userCreationData}
            columns={usercreationColumns}
            pagination="true"
            search="true"
          />
        </div>
      </Card>
    </>
  );

};

export default Users;
