import React from "react";
import Card from "../../../elements/Cards";
import Title from "../../../elements/Title";
import SubTitle from "../../../elements/SubTitle";
import Button from "../../../elements/Button";
import { DownloadSvg, OopsEmpty } from "../../../assest/svgs";

import InputSearch from "../../../component/InputSearch";
import UserManagementPresenter from "../user-management-presenter";
import Select from "../../../elements/Input/Select";
import Input from "../../../elements/Input";
import Modal from "../../../component/modal";
import Tables from "../../../component/Table";

const ClassMapping = () => {
  const {
    handleModal,
    showModal,
    setshowModal,
    handleSubmit,
    classMapping,
    renderSubComponent,
    classMappingColumns
  } = UserManagementPresenter();
  return (
    <>
      <Modal
        type="rightside"
        showModal={showModal}
        setShowModal={setshowModal}
        handleModal={handleModal}
        handleSubmit={handleSubmit}
      >
        <>
          <div className="h-full mb-5 mt-5">
            <form className="mt-1" onSubmit={handleSubmit}>
              <Title className="text-lg font-semibold not-italic mb-1">
                Assign
              </Title>
              <SubTitle>
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing.
              </SubTitle>
              <div>
                <div className="mt-4">
                  <SubTitle className="font-medium text-[#555555]">
                    Map
                  </SubTitle>

                  <Select
                    className="w-3/4 mt-0"
                    defaultOption="Select option"
                  />
                </div>

                <div className="mt-4">
                  <SubTitle className="font-medium text-[#555555]">
                    Department
                  </SubTitle>

                  <Select
                    className="w-3/4 mt-0"
                    defaultOption="Select department"
                  />
                </div>
                <div className="mt-4">
                  {" "}
                  <SubTitle className="font-medium text-[#555555]">
                    Assign To
                  </SubTitle>
                  <Select
                    className="w-3/4 mt-0"
                    defaultOption="Class 1 > Section 1"
                  />
                </div>
                <div className="mt-4">
                  <SubTitle className="font-medium text-[#555555]">
                    Subject Name
                  </SubTitle>

                  <Select
                    className="w-3/4 mt-0"
                    defaultOption="English, Hindi, Telugu"
                  />
                </div>

                <div className="mt-4">
                  <SubTitle className="font-medium text-[#555555]">
                    Staff
                  </SubTitle>

                  <Select className="w-3/4 mt-0" defaultOption="Select Staff" />
                </div>
              </div>

              <div className="flex justify-end gap-3 mt-5">
                <Button
                  type="button"
                  className="flex max-h-[40px] max-w-fit items-center bg-primaryblack text-white "
                  onClick={handleModal}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </>
      </Modal>

      <Card className="p-4 h-screen mt-4">
        <div className="">
          <div>
            <Title>Class Mapping</Title>
            <SubTitle className="w-4/5">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Delectus
              quibusdam quo consectetur sequi incidunt temporibus optio rem
              animi? Quasi, quam. Lorem ipsum dolor, sit amet consectetur
              adipisicing elit. Doloribus quis asperiores consequuntur
            </SubTitle>
            <div className="mt-1">
              <SubTitle className="flex gap-2 underline underline-offset-1">
                <DownloadSvg />
                Download Sample Excel File
              </SubTitle>
            </div>
          </div>
        </div>
        <div>
          <div className="w-full flex justify-center mt-4">
            <OopsEmpty />
          </div>
          <div className="text-center ">
            <Title>Oops! It’s Empty</Title>
            <SubTitle>Looks like you haven’t added any lead yet..!</SubTitle>
            <div className=" flex justify-center mt-4">
              <Button
                className="flex gap-2 bg-primaryblack max-w-fit"
                onClick={handleModal}
              >
                <span className="text-[white]">Map Subject</span>
              </Button>
            </div>
          </div>
        </div>
      </Card>
      <Card className="p-4 flex justify-center">
      
          <Tables
            data={classMapping}
            columns={classMappingColumns}
            pagination="true"
            search="true"
          />
      
      </Card>
    </>
  );
};

export default ClassMapping;
