import { twMerge } from "tailwind-merge";
import Clock from "./Clock";
import Calender from "./Calender";
import Fileinput from "./Fileinput";
import ClockWithoutAMorPM from "./ClockWithoutAMorPM";
import { Select } from "./SelectInput";

const Input = (props) => {
  const {
    labeltext,
    type,
    value,
    error,
    errorMessage,
    className,
    mandatory,
    autoFocus,
  } = props

  const errorTag = (error, errorMessage) => {
    return <div className={`input-error ${error === true ? "text-red-600" : "text-green-500"}`} >
      {errorMessage}
    </div>

  }

  const renderInput = () => {

    switch (type) {
      case ("select"):
        return (<Select {...props} />)
      case ("calender"):
        return (<Calender {...props} />)
      case "file":
        return (<Fileinput {...props} />)
      case "clock":
        return (<Clock {...props} />)
      case "clockWithoutAMorPM":
        return (<ClockWithoutAMorPM {...props} />)
      case "checkbox":
        return (
          <>
            <div className="cursor-pointer">
              <div className="flex">
                <input
                  id={labeltext}
                  type="checkbox"
                  checked={value}
                  {...props}
                  className={`w-4 h-4 text-black-600 accent-[#000] border-gray-300 focus:ring-black-500 dark:focus:ring-black-600 dark:ring-offset-gray-800 dark:bg-gray-700 ${className}`}
                />
                <label
                  htmlFor={labeltext}
                  className={twMerge("decoration-solid ml-2 text-xs font-medium text-gray-900 dark:text-gray-300 hover:cursor-pointer", props.className)}
                >
                  {labeltext}
                  {mandatory ? <span className="text-red-600">*</span> : null}

                </label>
              </div>
              {errorTag(error, errorMessage)}
            </div>

          </>
        );
      case "toggle":
        return (
          <div className="flex">
            {labeltext && (
              <div htmlFor={labeltext} className="mr-3 text-gray-700 text-sm font-semibold">
                {labeltext}
                {mandatory ? <span className="text-red-600">*</span> : null}
              </div>
            )}

            <label className="relative inline-flex items-center cursor-pointer">
              <input
                id={labeltext}
                type="checkbox"
                checked={props?.checked}
                onChange={props?.onChange}
                onClick={props?.onChange}
                name={props?.name}
                className="hidden"
                

              />
              {props?.checked && props?.checked === true ? <div {...props}>
                <svg width="29" height="17" viewBox="0 0 29 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="29" height="17" rx="8.5" fill="#32A64D" fillOpacity="0.2" />
                  <rect x="14" y="2" width="13" height="13" rx="6.5" fill="#32A64D" />
                </svg>

              </div> : <div {...props}>
                <svg width="29" height="17" viewBox="0 0 29 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="29" height="17" rx="8.5" fill="#CFCFCF" />
                  <rect x="2" y="2" width="13" height="13" rx="6.5" fill="white" />
                </svg>

              </div>

              }

            </label>

            {errorTag(error, errorMessage)}
          </div>
        );
      case "radio":
        return (
          <div className="cursor-pointer">
            <div className="flex items-center">
              <input
                id={labeltext}
                type="radio"
                value=""
                checked={props?.checked}
                disabled={props?.disabled}
                className="cursor-pointer w-4 h-4 text-black-600 accent-[#000] border-gray-300 focus:ring-black-500 dark:focus:ring-black-600 dark:ring-offset-gray-800 dark:bg-gray-700"
                {...props}
              />
              {labeltext && (
                <label
                  htmlFor={props?.labeltext}
                  className="ml-2 text-xs cursor-pointer font-medium text-gray-900 dark:text-gray-300 text-[20 rem]"
                >
                  {labeltext}
                  {mandatory ? <span className="text-red-600">*</span> : null}

                </label>
              )}
            </div>
            {errorTag(error, errorMessage)}
          </div>
        );
      default:
        return (
          <div className="">
            {labeltext && (
              <label
                htmlFor={labeltext}
                className="block mb-0.5 text-xs py-1 font-medium text-[#344054] "
              >
                {labeltext}
                {mandatory ? <span className="text-red-600">*</span> : null}
              </label>
            )}
            <input
              id={labeltext}
              autoFocus={autoFocus}
              className={`input-body focus:outline-none , border-#D0D5DD-1px ${(error) ? "border-red-500" : (error != undefined && "border-green-500")}`}
              {...props}
              disabled={props?.disabled}
              autocomplete="off"
            />
            <div className={`input-error ${error === true ? "text-red-600" : "text-green-500"}`}>
              {errorMessage}
            </div>
          </div>
        );
    }
  };

  return <>{renderInput()}</>;
};

export default Input;
