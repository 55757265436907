import { useState, useEffect, useRef } from "react";
import { BackArrow, EmailSvg, OtpSentSvg, Spinner } from "../../assest/svgs";
import Title from "../../elements/Title";
import Input from "../../elements/Input";
import Button from "../../elements/Button";
import SubTitle from "../../elements/SubTitle";
import Select from "../../elements/Input/Select";
import { PageLabel } from "../../constant/PageLabels";
import { useIsMutating } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { Registerdata } from "../../service/api/auth/register";
import { validateIsRequired } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import RouteNames from "../../constant/RouteNames";

const SubmitForm = () => {
    const location = useLocation()
    const { state } = location
    const createAccountData = state?.model

    const [model, setModel] = useState({
        organizationName: "",
        organizationSector: "Select sector",
        organizationSize: "Select size",
        subDomain: "",
        tenantId: "567891235678456",
        checked: false,

    });
    // const [checkEmail, setCheckEmail] = useState(false)

    const registerResp = (data) => {
        if (data?.success === true) {
            setIsBtnDisabled(true);
            toast.success("Registered successfully");
            navigate("/");
        }
        // navigate('/')
    };


    const registerapi = Registerdata(registerResp);


    const [errors, setErrors] = useState({
        organizationName: { state: null, errorMessage: "" },
        organizationSector: { state: null, errorMessage: "" },
        organizationSize: { state: null, errorMessage: "" },
        subDomain: { state: null, errorMessage: "" },
        checked: { state: null, errorMessage: "" }
    });

    const [isBtnDisabled, setIsBtnDisabled] = useState(true);


    const checkError = () => {
        const checkerrorState = Object.keys(errors).filter((i, idx) => {
            if (errors[i]?.state != false)
                return i
        })

        let temperrors = {}
        checkerrorState.forEach((i, idx) => {
            let name = i
            if (name === 'checked') {
                temperrors[name] = { state: true, errorMessage: "Please accept Terms & Conditions" }
            }
            else {
                temperrors[name] = { state: true, errorMessage: "" }
            }
        })
        setErrors((prevState) => ({
            ...prevState,
            ...temperrors,
        }))

        if (errors.checked.state === null) {
            setErrors((prevState) => ({
                ...prevState,
                checked: {
                    state: true,
                    errorMessage: "Please Check terms and Conditions"
                },
            }));
            return false
        }

        if (errors.checked.state === false && model?.checked === false) {
            setErrors((prevState) => ({
                ...prevState,
                checked: {
                    state: true,
                    errorMessage: "Please Check terms and Conditions"
                },
            }));
            return false
        }

        if (checkerrorState?.length > 0) {
            return false
        }


        return true
    }

    const HandleChange = (e) => {
        const { name, value } = e?.target;
        if (name === "organizationName" || name === "subDomain") {
            const validName = validateIsRequired(value);
            setErrors((prevState) => ({
                ...prevState,
                [name]: validName,
            }));
        } if (name === "checked") {
            setErrors((prevState) => ({
                ...prevState,
                checked: {
                    state: false,
                    errorMessage: ""
                },
            }));
            setModel((prevModel) => ({
                ...prevModel,
                [name]: !model?.checked,
            }));
        }
        else {
            setModel((prevModel) => ({
                ...prevModel,
                [name]: value,
            }));
        }
    };




    const navigate = useNavigate();
    const [otp, setOTP] = useState(["", "", "", "", "", ""]);


    const formSubmit = (e) => {
        e.preventDefault();
        const isError = checkError()
        const {
            organizationName,
            organizationSector,
            organizationSize,
            subDomain,
            tenantId,
        } = model;

        if (isError) {
            registerapi.mutate({
                ...createAccountData,
                organizationName,
                organizationSector,
                organizationSize,
                subDomain: subDomain + ".frs.com",
                tenantId,
            });
        }

        //  }
    }

    const handleSector = (event) => {
        const selectedOption = event.target.value;
        setErrors((prevState) => ({
            ...prevState,
            organizationSector: {
                state: false,
            },
        }));
        setModel({ ...model, organizationSector: selectedOption });
    };
    const handleSize = (event) => {
        const selectedOption = event.target.value;
        setErrors((prevState) => ({
            ...prevState,
            organizationSize: {
                state: false,
            },
        }));
        setModel({ ...model, organizationSize: selectedOption });
    };

    const [timer, setTimer] = useState(60);
    const [startTimer, setStartTimer] = useState(false);
    useEffect(() => {
        let intervalId;

        if (startTimer) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        if (timer === 0) {
            clearInterval(intervalId);
        }

        // Clear the interval when the component is unmounted or when startTimer is set to false
        return () => clearInterval(intervalId);
    }, [startTimer, timer]);



    const handleBack = () => {
        navigate("/" + RouteNames.REGISTER + "/" + RouteNames.CREATE_ACCOUNT, { state: createAccountData })
    };


    let show = false;
    const isMutating = useIsMutating();

    return (
        <>
            <div
                className={`${isMutating > 0 ? "animate-pulse opacity-25 cursor-not-allowed " : ""
                    }`}
            >
                <section className="w-full grid items-center">
                    <form className="mt-20 p-6" onSubmit={formSubmit}>
                        <div
                            className="flex w-[10%] justify-around mb-8 cursor-pointer"
                            onClick={handleBack}
                        >
                            <div className="flex align-middle">
                                <BackArrow />
                            </div>
                            <div className="m-auto">
                                <SubTitle className="font-semibold">
                                    {PageLabel?.commonLabels?.BACK}
                                </SubTitle>
                            </div>
                        </div>
                        <Title className="text-3xl font-semibold not-italic">
                            {PageLabel?.formHeaders?.ORG_DETAILS}
                        </Title>
                        <SubTitle className="my-3">
                            {PageLabel?.formHeaders?.ORG_SUBTEXT}
                        </SubTitle>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 mb-3 ">
                            <Input
                                labeltext={PageLabel?.inputlabels?.ORG_NAME}
                                type={"text"}
                                value={model.organizationName}
                                onChange={HandleChange}
                                name={"organizationName"}
                                required
                            />

                            <div className="">
                                <Select
                                    labeltext={PageLabel?.inputlabels?.ORG_SECTOR}
                                    className="mt-0"
                                    value={model?.organizationSector}
                                    handleSelection={handleSector}
                                    options={["IT", "EDUCATION"]}
                                    error={errors.organizationSector.state}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 mb-3 ">
                            <div className="">
                                <Select
                                    labeltext={PageLabel?.inputlabels?.ORG_SIZE}
                                    className="mt-0"
                                    value={model?.organizationSize}
                                    options={["1-50", "50-1000", "1000-10000", "10000"]}
                                    handleSelection={handleSize}
                                    error={errors.organizationSize.state}
                                />
                            </div>

                            <div className="">
                                <label
                                    htmlFor={"subdmn"}
                                    className="block  text-xs font-semibold pl-1 py-1 text-gray-900"
                                >
                                    {PageLabel?.inputlabels?.SUB_DOMAIN}{" "}
                                </label>
                                <div className="flex">
                                    <div className="w-4/5">
                                        <Input
                                            className="rounded-none rounded-l-lg bg-gray-50 border text-gray-900 focus:ring-black focus:border-black block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 "
                                            type="text"
                                            id="website-admin"
                                            placeholder="abc"
                                            name="subDomain"
                                            value={model?.subDomain}
                                            onChange={HandleChange}
                                            sidebar=""
                                            required
                                        />
                                    </div>

                                    <span className="h-8 inline-flex items-center px-3 py-5 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-r-md ">
                                        .frs.com
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className=" mt-2 flex justify-between items-center gap-4 mb-3">
                            <Input
                                labeltext={PageLabel?.inputlabels?.TERMS_AND_CONDITION}
                                type={"checkbox"}
                                value={model?.checked}
                                onChange={HandleChange}
                                name={"checked"}
                                error={errors?.checked?.state}
                                errorMessage={errors?.checked?.errorMessage}
                            />
                            <SubTitle className="text-sm font-semibold decoration-slice underline text-[#187FF9]">
                                {PageLabel?.commonLabels?.PRIVACY_POLICY}
                            </SubTitle>
                        </div>

                        <div className="flex justify-end">
                            <Button
                                className="bg-primaryblack text-white max-w-fit
               rounded-lg"
                            // disabled={isBtnDisabled}
                            >
                                {PageLabel?.buttonText?.SIGNUP_UP}
                                {isMutating > 0 ? <Spinner /> : <></>}
                            </Button>
                        </div>
                    </form>
                </section>
            </div>

        </>
    );
};

export default SubmitForm;
