import React from "react";
import Title from "../../../elements/Title";
import SubTitle from "../../../elements/SubTitle";
import Card from "../../../elements/Cards";
import InputSearch from "../../../component/InputSearch";
import SchoolStructurePresenter from "../../../component/modal/Onboarding/OnboardingPresenter";
import AddRemoveCard from "../../../component/AddRemoveCard";
import Select from "../../../elements/Input/Select";
import Button from "../../../elements/Button";
import UserManagementPresenter from "../user-management-presenter";
import Modal from "../../../component/modal";
import Tables from "../../../component/Table";

const SubjectMaster = () => {
  const {
    MappedTiles,
    EmptyTile,
    inputTile,
    setinputTile,
    datas,
    data,
    onClickNodes,
    NestedList,
    DesignationsMappedTiles,
    DesignationsEmptyTile,
    DesignationsinputTile,
    DesignationssetinputTile,
  } = SchoolStructurePresenter();

  const { handleModal, showModal, setshowModal, handleSubmit, subjectMaster, renderSubComponent ,subjectMapping} =
    UserManagementPresenter();

  return (
    <>
      <Modal
        type="rightside"
        showModal={showModal}
        setShowModal={setshowModal}
        handleModal={handleModal}
        handleSubmit={handleSubmit}
      >
        <>
          <div className="h-full mb-5 mt-5">

            <form className="mt-1">
              <Title className="text-lg font-semibold not-italic mb-1">
                Assign
              </Title>
              <SubTitle>
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing.
              </SubTitle>
              <div>
                <div className="mt-3">
                  <SubTitle className="font-medium text-[#555555]">Subject</SubTitle>

                  <Select className="w-3/4 mt-0" defaultOption="Select subject" />
                </div>
                <div className="mt-3 mb-2">
                  <SubTitle className="font-medium text-[#555555]">Department</SubTitle>

                  <Select className="w-3/4 mt-0" defaultOption="Select department" />
                </div>
                <div className="mt-3 mb-2">
                  <SubTitle className="font-medium text-[#555555]">Staff</SubTitle>

                  <Select className="w-3/4 mt-0" defaultOption="Select staff" />
                </div>
              </div>

              <div className="flex justify-end gap-3 mt-2">
                <Button
                  type="button"
                  className="flex max-h-[40px] items-center bg-primaryblack text-white max-w-fit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </>


      </Modal>
      <div>
        <Card className="p-4 h-auto mt-4">
          <Title>Subject Master</Title>
          <SubTitle>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nemo itaque
            quos necessitatibus minus tempora, natus, corrupti dolor est optio,
            iure deleniti autem perspiciatis delectus saepe unde similique
            expedita excepturi placeat?
          </SubTitle>
          <div className="w-1/4">
            <InputSearch></InputSearch>
          </div>
          <div className="my-2 p-4 bg-[#F4F4F4] border rounded-lg">
            <AddRemoveCard
              MappedTiles={DesignationsMappedTiles}
              inputTile={DesignationsinputTile}
              EmptyTile={DesignationsEmptyTile}
              setinputTile={DesignationssetinputTile}
            />
          </div>
          <div className="w-1/4"></div>
          <div>
            <div className="flex justify-end items-center">
                <Button
                  type="button"
                  className="flex gap-2 max-h-[40px] items-center bg-primaryblack text-white max-w-fit"
                  onClick={handleModal}
                >
                  Map New
                </Button>
              
            </div>

            <div className="p-4 flex justify-center overflow-x-auto">
              <Tables
                data={subjectMaster}
                columns={subjectMapping}
                pagination="true"
                search="true"
              />
            </div>

          </div>

        </Card>
      </div>
    </>

  );
};

export default SubjectMaster;
