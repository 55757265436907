import { useState } from "react";
import Card from "../../elements/Cards";
import Users from "./Users";
import TransferPromotion from "./TransferPromotion";
import Shifts from "./Shifts";
import SubjectClassMapping from "./SubjectClassMapping";
import {
  CrossSvgForRejectedBtn,
  TickSvgForApproveBtn,
} from "../../assest/svgs";
import { DelteIcon, EditIcon, StatusIcon } from "../../assest/svgs";
import SubTitle from "../../elements/SubTitle";
import Button from "../../elements/Button";
import { createColumnHelper } from "@tanstack/react-table";
import Input from "../../elements/Input";

export default function UserManagementPresenter() {
  const twoIcon = () => {
    return (
      <>
        <div className="flex gap-4 justify-center">
          <div className="cursor-pointer">
            <DelteIcon/>
          </div>
          <div className="cursor-pointer">
            <EditIcon />
          </div>
        </div>
      </>
    )
  };


  const columnHelper = createColumnHelper();

  const handleClick = () => {
  };

  const usercreationColumns = [
    // Display Column
    // Grouping Column
    columnHelper.group({
      accessorKey: "teacherid",
      header: (props)=>(<div className="flex justify-start bg-[#F4F4F4] rounded-l-lg py-2 px-2">Teachers ID</div>),
      columns: [
        // Accessor Column
        columnHelper.accessor((row) => row.Teacher_ID, {
          id: "teacher_id",
          header: "",
          cell: (props) => (
            <div className="w-full flex justify-start  py-1 px-2" row={props.row}>
              {props.getValue()}
            </div>
          ),
           size:150,
          maxSize:500,
          minSize:200,
          isborder: true,
        
        }),
      ],
    }),

    columnHelper.group({
      accessorKey: "teachername",
      header: (props)=>(<div className="flex justify-start bg-[#F4F4F4] py-2 px-2">Teachers Name</div>),      columns: [
        // Accessor Column
        columnHelper.accessor((row) => row.Teacher_Name, {
          id: "teacher_name",
          header: "",
          cell: (props) => (
            <div className="w-full flex justify-start  py-1 px-2" row={props.row}>
              {props.getValue()}
            </div>
          ),
          size:200,
          maxSize:500,
          minSize:150,
          isborder: true,
        }),
      ],
    }),

    columnHelper.group({
      accessorKey:"emailid",
      header: (props)=>(<div className="flex justify-start bg-[#F4F4F4] py-2 px-5">Email ID</div>), 
      columns: [
        // Accessor Column
        columnHelper.accessor((row) => row.emailid, {
          id: "emailid",
          header: "",
          cell: (props) => (
            <div className="w-full flex justify-start  py-1 px-5" row={props.row}>
              {props.getValue()}
            </div>
          ),
          size:100,
          maxSize:500,
          minSize:50,
          isborder: true,
        }),
      ],
    }),
    columnHelper.group({
      accessorKey:"mobilenumber",
      header: (props)=>(<div className="flex justify-start bg-[#F4F4F4] py-2 px-5">Mobile Number</div>), 
      columns: [
        // Accessor Column
        columnHelper.accessor((row) => row.mobileno, {
          id: "mobileno",
          header: "",
          cell: (props) => (
            <div className="w-full flex justify-start  py-1 px-5" row={props.row}>
              {props.getValue( )}
            </div>
          ),
          size:150,
          maxSize:500,
          minSize:200,
          isborder: true,
        }),
      ],
    }),
    columnHelper.group({
      id:"privilages",
      header:(props)=>(<div className="flex justify-start bg-[#F4F4F4] py-2 px-5">Privileages</div>),
    
      columns: [
        // Accessor Column
        columnHelper.accessor((row) => row.dashboard, {
          id: "Dashboard",
          cell: (info) => (
            <div className="w-full flex justify-start  py-1 px-5">
              {info.getValue()}
            </div>
          ),
          header: () => <span >Dashboard Feature</span>,
          size:100,
          maxSize:500,
          minSize:50,
          isborder: true,
        }),
        columnHelper.accessor((row) => row.mobilefeature, {
          id: "mobilefeature",
          cell: (info) => (
            <div className="w-full flex justify-start  py-1 px-5">
              {info.getValue()}
            </div>
          ),
          header: () => <span>Mobile Feature</span>,
          size:100,
          maxSize:500,
          minSize:50,
          isborder: true,
        }),
        
      ],
      
    
    }),

    columnHelper.group({
      accessorKey:"designations",

      header: (props)=>(<div className="flex justify-start bg-[#F4F4F4] py-2 px-5">Designations</div>),
      columns: [
        // Accessor Column
        columnHelper.accessor((row) => row.designations, {
          id: "designations",
          header: "",
          cell: (props) => (
            <div
            className="w-full flex justify-start  py-1 px-5"       row={props.row}
              onClick={() => handleClick(props?.cell?.row.original)}
            >
              {props?.cell?.getValue()}{" "}
            </div>
          ),
          size:100,
          maxSize:500,
          minSize:50,
          isborder: true,
        }),
      ],
    }),
    columnHelper.group({
      accessorKey:"status",
      header: (props)=>(<div className="flex justify-start bg-[#F4F4F4] py-2 px-5">Status</div>),
      columns: [
        // Accessor Column
        columnHelper.accessor((row) => row.status, {
          id: "status",
          header: "",
          cell: (props) => (
            <div
            className="w-full flex justify-start  py-1 px-5"
            row={props.row}
              onClick={() => handleClick(props?.cell?.row.original)}
            >
              {props?.cell?.getValue()}{" "}
            </div>
          ),
          size:100,
          maxSize:500,
          minSize:50,
          isborder: true,
        }),
      ],
    }),
    columnHelper.group({
      accessorKey:"action",
      header: (props)=>(<div className="flex justify-start rounded-r-lg  bg-[#F4F4F4] py-2 px-5">Action</div>),
      columns: [
        // Accessor Column
        columnHelper.accessor((row) => row.action, {
          id: "Action",
          header: "",
          cell: (props) => (
            <div
            className="w-full flex justify-start  py-1 px-5"
              row={props.row}
              onClick={() => handleClick(props?.cell?.row.original)}
            >
              {props?.cell?.getValue()}{" "}
            </div>
          ),
          size:100,
          maxSize:500,
          minSize:50,
          isborder: true,
        }),
      ],
    }),
  ];
  const usercreation = () => [
    {
      Teacher_ID: "NE120443",
      Teacher_Name: "Narayana Education",
      emailid: "Teacher@gmail.com",
      mobileno: "+91 9342830239",
      dashboard: <TickSvgForApproveBtn />,
      mobilefeature:<TickSvgForApproveBtn />,
      designations: "HOD",
      status:(<><Input type="toggle"></Input></>),
      action: (
      <>
        <div className="flex gap-4 justify-center">
          <div className="cursor-pointer">
            <DelteIcon/>
          </div>
          <div className="cursor-pointer">
            <EditIcon />
          </div>
        </div>
      </>
    ),
    },
    {
      Teacher_ID: "NE120443",
      Teacher_Name: "Narayana Education",
      emailid: "Teacher@gmail.com",
      mobileno: "+91 9342830239",
      dashboard: <TickSvgForApproveBtn />,
     mobilefeature:<TickSvgForApproveBtn />,
      designations: "HOD",
      status:(<><Input type="toggle"></Input></>),

      action: (
      <>
        <div className="flex gap-4 justify-center">
          <div className="cursor-pointer">
            <DelteIcon/>
          </div>
          <div className="cursor-pointer">
            <EditIcon />
          </div>
        </div>
      </>
    ),
    },
    {
      Teacher_ID: "NE120443",
      Teacher_Name: "Narayana Education",
      emailid: "Teacher@gmail.com",
      mobileno: "+91 9342830239",
      dashboard: <TickSvgForApproveBtn />,
      mobilefeature:<TickSvgForApproveBtn />,
      designations: "HOD",
      status:(<><Input type="toggle"></Input></>),

      action: (
      <>
        <div className="flex gap-4 justify-center">
          <div className="cursor-pointer">
            <DelteIcon/>
          </div>
          <div className="cursor-pointer">
            <EditIcon />
          </div>
        </div>
      </>
    ),
    },
    {
      Teacher_ID: "NE120443",
      Teacher_Name: "Narayana Education",
      emailid: "Teacher@gmail.com",
      mobileno: "+91 9342830239",
      dashboard: <TickSvgForApproveBtn />,
      mobilefeature:<TickSvgForApproveBtn />,
      designations: "HOD",
      status:(<><Input type="toggle"></Input></>),

      action: (
      <>
        <div className="flex gap-4 justify-center">
          <div className="cursor-pointer">
            <DelteIcon/>
          </div>
          <div className="cursor-pointer">
            <EditIcon />
          </div>
        </div>
      </>
    ),
    },
    {
      Teacher_ID: "NE120443",
      Teacher_Name: "Narayana Education",
      emailid: "Teacher@gmail.com",
      mobileno: "+91 9342830239",
      dashboard: <TickSvgForApproveBtn />,
    mobilefeature:<TickSvgForApproveBtn />,
      designations: "HOD",
      status:(<><Input type="toggle"></Input></>),

      action: (
      <>
        <div className="flex gap-4 justify-center">
          <div className="cursor-pointer">
            <DelteIcon/>
          </div>
          <div className="cursor-pointer">
            <EditIcon />
          </div>
        </div>
      </>
    ),
    },
    {
      Teacher_ID: "NE120443",
      Teacher_Name: "Narayana Education",
      emailid: "Teacher@gmail.com",
      mobileno: "+91 9342830239",
      dashboard: <TickSvgForApproveBtn />,
      mobilefeature:<TickSvgForApproveBtn />,
      designations: "HOD",
      status:(<><Input type="toggle"></Input></>),

      action: (
      <>
        <div className="flex gap-4 justify-center">
          <div className="cursor-pointer">
            <DelteIcon/>
          </div>
          <div className="cursor-pointer">
            <EditIcon />
          </div>
        </div>
      </>
    ),
    },
    {
      Teacher_ID: "NE120443",
      Teacher_Name: "Narayana Education",
      emailid: "Teacher@gmail.com",
      mobileno: "+91 9342830239",
      dashboard: <TickSvgForApproveBtn />,
     mobilefeature:<TickSvgForApproveBtn />,
      designations: "HOD",
      status:(<><Input type="toggle"></Input></>),

      action: (
      <>
        <div className="flex gap-4 justify-center">
          <div className="cursor-pointer">
            <DelteIcon/>
          </div>
          <div className="cursor-pointer">
            <EditIcon />
          </div>
        </div>
      </>
    ),
    },
    {
      Teacher_ID: "NE120443",
      Teacher_Name: "Narayana Education",
      emailid: "Teacher@gmail.com",
      mobileno: "+91 9342830239",
      dashboard: <TickSvgForApproveBtn />,
      mobilefeature:<TickSvgForApproveBtn />,
      designations: "HOD",
      status:(<><Input type="toggle"></Input></>),

      action: (
      <>
        <div className="flex gap-4 justify-center">
          <div className="cursor-pointer">
            <DelteIcon/>
          </div>
          <div className="cursor-pointer">
            <EditIcon />
          </div>
        </div>
      </>
    ),
    },
  ];

  const shiftColumn = [
    {
      accessorKey: "Shift_Name",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] rounded-l-lg py-2 px-5">Shift Name</div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start  py-1 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 100,
      size: 200,
      isborder: true,
    },
    {
      accessorKey: "Start_Time",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] py-2 px-5">Start Time</div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start  py-1 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 100,
      size: 150,
      isborder: true,
    },

    {
      accessorKey: "End_Time",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4]  py-2 px-5">End Time</div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start  py-1 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 100,
      size: 200,
      isborder: true,
       },

    {
      accessorKey: "Working_Hrs",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] py-2 px-5">Working Hrs </div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start  py-1 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 100,
      size: 180,
      isborder: true,
    },

    {
      accessorKey: "Break",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] py-2 px-5">
          Break
        </div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start  py-1 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 150,
      size: 300,
      isborder: true,
    },
    {
      accessorKey: "Status",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] py-2 px-5">Status</div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start  py-1 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 80,
      size: 100,
      isborder: true,
    },
    {
      accessorKey: "Schedule",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4]  py-2 px-5">Schedule</div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start  py-1 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 80,
      size: 100,
      isborder: true,
    },
    {
      accessorKey: "Action",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] rounded-r-lg py-2 px-5">Action </div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start  py-1 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 80,
      size: 100,
    },
  ];
  const shifts = () => [
    {
      Shift_Name: "Morning Shift",
      Start_Time: "05:00 am",
      End_Time: "10:00 am",
      Working_Hrs: "10",
      Break: "Fixed (09:30 am - 06:30 pm)",
      Status: (
        <>
          <div className="flex gap-4 justify-center">
            <StatusIcon />
            <SubTitle>Active</SubTitle>
          </div>
        </>
      ),
      Schedule: (
        <>
          <div className="flex justify-center">
            <Button
              type="button"
              className="bg-yellowbg px-4 py-1 border rounded-xl"
            >
              Assign
            </Button>
          </div>
        </>
      ),
      Action: (
      <>
        <div className="flex gap-4 justify-center">
          <div className="cursor-pointer">
            <DelteIcon/>
          </div>
          <div className="cursor-pointer">
            <EditIcon />
          </div>
        </div>
      </>
    ),
    },
    {
      Shift_Name: "Morning Shift",
      Start_Time: "05:00 am",
      End_Time: "10:00 am",
      Working_Hrs: "10",
      Break: "Fixed (09:30 am - 06:30 pm)",
      Status: (
        <>
          <div className="flex gap-4 justify-center">
            <StatusIcon />
            <SubTitle>Active</SubTitle>
          </div>
        </>
      ),
      Schedule: (
        <>
          <div className="flex justify-center">
            <Button
              type="button"
              className="bg-yellowbg px-4 py-1 border rounded-xl"
            >
              Assign
            </Button>
          </div>
        </>
      ),
      Action: (
        <>
          <div className="flex gap-4 justify-center">
            <div className="cursor-pointer">
              <DelteIcon/>
            </div>
            <div className="cursor-pointer">
              <EditIcon />
            </div>
          </div>
        </>
      ),
    },
    {
      Shift_Name: "Morning Shift",
      Start_Time: "05:00 am",
      End_Time: "10:00 am",
      Working_Hrs: "10",
      Break: "Fixed (09:30 am - 06:30 pm)",
      Status: (
        <>
          <div className="flex gap-4 justify-center">
            <StatusIcon />
            <SubTitle>Active</SubTitle>
          </div>
        </>
      ),
      Schedule: (
        <>
          <div className="flex justify-center">
            <Button
              type="button"
              className="bg-yellowbg px-4 py-1 border rounded-xl"
            >
              Assign
            </Button>
          </div>
        </>
      ),
      Action: (
        <>
          <div className="flex gap-4 justify-center">
            <div className="cursor-pointer">
              <DelteIcon/>
            </div>
            <div className="cursor-pointer">
              <EditIcon />
            </div>
          </div>
        </>
      ),
    },
    {
      Shift_Name: "Morning Shift",
      Start_Time: "05:00 am",
      End_Time: "10:00 am",
      Working_Hrs: "10",
      Break: "Fixed (09:30 am - 06:30 pm)",
      Status: (
        <>
          <div className="flex gap-4 justify-center">
            <StatusIcon />
            <SubTitle>Active</SubTitle>
          </div>
        </>
      ),
      Schedule: (
        <>
          <div className="flex justify-center">
            <Button
              type="button"
              className="bg-yellowbg px-4 py-1 border rounded-xl"
            >
              Assign
            </Button>
          </div>
        </>
      ),
      Action: (
        <>
          <div className="flex gap-4 justify-center">
            <div className="cursor-pointer">
              <DelteIcon/>
            </div>
            <div className="cursor-pointer">
              <EditIcon />
            </div>
          </div>
        </>
      ),
    },
  ];

  const transferpromotionColumns = [
    {
      accessorKey: "Transaction_ID",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] rounded-l-lg py-2 px-5">
          Transaction_ID
        </div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start py-4 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 100,
      size: 100,
      isborder: true,
     
    },
    {
      accessorKey: "Performed_by",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] py-2 px-5">Performed By</div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start  py-4 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 80,
      size: 300,
      isborder: true,
    },

    {
      accessorKey: "Email_ID",
      header: (props) => <div className="flex justify-start bg-[#F4F4F4] py-2 px-5">Email ID</div>,
      cell: (props) => (
        <div className="w-full flex justify-start  py-4 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 80,
      size: 100,
      isborder: true,
    },

    {
      accessorKey: "Mobile_Number",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] py-2 px-5">Mobile Number</div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start  py-4 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 80,
      size: 100,
      isborder: true,
    },

    {
      accessorKey: "Type",
      header: (props) => <div className="flex justify-start bg-[#F4F4F4] py-2 px-5">Type</div>,
      cell: (props) => (
        <div className="w-full flex justify-start  py-4 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 80,
      size: 200,
      isborder: true,
    },
    {
      accessorKey: "Date",
      header: (props) => <div className="flex justify-start bg-[#F4F4F4] py-2 px-5">Date</div>,
      cell: (props) => (
        <div className="w-full flex justify-start  py-4 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 80,
      size: 200,
      isborder: true,
    },

    {
      accessorKey: "Action",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] py-2 px-5 rounded-r-lg">Action</div>
      ),
      cell: (props) => (
        <div
          className="w-full flex justify-start py-4 px-5 cursor-pointer"
          row={props.row}
        >
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 80,
      size: 100,
    },
  ];
  const transferpromotion = () => [
    {
      Transaction_ID: "NE120443",
      Performed_by: "Narayan Education",
      Email_ID: "anupamanand46@gmail.com",
      Mobile_Number: "+919342830239",
      Type: "Promotion",
      Date: "28/03/2023",
      Action: <EditIcon />,
    },
    {
      Transaction_ID: "NE120443",
      Performed_by: "Narayan Education",
      Email_ID: "anupamanand46@gmail.com",
      Mobile_Number: "+919342830239",
      Type: "Promotion",
      Date: "28/03/2023",
      Action: <EditIcon />,
    },
    {
      Transaction_ID: "NE120443",
      Performed_by: "Narayan Education",
      Email_ID: "anupamanand46@gmail.com",
      Mobile_Number: "+919342830239",
      Type: "Promotion",
      Date: "28/03/2023",
      Action: <EditIcon />,
    },
    {
      Transaction_ID: "NE120443",
      Performed_by: "Narayan Education",
      Email_ID: "anupamanand46@gmail.com",
      Mobile_Number: "+919342830239",
      Type: "Promotion",
      Date: "28/03/2023",
      Action: <EditIcon />,
    },
    {
      Transaction_ID: "NE120443",
      Performed_by: "Narayan Education",
      Email_ID: "anupamanand46@gmail.com",
      Mobile_Number: "+919342830239",
      Type: "Promotion",
      Date: "28/03/2023",
      Action: <EditIcon />,
    },
    {
      Transaction_ID: "NE120443",
      Performed_by: "Narayan Education",
      Email_ID: "anupamanand46@gmail.com",
      Mobile_Number: "+919342830239",
      Type: "Promotion",
      Date: "28/03/2023",
      Action: <EditIcon />,
    },
    {
      Transaction_ID: "NE120443",
      Performed_by: "Narayan Education",
      Email_ID: "anupamanand46@gmail.com",
      Mobile_Number: "+919342830239",
      Type: "Promotion",
      Date: "28/03/2023",
      Action: <EditIcon />,
    },
    {
      Transaction_ID: "NE120443",
      Performed_by: "Narayan Education",
      Email_ID: "anupamanand46@gmail.com",
      Mobile_Number: "+919342830239",
      Type: "Promotion",
      Date: "28/03/2023",
      Action: <EditIcon />,
    },
  ];

  const subjectMapping = [

    {
      accessorKey: "Subject_ID",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] rounded-l-lg py-2 px-5">Subject ID</div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start py-1 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 80,
      size: 200,
      isborder: true,
    },
   
    {
      accessorKey: "Name",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] py-2 px-5">Name</div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start py-1 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 200,
      size: 400,
      isborder: true,
    },

    {
      accessorKey: "No_of_members",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] py-2 px-5">No Of Members</div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start py px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 200,
      size: 400,
      isborder: true,
    },
    {
      accessorKey: "Schedule",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] py-2 px-5">Schedule</div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start py-1 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 80,
      size: 200,
      isborder: true,
    },
    
    {
      accessorKey: "Action",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] rounded-r-lg py-2 px-5">Action</div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start py-1 px-5 cursor-pointer" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 80,
      size: 200,
    }
  ];

  const subjectmaster = () => [
    {
      Subject_ID: "Subject ID",
      Name: "Science",
      No_of_members : 2,
      Schedule:(
        <>
          <div className="flex justify-center">
            <Button
              type="button"
              className="bg-yellowbg px-4 py-1 border rounded-xl"
            >
              Assign
            </Button>
          </div>
        </>
      ),
      Action: <EditIcon />,
    },
    {
      Subject_ID: "Subject ID",
      Name: "Science",
      No_of_members : 2,
      Schedule:(
        <>
          <div className="flex justify-center">
            <Button
              type="button"
              className="bg-yellowbg px-4 py-1 border rounded-xl"
            >
              Assign
            </Button>
          </div>
        </>
      ),
      Action: <EditIcon />,
    },
    {
      Subject_ID: "Subject ID",
      Name: "Science",
      No_of_members : 2,
      Schedule:(
        <>
          <div className="flex justify-center">
            <Button
              type="button"
              className="bg-yellowbg px-4 py-1 border rounded-xl"
            >
              Assign
            </Button>
          </div>
        </>
      ),
      Action: <EditIcon />,
    },
  ];

  const classMappingColumns = [

    {
      accessorKey: 'Class',
      header: ({ table }) => (
        <div className="flex justify-start bg-[#F4F4F4] rounded-l-lg py-2 px-5">
            Class
        </div>
    ),
      size: 200,
      minSize: 100,
      maxSize: 500,
      isborder:true,
      cell: ({ row, getValue }) => (
          <div
          style={{
            paddingLeft: `${row.depth}rem`,
        }}
    
        className="flex justify-between item-center bg-white h-16 p-2"

          >
              <>
                  {' '}
                  <div className="ml-2 mt-2 pl-2">

                      {getValue()}
                  </div>
                  <div>

                      {row.getCanExpand() ? (
                          <button
                              {...{
                                  onClick: row.getToggleExpandedHandler(),
                                  style: { cursor: 'pointer' },
                                  className: "ml-2 mt-2"
                              }}
                          >
                              {row.getIsExpanded() ? <><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fillrule="evenodd" clip-rule="evenodd" d="M3.53738 6.86225C3.80858 6.6019 4.24828 6.6019 4.51948 6.86225L9.58398 11.7242L14.6485 6.86225C14.9197 6.6019 15.3594 6.6019 15.6306 6.86225C15.9018 7.1226 15.9018 7.54471 15.6306 7.80506L10.075 13.1384C9.80383 13.3987 9.36414 13.3987 9.09294 13.1384L3.53738 7.80506C3.26619 7.54471 3.26619 7.1226 3.53738 6.86225Z" fill="#151515" />
                              </svg>
                              </> : <><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fillrule="evenodd" clip-rule="evenodd" d="M3.53738 13.1377C3.80858 13.3981 4.24828 13.3981 4.51948 13.1377L9.58398 8.27582L14.6485 13.1377C14.9197 13.3981 15.3594 13.3981 15.6306 13.1377C15.9018 12.8774 15.9018 12.4553 15.6306 12.1949L10.075 6.8616C9.80383 6.60125 9.36414 6.60125 9.09294 6.8616L3.53738 12.1949C3.26619 12.4553 3.26619 12.8774 3.53738 13.1377Z" fill="#151515" />
                              </svg>
                              </>}
                          </button>
                      ) : (
                          ''
                      )}{' '}
                  </div>

              </>
          </div>
      ),
  },

  {
    accessorKey: "Assigned_Subjects",
    header: (props) => (
      <div className="flex justify-start bg-[#F4F4F4] py-2 px-5">
        Assigned Subjects
      </div>
    ),
    cell: (props) => (
      <div className="w-full flex justify-start py-4 px-5" row={props.row}>
        {props.getValue()}
      </div>
    ),
    maxSize: 700,
    minSize: 300,
    size: 500,
    isborder: true,
   
  },

  {
    accessorKey: "Assigned_Staff",
    header: (props) => (
      <div className="flex justify-start bg-[#F4F4F4] py-2 px-5">
Assigned Staff      </div>
    ),
    cell: (props) => (
      <div className="w-full flex justify-start py-4 px-5" row={props.row}>
        {props.getValue()}
      </div>
    ),
    maxSize: 500,
    minSize: 100,
    size: 200,
    isborder: true,
   
  },
  {
    accessorKey: "Action",
    header: (props) => (
      <div className="flex justify-start bg-[#F4F4F4] rounded-r-lg py-2 px-5">
        Action
      </div>
    ),
    cell: (props) => (
      <div className="w-full flex justify-start py-4 px-5 cursor-pointer" row={props.row}>
        {props.getValue()}
      </div>
    ),
    maxSize: 500,
    minSize: 100,
    size: 200,   
  }
  ];

  const classmapping = () => [
    {
      Class: "Class 1",
      Assigned_Subjects: "10 Subjects",
      Assigned_Staff: "50 Members",
      Action: <EditIcon />,
      subRows:[
        {
          Class: "section1",
          Assigned_Subjects: "English, Telugu, Hindi, Social",
          Assigned_Staff: "10 Members",
          Action: <EditIcon />,
        },
        {
          Class: "section1",
          Assigned_Subjects: "English, Telugu,Marathi",
          Assigned_Staff: "20 Members",
          Action: <EditIcon />,
        }
      ]
    },
    {
      Class: "Class 2",
      Assigned_Subjects: "10 Subjects",
      Assigned_Staff: "50 Members",
      Action: <EditIcon />,
      subRows:[
        {
          Class: "section1",
          Assigned_Subjects: "English, Telugu, Hindi, Social",
          Assigned_Staff: "10 Members",
          Action: <EditIcon />,
        },
        {
          Class: "section1",
          Assigned_Subjects: "English, Telugu,Marathi",
          Assigned_Staff: "20 Members",
          Action: <EditIcon />,
        }
      ]
    },
    {
      Class: "Class 3",
      Assigned_Subjects: "19 Subjects",
      Assigned_Staff: "50 Members",
      Action: <EditIcon />,
      subRows:[
        {
          Class: "section1",
          Assigned_Subjects: "English, Telugu, Hindi, Social",
          Assigned_Staff: "10 Members",
          Action: <EditIcon />,
        },
        {
          Class: "section1",
          Assigned_Subjects: "English, Telugu,Marathi",
          Assigned_Staff: "20 Members",
          Action: <EditIcon />,
        }
      ]
    },
    {
      Class: "Class 4",
      Assigned_Subjects: "8 Subjects",
      Assigned_Staff: "50 Members",
      Action: <EditIcon />,
      subRows:[
        {
          Class: "section1",
          Assigned_Subjects: "English, Telugu, Hindi, Social",
          Assigned_Staff: "10 Members",
          Action: <EditIcon />,
        },
        {
          Class: "section1",
          Assigned_Subjects: "English, Telugu,Marathi",
          Assigned_Staff: "20 Members",
          Action: <EditIcon />,
        }
      ]
    },
    {
      Class: "Class 5",
      Assigned_Subjects: "15 Subjects",
      Assigned_Staff: "50 Members",
      Action: <EditIcon />,
      subRows:[
        {
          Class: "section1",
          Assigned_Subjects: "English, Telugu, Hindi",
          Assigned_Staff: "10 Members",
          Action: <EditIcon />,
        },
        {
          Class: "section1",
          Assigned_Subjects: "English, Telugu,Marathi",
          Assigned_Staff: "20 Members",
          Action: <EditIcon />,
        }
      ]
    },
    {
      Class: "Class 6",
      Assigned_Subjects: "4 Subjects",
      Assigned_Staff: "50 Members",
      Action: <EditIcon />,
      subRows:[
        {
          Class: "section1",
          Assigned_Subjects: "English, Telugu, Hindi, Social",
          Assigned_Staff: "5 Members",
          Action: <EditIcon />,
        },
        {
          Class: "section1",
          Assigned_Subjects: "English, Telugu,Marathi",
          Assigned_Staff: "20 Members",
          Action: <EditIcon />,
        }
      ]
    },
    {
      Class: "Class 7",
      Assigned_Subjects: "10 Subjects",
      Assigned_Staff: "50 Members",
      Action: <EditIcon />,
      subRows:[
        {
          Class: "section1",
          Assigned_Subjects: "English, Telugu, Hindi, Social",
          Assigned_Staff: "10 Members",
          Action: <EditIcon />,
        },
        {
          Class: "section1",
          Assigned_Subjects: "English, Telugu,Marathi",
          Assigned_Staff: "20 Members",
          Action: <EditIcon />,
        }
      ]
    },
    {
      Class: "Class 8",
      Assigned_Subjects: "10 Subjects",
      Assigned_Staff: "50 Members",
      Action: <EditIcon />,
      subRows:[
        {
          Class: "section1",
          Assigned_Subjects: "English, Telugu, Hindi, Social",
          Assigned_Staff: "10 Members",
          Action: <EditIcon />,
        },
        {
          Class: "section1",
          Assigned_Subjects: "English, Telugu,Marathi",
          Assigned_Staff: "20 Members",
          Action: <EditIcon />,
        }
      ]
    },
    {
      Class: "Class 10",
      Assigned_Subjects: "10 Subjects",
      Assigned_Staff: "50 Members",
      Action: <EditIcon />,
      subRows:[
        {
          Class: "section1",
          Assigned_Subjects: "English, Telugu, Hindi, Social",
          Assigned_Staff: "10 Members",
          Action: <EditIcon />,
        },
        {
          Class: "section1",
          Assigned_Subjects: "English, Telugu,Marathi",
          Assigned_Staff: "20 Members",
          Action: <EditIcon />,
        }
      ]
    },
  ];

  const centerModalDataColumns = [

    {
      accessorKey: "id",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] rounded-l-lg py-2 px-5">ID</div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start  py-4 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 80,
      size: 300,
      isborder: true,
    },

    {
      accessorKey: "Name",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] py-2 px-5">Name</div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start  py-4 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 80,
      size: 300,
      isborder: true,
    },

    {
      accessorKey: "Email_ID",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] py-2 px-5">Email ID</div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start  py-4 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 80,
      size: 300,
      isborder: true,
    },

    {
      accessorKey: "Mobile_Number",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] py-2 px-5">Mobile Number</div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start  py-4 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 80,
      size: 300,
      isborder: true,
    },


    {
      accessorKey: "Subject",
      header: (props) => (
        <div className="flex justify-start bg-[#F4F4F4] rounded-r-lg py-2 px-5">Subject</div>
      ),
      cell: (props) => (
        <div className="w-full flex justify-start  py-4 px-5" row={props.row}>
          {props.getValue()}
        </div>
      ),
      maxSize: 500,
      minSize: 80,
      size: 300,
    },

  ];

  const centermodaldata = () => [
    {
      id: (
        <>
          <Input type="checkbox" labeltext="SI12034"></Input>
        </>
      ),

      Name: "Staff Name",
      Email_ID: "Teacher@gmail.com",
      Mobile_Number: "+91 9342830239",
      Subject: "English",
    },
    {
      id: (
        <>
          <Input type="checkbox" labeltext="SI12034"></Input>
        </>
      ),

      Name: "Staff Name",
      Email_ID: "Teacher@gmail.com",
      Mobile_Number: "+91 9342830239",
      Subject: "English",
    },
    {
      id: (
        <>
          <Input type="checkbox" labeltext="SI12034"></Input>
        </>
      ),

      Name: "Staff Name",
      Email_ID: "Teacher@gmail.com",
      Mobile_Number: "+91 9342830239",
      Subject: "English",
    },
    {
      id: (
        <>
          <Input type="checkbox" labeltext="SI12034"></Input>
        </>
      ),

      Name: "Staff Name",
      Email_ID: "Teacher@gmail.com",
      Mobile_Number: "+91 9342830239",
      Subject: "English",
    },
    {
      id: (
        <>
          <Input type="checkbox" labeltext="SI12034"></Input>
        </>
      ),

      Name: "Staff Name",
      Email_ID: "Teacher@gmail.com",
      Mobile_Number: "+91 9342830239",
      Subject: "English",
    },
    {
      id: (
        <>
          <Input type="checkbox" labeltext="SI12034"></Input>
        </>
      ),

      Name: "Staff Name",
      Email_ID: "Teacher@gmail.com",
      Mobile_Number: "+91 9342830239",
      Subject: "English",
    },
  ];
  const tabmenu = [
    { name: "User Creation", component: <Users />, selected: true },
    {
      name: "Transfer/Promotion",
      component: <TransferPromotion></TransferPromotion>,
      selected: false,
    },
    { name: "Shifts", component: <Shifts></Shifts>, selected: false },
    {
      name: "Subject/Class Mapping",
      component: <SubjectClassMapping></SubjectClassMapping>,
      selected: false,
    },
  ];

  const [tabMenu, setabMenu] = useState(tabmenu);
  const [selectedTab, setselectedTab] = useState(tabmenu[0]);
  const [showModal, setshowModal] = useState(false);
  const [showPromotionDiv, setshowPromotionDiv] = useState(false);
  const [step, setStep] = useState("");
  const [userCreationData, setUserCreationData] = useState(usercreation);
  const [transferPromotion, setTransferPromotion] = useState(transferpromotion);
  const [shiftData, setShiftData] = useState(shifts);
  const [subjectMaster, setubjectMaster] = useState(subjectmaster);
  const [classMapping, setclassmapping] = useState(classmapping);
  const [showCenterModal, setShowCenterModal] = useState(false);
  const [centerModalData, setCenterModalData] = useState(centermodaldata);

  const handleTab = (params) => {
    // if (selectedTab?.name === params?.name) return
    const temp = [...tabmenu].map((i, idx) => {
      if (i?.name === params?.name) i.selected = true;
      else i.selected = false;
      return i;
    });
    setabMenu([...temp]);
    setselectedTab(params);
    setshowPromotionDiv(false);
  };

  const handleModal = () => {
    setshowModal((prev) => !prev);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleModal();
    setshowPromotionDiv(true);
  };

  return {
    tabMenu,
    selectedTab,
    handleTab,
    showModal,
    setshowModal,
    handleModal,
    handleSubmit,
    showPromotionDiv,
    setshowPromotionDiv,
    step,
    setStep,
    userCreationData,
    transferPromotion,
    shiftData,
    subjectMaster,
    classMapping,
    showCenterModal,
    setShowCenterModal,
    usercreationColumns,
    transferpromotionColumns,
    shiftColumn,
    classMappingColumns,
    subjectMapping,
    centerModalDataColumns,
    centerModalData,
  };
}
