const CommonStatuesCodes = {
    NEW: 0,
    STARTED: 1,
    PENDING: 2,
    COMPLETED: 3,
    REJECTED: 4,

    ACTIVE: 0,
    ARCHIVED: 1,
    IN_ACTIVE: 2,
    ADMIN: 3,
    TRUE:true,
    FALSE:false,
};


const CommonStatuesObj= Object.freeze(CommonStatuesCodes);

export default CommonStatuesObj;
