import React from 'react';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, linearGradient } from 'recharts';

const DashboardLineGraphCustom = ({ data }) => {
    return (
        <ResponsiveContainer>
            <LineChart data={data} margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0
            }}>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#242426" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#242426" stopOpacity={0} />
                </linearGradient>
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <Line type="monotone" dataKey="uv" dot={false} stroke="#8884d8" fill="url(#colorUv)" />
            </LineChart>
        </ResponsiveContainer>
    )
}

export default DashboardLineGraphCustom;