import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Modal from '.';
import { useNavigate } from 'react-router-dom';
import Title from '../../elements/Title';
import Button from '../../elements/Button';
import { LogoutData } from '../../service/api/auth';
import { toast } from 'react-hot-toast';

const ModalPortal = ({ showModal, setShowModal }) => {
    const navigate = useNavigate()

    const logoutapiResp = (resp) => {
        localStorage.clear();
        navigate('/')
        if (resp?.success === true) {
        }
        else {
            toast.error('Something went wrong please try again')
        }
    }
    const logoutapi = LogoutData(logoutapiResp)

    return ReactDOM.createPortal(
        < >
            <Modal type="center"
                showModal={showModal}
                setShowModal={setShowModal}
            >
                <div className='min-h-[120px] mt-5 text-center'>

                    <Title>Are You Sure You Want to <span className='text-2xl text-red-600 my-2'>
                        logout
                    </span>
                    </Title>

                    <div className='w-full flex justify-center items-center gap-4 mt-6 mb-5'>

                        <Button
                            className='bg-red-600 text-white max-w-fit '
                            onClick={
                                () => logoutapi.mutate()
                            }

                        >
                            Yes
                        </Button>
                        <Button
                            onClick={() => {
                                setShowModal(false)
                            }
                            }
                            className='bg-gray-600 text-white max-w-fit'
                        >
                            Back
                        </Button>
                    </div>
                </div>
            </Modal>
        </>,
        document.getElementById('modal-root')
    );
};

export default ModalPortal;