import React from 'react'
import RoutesNames from '../constant/RouteNames'
import Card from '../elements/Cards'
import TabLayout from '../component/layout/LayoutTabs'
import DashboardStaffTab from '../pages/Dashboard/DashboardTabs/DashboardStaffTab'
import DashboardStudentTab from '../pages/Dashboard/DashboardTabs/DashboardStudentTab'
import Users from '../pages/UserManagement/Users'
import TransferPromotion from '../pages/UserManagement/TransferPromotion'
import OtpInput from '../pages/Register/OtpInput'
import CreateAccount from '../pages/Register/CreateAccount'
import SubmitForm from '../pages/Register/SubmitForm'
import OtpForgotPassword from '../pages/ForgotPassword/OtpForgotPassword'
import SetNewPassword from '../pages/ForgotPassword/SetNewPassword'
import UserIdOtp from '../pages/ForgotUSERID/UserIdOtp'
import OnboardingLayout from '../pages/Onboarding'

const LayoutAuth = React.lazy(() => import('../component/layout/Auth'))
const LayoutdashBoard = React.lazy(() =>
  import('../component/layout/DashBoard'),
)

const OrganisationLayout = React.lazy(() =>
  import('../pages/Organisation'),
)

const Profile = React.lazy(() => import('../pages/CommonPages/Profile'))
const Structure = React.lazy(() =>
  import('../pages/CommonPages/Structure/Structure'),
)
const Settings = React.lazy(() =>
  import('../pages/CommonPages/Settings/Settings'),
)
const Subscription = React.lazy(() =>
  import('../pages/Organisation/Subscription/Subscription'),
)

const ForgotuserID = React.lazy(() => import('../pages/ForgotUSERID'))
const Login = React.lazy(() => import('../pages/Login'))
const SubmitLogin = React.lazy(() => import('../pages/Login/SubmitLogin'))
const Register = React.lazy(() => import('../pages/Register'))
const ForgotPassword = React.lazy(() => import('../pages/ForgotPassword'))
const UserManagement = React.lazy(() => import('../pages/UserManagement'))
const Notifications = React.lazy(() => import('../pages/Notifications'))
const AttendancePolicy = React.lazy(() =>
  import('../pages/CommonPages/AttendancePolicy/AttendancePolicy'),
)
const Leaves = React.lazy(() => import('../pages/Leaves'))
const ReportsAnalytics = React.lazy(() => import('../pages/ReportsAnalytics'))
const Holidays = React.lazy(() => import('../pages/Holidays'))
const DataManagement = React.lazy(() => import('../pages/DataManagement'))

const RolePrivillages = React.lazy(() =>
  import('../pages/RoleManagement/RolePrivillages'),
)
const Designations = React.lazy(() =>
  import('../pages/RoleManagement/Designations'),
)
const RolePolicy = React.lazy(() =>
  import('../pages/RoleManagement/RolePolicy'),
)

const authRoute = [
  {
    path: '/*',
    element: <LayoutdashBoard />,
    children: [
      {
        element: <TabLayout type={RoutesNames.DASHBOARD} />,
        path: `${RoutesNames.DASHBOARD}/*`,
        children: [
          { element: <DashboardStaffTab />, path: RoutesNames.STAFF },
          { element: <DashboardStudentTab />, path: RoutesNames.STUDENT },
        ],
      },
      {
        element: <OrganisationLayout/>,
        path: `${RoutesNames.ORGANIZATION}/*`,
        children: [
          { element: <Profile />, path: RoutesNames.PROFILE },
          { element: <Settings />, path: RoutesNames.SETTINGS },
          { element: <Structure />, path: RoutesNames.STRUCTURE },
          { element: <Subscription />, path: RoutesNames.SUBSCRIPTION },
        ],
      },
      {
        path: RoutesNames.ONBOARDING,
        element: <OnboardingLayout flag={true} />,
        children: [
          {
            element: <Profile isOnboarding={true} />,
            path: RoutesNames.PROFILE,
          },
          {
            element: <Settings isOnboarding={true} />,
            path: RoutesNames.SETTINGS,
          },
          {
            element: <Structure isOnboarding={true} />,
            path: RoutesNames.STRUCTURE,
          },
          {
            element: <AttendancePolicy isOnboarding={true} />,
            path: RoutesNames.ATTENDANCE_POLICY,
          },
        ],
      },
      {
        element: <TabLayout type={RoutesNames.ROLE_MANAGEMENT} />,
        path: `${RoutesNames.ROLE_MANAGEMENT}/*`,
        children: [
          {
            element: <RolePrivillages />,
            path: RoutesNames.PRIVILLAGES,
          },
          {
            element: <Designations />,
            path: RoutesNames.DESIGNATION,
          },
          { element: <RolePolicy />, path: RoutesNames?.POLICY },
        ],
      },
      {
        element: <TabLayout type={RoutesNames.USER_MANAGEMENT} />,
        path: RoutesNames.USER_MANAGEMENT,
        children: [
          {
            element: <Users />,
            path: RoutesNames.CREATION,
          },
          {
            element: <TransferPromotion />,
            path: RoutesNames.TRANSFER_PROMOTION,
          },
          { element: <Card>SHIFTS</Card>, path: RoutesNames?.SHIFTS },
          {
            element: <Card>SUBJECT CLASS MAPPING</Card>,
            path: RoutesNames?.SUBJECT_CLASS_MAPPING,
          },
        ],
      },
      {
        element: <TabLayout type={RoutesNames.ATTENDANCE_POLICY} />,
        path: RoutesNames.ATTENDANCE_POLICY,
        children: [
          { element: <Card>Global</Card>,
          path: RoutesNames.GLOBAL,
          },
          {
            element: <Card>Configurable</Card>,
            path: RoutesNames?.CONFIGURABLE,
          },
        ],
      },
      {
        element: <TabLayout type={RoutesNames.LEAVES} />,
        path: RoutesNames.LEAVES,
        children: [
          { element: <Card>Org Configuration</Card>, 
          path: RoutesNames.CONFIGURATION,
        },
          {
            element: <Card>My Leaves</Card>,
            path: RoutesNames?.CONFIGURABLE,
          },
        ],
      },
      {
        element: <TabLayout type={RoutesNames.NOTIFICATIONS} />,
        path: RoutesNames.NOTIFICATIONS,
        children: [
          { element: <Card>Configuration</Card>,
          path: RoutesNames.NOTCONFIGURATION,
          
          },
          {
            element: <Card>Notifications</Card>,
            path: RoutesNames?.NOTIFICATION,
          },
          {
            element: <Card>Take Action</Card>,
            path: RoutesNames?.TAKEACTION,
          },
        ],
      },
      {
        element: <TabLayout type={RoutesNames.REPORT_ANALYTICS} />,
        path: RoutesNames.REPORT_ANALYTICS,
        children: [
          { element: <Card>Attendance</Card>, path: RoutesNames.ATTENDANCE },
          {
            element: <Card>Enrolment</Card>,
            path: RoutesNames?.ENROLLEMENT,
          },
          {
            element: <Card>Transaction</Card>,
            path: RoutesNames?.TRANSACTION,
          },
          {
            element: <Card>Activity</Card>,
            path: RoutesNames?.ACTIVITY,
          },
          {
            element: <Card>Usage</Card>,
            path: RoutesNames?.USAGE,
          },
          {
            element: <Card>Anomaly</Card>,
            path: RoutesNames?.ANOMALY,
          },
          {
            element: <Card>Additional</Card>,
            path: RoutesNames?.ADDITIONAL,
          },
        ],
      },
      { element: <Holidays />, path: RoutesNames.HOLIDAYS },
      { element: <DataManagement />, path: RoutesNames.DATA_MANAGEMENT },
      { element: <Settings />, path: RoutesNames.SETTINGS },
    ],
  },
]

const openRoute = [
  {
    element: <LayoutAuth />,
    children: [
      {
        element: <Login />,
        path: RoutesNames.HOME,
        // children: [
        // ]
      },
      { element: <SubmitLogin />, path: RoutesNames.LOGIN_SUBMIT },
      {
        path: `${RoutesNames.REGISTER}/*`,
        children: [
          {
            element: <Register />,
            index: true,
          },
          {
            element: <OtpInput />,
            path: RoutesNames.OTP,
          },
          {
            element: <CreateAccount />,
            path: RoutesNames.CREATE_ACCOUNT,
          },
          {
            element: <SubmitForm />,
            path: RoutesNames.SUBMIT_FORM,
          },
        ],
      },
      // { element: <ForgotPassword />, path: RoutesNames.FORGOT_PASSWORD },
      {
        path: `${RoutesNames.FORGOT_PASSWORD}/*`,
        children: [
          {
            element: <ForgotPassword />,
            index: true,
          },
          {
            element: <OtpForgotPassword />,
            path: RoutesNames.FORGOT_PASSWORD_OTP,
          },
          {
            element: <SetNewPassword />,
            path: RoutesNames.SET_NEW_PASSWORD,
          },
        ],
      },
      {
        path: `${RoutesNames.GET_USERID}/*`,
        children: [
          {
            element: <ForgotuserID />,
            index: true,
          },
          {
            element: <UserIdOtp />,
            path: RoutesNames.GET_USER_ID_OTP,
          },
        ],
      },
      // { element: <ForgotuserID />, path: RoutesNames.GET_USERID },
    ],
  },
  {
    element: <Card className="h-screen text-red-800">Page Not Found</Card>,
    path: '*',
  },
]

export const routes = [...openRoute, ...authRoute]
