import InputSearch from "../InputSearch";

const SearchForTable = (props) => {
  const { globalFilter, setGlobalFilter } = props;
  const onChange = (e)=>{
    setGlobalFilter(e.target.value)
  }
  return (
    <>
      <div className=" w-1/5 mb-1">
        <InputSearch value={globalFilter || ""} onChange={onChange} />
      </div>
    </>
  );
};

export default SearchForTable;
