import React from 'react';
import Card from '../../elements/Cards';
import { GraphIncDncSVG } from '../../assest/svgs';
import DashboardLineGraphCustom from './DashboardLineGraphCustom';

const DashboardCardWithGraph = ({ item }) => {
    return (
        <Card>
            <div className="flex items-center h-full p-2">
                <div className="w-3/4 h-20 ">
                    <div className="flex items-center h-full">
                        <div className="w-[40px] h-[40px] rounded-lg bg-gray-300"></div>
                        <div className="mx-2">
                            <p className="mb-[6px] text-[12px] text-lightgray_333 leading-[1]">{item?.cardTitle}</p>
                            <h4 className="mb-[6px] text-primaryblack text-[18px] font-black leading-[1]">{item?.cardCount}</h4>
                            <div className={`${item?.percent >= 50 ? 'bg-lightgreen_custom' : 'bg-lightred_custom'} rounded-md px-[6px] py-[3px] flex flex-row items-center text-[9px] w-auto`}>
                                <GraphIncDncSVG color={item?.percent >= 50 ? "#027A48" : "#D92D20"} /> <span className="ml-1">{item?.percent}% increased</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`w-1/4 h-16 flex flex-row items-center ${item?.data?.length > 0 ? '' : 'bg-gray-300 rounded-lg'}`}>
                    <DashboardLineGraphCustom data={item?.data} />
                </div>
            </div>
        </Card>
    )
}

export default DashboardCardWithGraph