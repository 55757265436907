import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getAPI, postAPI } from '../../ApiMethods'
import API_NAMES from '../../../constant/API_Names'
import { toast } from 'react-hot-toast'
import { SetGlobalState, store } from '../../..'
import { storeDynamicImages } from '../../../Store/Actions/CommonAction'

export const ResetPasswordData = (onSuccess, onError) => {
  // Retrieve the access token from authdata in local storage
  const authdata = JSON.parse(localStorage.getItem('authdata'));
  const accessToken = authdata?.accessToken;

  return useMutation({
    mutationKey: [API_NAMES?.RESETPASSWORD],
    mutationFn: async (payload) => {
      const response = await postAPI(API_NAMES?.RESETPASSWORD, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Add the Bearer token header
        },
      });
      return response;
    },
    onSuccess: (data, variable, context) => {
      onSuccess(data);
    },
    onError: (error) => {
      onSuccess(error);
    },
    retry: 0,
    staleTime: Infinity,
  });
};


export const LogoutData = (onSuccess, onError) => {
  const username = JSON.parse(localStorage.getItem('authdata'))?.username || ''
  return useMutation({
    mutationKey: [API_NAMES?.LOGOUT],
    mutationFn: async () => {
      const response = await getAPI(`${API_NAMES?.LOGOUT}?userName=${username}`)
      return response
    },
    onSuccess: (data, variable, context) => {
      onSuccess(data)
    },
    onError: (error) => {
      onSuccess(error?.response?.data)
    },
    retry: 0,
    staleTime: Infinity,
  })
}

export const GenerateOTPData = (onSuccess, onError) => {
  const temp = {
    otpLength: 4,
    phoneNumber: '0000000000',
  }
  return useMutation({
    mutationKey: [API_NAMES?.GENERATEOTP],
    mutationFn: async (payload) => {
      const body = { ...temp, ...payload }
      const response = await postAPI(API_NAMES?.GENERATEOTP, body)
      return response
    },
    onSuccess: (data, variable, context) => {
      onSuccess(data)
    },
    onError: (error) => {
      onSuccess(error)
    },
    retry: 0,
    staleTime: Infinity,
  })
}

export const VerifyOTPData = (onSuccess, onError) => {
  return useMutation({
    mutationKey: [API_NAMES?.VERIFYOTP],
    mutationFn: async (payload) => {
      const response = await postAPI(API_NAMES?.VERIFYOTP, payload)
      return response
    },
    onSuccess: (data, variable, context) => {
      onSuccess(data)
    },
    onError: (error) => {
      toast.error(error?.response?.data?.errors?.message)
      onSuccess(error?.response?.data)
    },
    retry: 0,
    staleTime: Infinity,
  })
}

export const CheckEmail = (onSuccess, onError) => {
  const queryClient = useQueryClient()
  let email
  return useMutation({
    mutationKey: [API_NAMES?.CHECK_USER],
    mutationFn: async (payload) => {
      email=payload?.email
      const response = await postAPI(API_NAMES?.CHECK_USER, payload)
      return response
    },
    onSuccess: (data, variable, context) => {
      const temp=data?.data
      temp.email=email
      SetGlobalState("userlogo",{...temp})      
      sessionStorage.setItem("backdrops", JSON.stringify(temp))
      store.dispatch(storeDynamicImages(temp));
      onSuccess(data)
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message)
      onSuccess(error?.response?.data)
    },
    retry: 0,
    staleTime: Infinity,
    enable: true,
  })
}
