import React from 'react'
import Card from '../../../elements/Cards'
import Title from '../../../elements/Title'
import SubTitle from '../../../elements/SubTitle'
import Button from '../../../elements/Button'
import { DownloadSvg, ExportSvg, ImportSvg, OopsEmpty, PlusSvg, UserCreationSvg, UserCreationSvg1, UserCreationSvg2 } from '../../../assest/svgs'
import TitleSwitchParagraph from '../../TitleSwitchParagraph'
import Input from '../../../elements/Input'
import Select from '../../../elements/Input/Select'

const UserCreation = () => {
    let error=true
    let errorMessage="please select this"
 
    return (
        <>
            <Card className="p-4 h-max mt-4">

                {/* <div className='flex justify-between'>
                    <div>
                        <Title>User Creation</Title>
                        <SubTitle className="w-4/5">View the users that have been added or select "Add" to create more by clicking the button. Multiple users can be created using bulk upload.</SubTitle>
                        <div className='mt-1'>
                            <SubTitle className='flex gap-2'><DownloadSvg />Download Sample Excel File</SubTitle>
                        </div>
                    </div>
                    <div className='flex gap-4'>
                        <Button className='flex gap-2 border max-h-[40px] '><ExportSvg /> Export</Button>
                        <Button className='flex gap-2 border max-h-[40px]'><ImportSvg />Import</Button>
                    </div>
                </div>
                <div>
                    <div className='w-full flex justify-center mt-4'>
                        <OopsEmpty />
                    </div>
                    <div className='text-center '>
                        <Title>Oops! It’s Empty</Title>
                        <SubTitle>Looks like you haven’t added any lead yet..!</SubTitle>
                        <div className=' flex justify-center mt-4'>

                            <Button className='flex gap-2 bg-primaryblack '><PlusSvg /><span className='text-[white]'>Add</span></Button>
                            
                        </div>
                    </div>


                </div> */}

                <Title>Basic Settings</Title>
                <TitleSwitchParagraph subtext={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, reiciendis fugit qui consequuntur reprehenderit quaerat culpa nam          corrupti error quisquam sed, labore necessitatibus nostrum, eligendi   repellendus facere inventore atque eaque. Deserunt dolorum dolores a aliquam,"}>
                    <div className='grid grid-cols-4 gap-4'>
                        <Input
                            labeltext={"Staff/Student ID"}
                            placeholder={""}
                            type={"text"}
                            name={"orgname"}
                        >
                        </Input>
                        <Input
                            labeltext={"Attendee Name"}
                            placeholder={""}
                            type={"text"}
                            name={""}
                        >
                        </Input>
                        <Input
                            labeltext={"Email ID"}
                            placeholder={""}
                            type={"text"}
                            name={""}
                        >
                        </Input>
                        <Input
                            labeltext={"Mobile Number"}
                            placeholder={""}
                            type={"text"}
                            name={""}
                        >
                        </Input>
                    </div>
                </TitleSwitchParagraph>
                <TitleSwitchParagraph subtext={"Gender"}>
                    <div className='grid grid-cols-3 w-1/4'>
                        <Input
                            labeltext={"Male"}
                            placeholder={""}
                            type={"radio"}
                            name={""}
                            error={error}
                            errorMessage={errorMessage}
                        >
                        </Input>
                        <Input labeltext={"Female"}
                            placeholder={""}
                            type={"radio"}
                            name={""}
                        >
                        </Input>
                        <Input labeltext={"Others"}
                            placeholder={""}
                            type={"radio"}
                            name={""}
                        >
                        </Input>
                    </div>
                </TitleSwitchParagraph>
                <TitleSwitchParagraph>
                    <div className='grid grid-cols-4 gap-3'>
                        <Input labeltext={"Role Policy Number"}
                            type={"text"}
                        // placeholder={""}
                        >
                        </Input>
                        <Input labeltext="Organization Name"
                            type={"text"}>
                        </Input>
                    </div>
                </TitleSwitchParagraph>
                <TitleSwitchParagraph subtext={"Privileges"} >
                    <div className='grid grid-cols-2 w-1/3'>
                        <Input type={"checkbox"} labeltext={"Dashboard Features"}></Input>
                        <Input type={"checkbox"} labeltext={"1:N App Features"}></Input>
                    </div>
                </TitleSwitchParagraph>
            </Card>
            <Card className="p-4 h-max mt-4">
                <TitleSwitchParagraph text={"Reporting Details"}
                    subtext={"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups."}>
                    <div className='grid grid-cols-4 gap-3'>
                        <Input type={"text"} labeltext={"Staff ID"}>
                        </Input>
                        <Input type={"text"} labeltext={"Staff Name"}>
                        </Input>
                        <Input type={"text"} labeltext={"Email ID"}>
                        </Input>
                        <Input type={"text"} labeltext={"Mobile Number"}>
                        </Input>
                    </div>
                </TitleSwitchParagraph>
             
            </Card>
            <Card className="p-4 h-max mt-4">
                <TitleSwitchParagraph text={"Create User Access"}
                    subtext={"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups."}>
                    <div className='grid grid-cols-4 gap-3'>
                        
                    </div>
                </TitleSwitchParagraph>
                <div className='flex w-1/2 '>
                    <div className='w-1/3'>
                <SubTitle>Role Policy Number</SubTitle>
                <Select className="mt-0" defaultOption="Select type" />
                </div>
                <div className='flex text-center w-1/12 items-center justify-center pt-2'>or</div>
                <div className='w-1/3'>
                <SubTitle>Organization Name</SubTitle>
                <Select className="mt-0" defaultOption="Select type" />
                </div>
                </div>
            </Card>
        </>
    )
}

export default UserCreation