import React from "react";
import Card from "../../../elements/Cards";
import { DashBoardTabsPresenter } from "./dashboard-tabs-presenter";
import InputSearch from "../../../component/InputSearch";
import Title from "../../../elements/Title";
import DashboardCardLoader from "../../../component/Skeleton/Dashbaord/DashboardCardLoader";
import Input from "../../../elements/Input";
import EnrollmentCircle from "../../../component/Skeleton/Dashbaord/EnrollmentCircleLoader";
import EnrollmentStatus from "../../../component/Skeleton/Dashbaord/EnrollmentStatusLoader";


import DetailAnalyticsLoader from "../../../component/Skeleton/Dashbaord/DetailAnalyticsLoader";
import SkeletonCard from "../../../elements/SkeletonCard";
import { FilterSVG1 as FilterSVG } from "../../../assest/svgs";
import DashboardCardWithGraph from "../../../component/Dashboard/DashboardCardWithGraph";
import DashboardAreaGraph from "../../../component/Dashboard/DashboardAreaGraph";
import DashboardBarChartGraph from "../../../component/Dashboard/DashboardBarChartGraph";
import SubTitle from "../../../elements/SubTitle";
import DashboardVerticalBarChartGraph from "../../../component/Dashboard/DashboardVerticalBarChartGraph";
import { PageLabel } from "../../../constant/PageLabels";

const DashboardStaffTab = () => {
  const { tabMenu, selectedTab, handleTab, cardsData } =
    DashBoardTabsPresenter();
  return (
    <div>
      <>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          {cardsData?.map((item, idx) => (
            <DashboardCardWithGraph item={item} />
          ))}
          {/* <DashboardCardLoader /> */}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-5 my-4">
          <Card className="col-span-1">
            <div className="w-full flex justify-between rounded-lg p-2">
              <Title>{PageLabel?.commonLabels?.ENROLLMENT}</Title>
              <FilterSVG />
            </div>
            <EnrollmentCircle
              text="Staff"
            />
            <EnrollmentStatus />

          </Card>
          <Card className="col-span-2">
            <div role="status" className=" p-2 flex flex-col">
              <div className="flex justify-between ">
                <Title className="mb-2">{PageLabel?.commonLabels?.TODAY_ATTENDANCE}</Title>
                <FilterSVG />
              </div>
              {/* <SkeletonCard className="h-96" /> */}
              <div className={`h-96 rounded-lg w-full`}>
                <DashboardBarChartGraph />
              </div>
            </div>
          </Card>
          <Card className="col-span-1">

            <div className="flex items-center justify-between p-2">
              <Title className="">Detail Analytics</Title>
              <FilterSVG />
            </div>
            <InputSearch />
            {/* <DetailAnalyticsLoader /> */}
            {[1, 1, 1, 1, 1, 1].map((i, idx) => (
              <div className="flex items-center justify-between px-2 py-1 border-b">
                <div className="flex pt-3">
                  <div className="w-8 h-8 rounded-lg bg-[#D8D8D8] "></div>
                  <div className="px-3">
                    <Title className="font-light">{PageLabel?.commonLabels?.PERSON_NAME}</Title>
                    <SubTitle className="text-black font-light">
                      {PageLabel?.commonLabels?.SUBJECT_ROLE}
                    </SubTitle>
                  </div>
                </div>
                <div>
                  <div className="text-[#128400] font-semibold text-xs">9:30 am</div>
                  <div className="text-[#D02127] font-semibold text-xs">5:30 pm

                  </div>
                </div>

              </div>
            ))}

          </Card>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
          <Card className="col-span-2 h-70vh">
            <div className="flex flex-col  my-2">
              <div className="flex justify-between p-2">
                <Title>{PageLabel?.commonLabels?.REPORTS}</Title>
                <FilterSVG />
              </div>

              {/* <SkeletonCard className="h-96 " /> */}
              <div className={`h-96 rounded-lg w-full`}>
                <DashboardAreaGraph />
              </div>
            </div>
          </Card>

          <Card className="col-span-1">
            <div className="flex justify-between p-2">
              <Title>{PageLabel?.commonLabels?.PERFORMANCE_REPORT}</Title>
              <div className="flex ">
                <div className="">
                  {PageLabel?.commonLabels?.HIGH}
                </div>
                <div className="ml-2">
                  <Input type={"toggle"} />
                </div>
                <div className="ml-2">
                  {PageLabel?.commonLabels?.LOW}
                </div>
                <div className="ml-2">
                  <FilterSVG />
                </div>
              </div>
            </div>
            {/* <SkeletonCard className="h-96" /> */}
            <div className={`h-96 rounded-lg w-full`}>
              <DashboardVerticalBarChartGraph />
            </div>
          </Card>
        </div>
      </>
    </div>
  );
};

export default DashboardStaffTab;
