import React, { useState } from 'react'
import Staff from './DashboardStaffTab'
import Student from './DashboardStudentTab'

export const DashBoardTabsPresenter = () => {
  const tabmenu = [
    { name: 'Staff', component: <Staff />, selected: true },
    { name: 'Students', component: <Student />, selected: false },
  ]

  const [tabMenu, setabMenu] = useState(tabmenu)
  const [selectedTab, setselectedTab] = useState(tabmenu[0])

  const handleTab = (params) => {
    const temp = [...tabmenu].map((i, idx) => {
      if (i?.name === params?.name) i.selected = true
      else i.selected = false
      return i
    })
    setabMenu([...temp])
    setselectedTab(params)
  }
  const cardsdata = () => {
    return [
      {
        cardTitle: 'Total Staff', cardCount: 121212, percent: 70, status: 0, data: [
          {
            "name": "Page A",
            "uv": 4000,
            "pv": 2400,
            "amt": 2400
          },
          {
            "name": "Page B",
            "uv": 3000,
            "pv": 1398,
            "amt": 2210
          },
          {
            "name": "Page C",
            "uv": 2000,
            "pv": 9800,
            "amt": 2290
          },
          {
            "name": "Page D",
            "uv": 2780,
            "pv": 3908,
            "amt": 2000
          },
          {
            "name": "Page E",
            "uv": 1890,
            "pv": 4800,
            "amt": 2181
          },
          {
            "name": "Page F",
            "uv": 2390,
            "pv": 3800,
            "amt": 2500
          },
          {
            "name": "Page G",
            "uv": 3490,
            "pv": 4300,
            "amt": 2100
          }
        ]
      },
      { cardTitle: 'Present', cardCount: 6456, percent: 20, status: 0, data: [] },
      {
        cardTitle: 'Absent', cardCount: 787, percent: 60, status: 0, data: [
          {
            "name": "Page A",
            "uv": 4000,
            "pv": 2400,
            "amt": 2400
          },
          {
            "name": "Page B",
            "uv": 3000,
            "pv": 1398,
            "amt": 2210
          },
          {
            "name": "Page C",
            "uv": 45,
            "pv": 45,
            "amt": 23
          },
          {
            "name": "Page D",
            "uv": 2780,
            "pv": 3908,
            "amt": 2000
          },
          {
            "name": "Page E",
            "uv": 1890,
            "pv": 4800,
            "amt": 2181
          },
          {
            "name": "Page F",
            "uv": 2390,
            "pv": 3800,
            "amt": 2500
          },
          {
            "name": "Page G",
            "uv": 23,
            "pv": 23,
            "amt": 23
          }
        ]
      },
      { cardTitle: 'On Leave', cardCount: 1232, percent: 20, status: 0, data: [] },
    ]
  }
  const cardsdata1 = () => {
    return [
      {
        cardTitle: 'Total Student', cardCount: 121212, percent: 70, status: 0, data: [
          {
            "name": "Page A",
            "uv": 4000,
            "pv": 2400,
            "amt": 2400
          },
          {
            "name": "Page B",
            "uv": 3000,
            "pv": 1398,
            "amt": 2210
          },
          {
            "name": "Page C",
            "uv": 2000,
            "pv": 9800,
            "amt": 2290
          },
          {
            "name": "Page D",
            "uv": 2780,
            "pv": 3908,
            "amt": 2000
          },
          {
            "name": "Page E",
            "uv": 1890,
            "pv": 4800,
            "amt": 2181
          },
          {
            "name": "Page F",
            "uv": 2390,
            "pv": 3800,
            "amt": 2500
          },
          {
            "name": "Page G",
            "uv": 3490,
            "pv": 4300,
            "amt": 2100
          }
        ]
      },
      { cardTitle: 'Present', cardCount: 6456, percent: 20, status: 0, data: [] },
      {
        cardTitle: 'Absent', cardCount: 787, percent: 60, status: 0, data: [
          {
            "name": "Page A",
            "uv": 4000,
            "pv": 2400,
            "amt": 2400
          },
          {
            "name": "Page B",
            "uv": 3000,
            "pv": 1398,
            "amt": 2210
          },
          {
            "name": "Page C",
            "uv": 45,
            "pv": 45,
            "amt": 23
          },
          {
            "name": "Page D",
            "uv": 2780,
            "pv": 3908,
            "amt": 2000
          },
          {
            "name": "Page E",
            "uv": 1890,
            "pv": 4800,
            "amt": 2181
          },
          {
            "name": "Page F",
            "uv": 2390,
            "pv": 3800,
            "amt": 2500
          },
          {
            "name": "Page G",
            "uv": 23,
            "pv": 23,
            "amt": 23
          }
        ]
      },
      { cardTitle: 'On Leave', cardCount: 1232, percent: 20, status: 0, data: [] },
    ]
  }

  const [cardsData, setcardsData] = useState(cardsdata)
  const [cardsData1, setcardsData1] = useState(cardsdata1)

  return { tabMenu, selectedTab, handleTab, cardsData,cardsData1 }
}
