import React from 'react'
import { ImgCrossSVG } from '../../assest/svgs'

const Fileinput = (props) => {
  return (
    <div>
      {
        props?.title ? <div className='text-lightgray my-1 text-sm'>
        </div> :
          <div className='text-lightgray my-1 text-sm'>
            {props?.placeholder.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
          </div>
      }
      <div className="">

        {props?.valueimg ?
          <div className='bg-gray-50 border-2 border-dashed border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  
                    flex justify-center items-center relative h-28'>
            <img src={props?.valueimg} alt="Preview" className='h-20 rounded border border-gray-400' style={{ maxWidth: '100px' }} />
            <div className='relative -left-4 -top-8 cursor-pointer'
              name={props?.name}
              onClick={(e) => {
                props?.onChange(e, props?.name)
              }}
            >
              <ImgCrossSVG />
            </div>
          </div> : <div className='h-24 ' >


            <div className="flex items-center justify-center w-auto relative">
              <label htmlFor={props?.name} className="flex flex-col  items-center justify-center w-full  border-2 border-dashed border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">

                <div className="flex flex-col items-center justify-center gap-1.5 py-[18px] ">
                  <div className="w-7  mb-1 text-gray-400">
                    <svg width="" height="" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="16" cy="16" r="15.5" stroke="#D9D9D9" />
                      <g clipPath="url(#clip0_1067_9086)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.25 22.7959C15.25 23.1848 15.5858 23.5 16 23.5C16.4142 23.5 16.75 23.1848 16.75 22.7959L16.75 19.427L18.575 19.427C18.9529 19.427 19.1421 18.9697 18.8749 18.7022L16.2999 16.1244C16.1343 15.9586 15.8657 15.9586 15.7001 16.1244L13.1251 18.7022C12.8579 18.9697 13.0471 19.427 13.425 19.427L15.25 19.427L15.25 22.7959Z" fill="#999999" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.2203 8.62234C14.1985 8.39769 13.1382 8.48355 12.1591 8.87071C11.1799 9.25791 10.3193 9.93179 9.67665 10.8187C9.03384 11.7058 8.63529 12.77 8.5288 13.8909C8.42293 15.0054 8.61031 16.1284 9.06835 17.1331C9.61848 18.5484 10.5863 19.4043 11.5506 19.8948C11.8183 20.031 12.0875 20.1401 12.3502 20.226C11.701 19.6479 11.5597 18.5713 12.2761 17.854L14.8512 15.2763C15.4855 14.6413 16.5146 14.6413 17.1489 15.2763L19.7239 17.854C20.5779 18.7089 20.2132 20.0744 19.2224 20.4976H19.8064C20.7797 20.4976 21.717 20.0848 22.4111 19.3434C23.1058 18.6013 23.5 17.59 23.5 16.5307C23.5 15.4713 23.1058 14.46 22.4111 13.7179C21.717 12.9765 20.7797 12.5637 19.8064 12.5637H19.3926C19.1005 11.6446 18.6043 10.8145 17.946 10.1455C17.1867 9.37375 16.2421 8.84699 15.2203 8.62234ZM16.75 20.4976V19.4269H18.5751C18.9529 19.4269 19.1422 18.9696 18.875 18.7021L16.3 16.1244C16.1343 15.9586 15.8658 15.9586 15.7001 16.1244L13.1251 18.7021C12.8579 18.9696 13.0472 19.4269 13.425 19.4269H15.25V20.4976H16.75Z" fill="#999999" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1067_9086">
                          <rect width="18" height="18" fill="white" transform="translate(7 7)" />
                        </clipPath>
                      </defs>
                    </svg>

                  </div>

                  <p className="text-xs leading-4  text-gray-500 dark:text-gray-400"><span className="font-semibold underline">Click to upload</span> {props?.placeholder ? props?.placeholder : props?.title}</p>
                  <p className="text-[8px] leading-3 text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF ( {props?.filesize ? props?.filesize : "max file size 1mb"} )</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  {...props}
                  name={props?.name}
                  id={props?.name}
                  accept={props?.accept || "image/*"}

                />
              </label>
            </div>
          </div>
        }

      </div>
    </div>
  )
}

export default Fileinput



// 
//   props?.title ? <div className='text-lightgray my-1 text-sm'>
//   </div> :
//     <div className='text-lightgray my-1 text-sm'>
//       {props?.placeholder.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
//     </div>

// 
{/* <div className="flex items-center justify-center w-auto">
  <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full  border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">

    <div className="flex flex-col items-center justify-center py-5">
      <div className="w-7  mb-1 text-gray-400">
        <svg width="12" height="12" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="16" cy="16" r="15.5" stroke="#D9D9D9" />
          <g clipPath="url(#clip0_1067_9086)">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.25 22.7959C15.25 23.1848 15.5858 23.5 16 23.5C16.4142 23.5 16.75 23.1848 16.75 22.7959L16.75 19.427L18.575 19.427C18.9529 19.427 19.1421 18.9697 18.8749 18.7022L16.2999 16.1244C16.1343 15.9586 15.8657 15.9586 15.7001 16.1244L13.1251 18.7022C12.8579 18.9697 13.0471 19.427 13.425 19.427L15.25 19.427L15.25 22.7959Z" fill="#999999" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.2203 8.62234C14.1985 8.39769 13.1382 8.48355 12.1591 8.87071C11.1799 9.25791 10.3193 9.93179 9.67665 10.8187C9.03384 11.7058 8.63529 12.77 8.5288 13.8909C8.42293 15.0054 8.61031 16.1284 9.06835 17.1331C9.61848 18.5484 10.5863 19.4043 11.5506 19.8948C11.8183 20.031 12.0875 20.1401 12.3502 20.226C11.701 19.6479 11.5597 18.5713 12.2761 17.854L14.8512 15.2763C15.4855 14.6413 16.5146 14.6413 17.1489 15.2763L19.7239 17.854C20.5779 18.7089 20.2132 20.0744 19.2224 20.4976H19.8064C20.7797 20.4976 21.717 20.0848 22.4111 19.3434C23.1058 18.6013 23.5 17.59 23.5 16.5307C23.5 15.4713 23.1058 14.46 22.4111 13.7179C21.717 12.9765 20.7797 12.5637 19.8064 12.5637H19.3926C19.1005 11.6446 18.6043 10.8145 17.946 10.1455C17.1867 9.37375 16.2421 8.84699 15.2203 8.62234ZM16.75 20.4976V19.4269H18.5751C18.9529 19.4269 19.1422 18.9696 18.875 18.7021L16.3 16.1244C16.1343 15.9586 15.8658 15.9586 15.7001 16.1244L13.1251 18.7021C12.8579 18.9696 13.0472 19.4269 13.425 19.4269H15.25V20.4976H16.75Z" fill="#999999" />
          </g>
          <defs>
            <clipPath id="clip0_1067_9086">
              <rect width="18" height="18" fill="white" transform="translate(7 7)" />
            </clipPath>
          </defs>
        </svg>

      </div>

      <p className="text-xs leading-4  text-gray-500 dark:text-gray-400"><span className="font-semibold underline">Click to upload</span> {props?.placeholder ? props?.placeholder : props?.title}</p>
      <p className="text-[8px] leading-3 text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF ( {props?.filesize ? props?.filesize : "max file size 1mb"} )</p>
    </div>
    <input
      type="file"
      className="hidden"
      {...props}
      name={props?.name}
      accept="image/*"

    />
  </label>
</div>  */}

