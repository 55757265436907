import React, { Suspense } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import * as Sentry from '@sentry/react'

import ReactDOM from 'react-dom/client';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from 'react-hot-toast';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Modal from './component/modal';
import { applyMiddleware, createStore } from 'redux';
import AllReducers from './Store/Reducers';


const root = ReactDOM.createRoot(
  document.getElementById('root')
);



Sentry.init({
  dsn: "https://b1c79c86f6d84042bba2246d2620987d@o4505214857838592.ingest.sentry.io/4505267788644352",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 1,
      staleTime: 5 * 1000,
      enabled: false,
    },
  },
});

const SetGlobalState = (key, initialData) => {
  queryClient.setQueryData([key], initialData)
}

const GetGlobalState = (key) => {
  const data = queryClient.getQueryData([key])
  return data
}
// const ReactQueryDevtoolsForApp = React.lazy(() =>
//   import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
//     (d) => ({
//       default: d.ReactQueryDevtools,
//     }),
//   ),
// )

const store = createStore(AllReducers, applyMiddleware(thunk));

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Toaster />

      <BrowserRouter>
        <Suspense fallback={<Modal
          type='loader'
          showModal={true}
        />}>
          <App />
        </Suspense >
      </BrowserRouter>
      {/* <ReactQueryDevtoolsForApp initialIsOpen={false} position='bottom-right' /> */}
    </QueryClientProvider>
  </Provider>
);

export { SetGlobalState, GetGlobalState, store }

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
