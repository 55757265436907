import React, { useState, useEffect, useRef } from "react";
import Modal from "../../component/modal";
import { BackArrow, EmailSvg, OtpSentSvg, Spinner } from "../../assest/svgs";
import Title from "../../elements/Title";
import Input from "../../elements/Input";
import Button from "../../elements/Button";
import { Googlesvg, Outlooksvg } from "../../assest/svgs/Login/loginsvg";
import SubTitle from "../../elements/SubTitle";
import logo from "../../assest/svgs/Login/Content.svg";
import Select from "../../elements/Input/Select";
import { PageLabel } from "../../constant/PageLabels";
import OTP from "../../component/OTP";
import { useIsMutating } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { Registerdata } from "../../service/api/auth/register";
import { CheckEmail, GenerateOTPData, VerifyOTPData } from "../../service/api/auth";
import { validateEmail, validateIsNumAndAlp, validateIsRequired, validatePhoneNumber } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import RouteNames from "../../constant/RouteNames";

const CreateAccount = () => {
    const location = useLocation()
   
    const { state } = location
  
    const [model, setModel] = useState({
        email: state?.email,
        name: state?.name || "",
        mobile:state?.mobile || "",
        password: state?.password || "",
        confirmPassword: state?.confirmPassword||"",
    });

    const [errors, setErrors] = useState({
        email: { state: null, errorMessage: "" },
        name: { state: null, errorMessage: "" },
        mobile: { state: null, errorMessage: "" },
        organizationName: { state: null, errorMessage: "" },
        organizationSector: { state: null, errorMessage: "" },
        organizationSize: { state: null, errorMessage: "" },
        subDomain: { state: null, errorMessage: "" },
        password: { state: null, errorMessage: "" },
        confirmPassword: { state: null, errorMessage: "" },
        subDomain: { state: null, errorMessage: "" },
    });
    const [validForm, setvalidForm] = useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showOtpNextStep, setShowOtpNextStep] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "name") {
            const validName = validateIsRequired(value);
            setvalidForm((prev) => validName?.state);
            setErrors((prevState) => ({
                ...prevState,
                [name]: validName,
            }));
        }

        if (name === "password" || name === "confirmPassword") {
            const validPassword = validateIsNumAndAlp(value);
            setvalidForm((prev) => validPassword?.state);
            setErrors((prevState) => ({
                ...prevState,
                [name]: validPassword,
            }));
        }

        if (name === "mobile") {
            const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
            const validMobile = validatePhoneNumber(numericValue);
            setvalidForm((prev) => validMobile?.state);
            setErrors((prevState) => ({
                ...prevState,
                [name]: validMobile,
            }));
            setModel((prevModel) => ({
                ...prevModel,
                [name]: numericValue,
            }));
        }
        else {
            setModel((prevModel) => ({
                ...prevModel,
                [name]: value,
            }));

        }
    };



    const navigate = useNavigate();
    const [count, setcount] = useState(0);
    const [title, setTitle] = useState("");
    const [subtitle, setSubTitles] = useState("");
    const [showOtpInput, setShowOtpInput] = useState(false);

    const [otp, setOTP] = useState(["", "", "", "", "", ""]);
    const inputRefs = useRef([]);

    // useEffect(() => {

    //     const value = otp.join("");

    //     if (value.length < 6) {
    //         setIsBtnDisabled(true);
    //     } else {
    //         setIsBtnDisabled(false);
    //     }
    // }, [otp, showOtpNextStep]);

    // useEffect(() => {
    //     if (count == 0) {
    //         if (errors?.email?.state === false) {
    //             setIsBtnDisabled(false);
    //         } else {
    //             setIsBtnDisabled(true);
    //         }
    //     }

    //     if (count == 1) {
    //         if (
    //             errors?.name?.state === false &&
    //             errors?.mobile?.state === false &&
    //             errors?.password?.state === false &&
    //             errors?.confirmPassword?.state === false
    //         ) {
    //             setIsBtnDisabled(false);
    //         }
    //     }
    //     if (count == 2) {
    //         if (
    //             errors?.organizationName?.state === false &&
    //             model?.organizationSize != "Select size" &&
    //             model?.organizationSector != "Select sector" &&
    //             errors?.subDomain?.state === false && model?.checked
    //         ) {
    //             setIsBtnDisabled(false);
    //         }
    //     }
    // }, [count, model]);

    // Handle keydown event for backspace/delete key
    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" || e.key === "Delete") {
            if (index > 0 && e.target.value === "") {
                e.preventDefault();
                const previousInputRef = inputRefs.current[index - 1];
                if (previousInputRef) {
                    previousInputRef.focus();
                }
            }
        }
    };

    const formSubmit = (e) => {
        e.preventDefault();
        if (model?.password !== model?.confirmPassword) {
            toast.error("Password and confirm password must be same");
            return;
        }
        navigate("/" + RouteNames.REGISTER + "/" + RouteNames.SUBMIT_FORM, { state: { model } })
    };

    const SVG = () => {
        return <OtpSentSvg />;
    };


    const handleBack = () => {
        navigate("/" + RouteNames.REGISTER)
    };



    let show = false;
    const isMutating = useIsMutating();

    return (
        <>
            <div
                className={`${isMutating > 0 ? "animate-pulse opacity-25 cursor-not-allowed " : ""
                    }`}
            >
                <section className="w-full grid items-center ">
                    <form className="mt-20 p-6" onSubmit={formSubmit}>
                        <div
                            className="flex w-[10%] justify-around mb-8 cursor-pointer"
                            onClick={handleBack}
                        >
                            <div className="flex align-middle">
                                <BackArrow />
                            </div>
                            <div className="m-auto">
                                <SubTitle className="font-semibold">
                                    {PageLabel?.commonLabels?.BACK}
                                </SubTitle>
                            </div>
                        </div>
                        <Title className="text-3xl font-semibold not-italic">
                            {PageLabel?.commonLabels?.CREATE_ACCOUNT}
                        </Title>
                        <SubTitle className="mt-3">
                            {PageLabel?.formHeaders?.SIGN_UP_CREATE_SUBTEXT}
                        </SubTitle>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 mb-3">
                            <Input
                                labeltext={PageLabel?.commonLabels?.NAME}
                                type={"text"}
                                // placeholder={"Enter Your Name"}
                                error={errors?.name?.state}
                                errorMessage={errors?.name?.errorMessage}
                                value={model?.name}
                                onChange={handleChange}
                                name={"name"}
                                required
                            />

                            <Input
                                labeltext={PageLabel?.commonLabels?.MOBILE}
                                type={"text"}
                                value={model.mobile}
                                onChange={handleChange}
                                name={"mobile"}
                                error={errors?.mobile?.state}
                                errorMessage={errors?.mobile?.errorMessage}
                                maxLength={10}
                                required
                            />

                            <Input
                                labeltext={PageLabel?.commonLabels?.PWD}
                                type={"password"}
                                value={model?.password}
                                onChange={handleChange}
                                name={"password"}
                                error={errors?.password?.state}
                                errorMessage={errors?.password?.errorMessage}
                                required
                            />
                            <Input
                                labeltext={PageLabel?.commonLabels?.CNFPWD}
                                type={"password"}
                                value={model.confirmPassword}
                                onChange={handleChange}
                                name={"confirmPassword"}
                                error={errors?.confirmPassword?.state}
                                errorMessage={errors?.confirmPassword?.errorMessage}
                                required
                            />
                        </div>
                        <div className="flex justify-end">
                            <Button
                                className="bg-primaryblack text-white max-w-fit"
                            // disabled={isBtnDisabled}
                            >
                                {PageLabel?.buttonText?.NEXT}

                                {isMutating > 0 ? <Spinner /> : <></>}
                            </Button>
                        </div>
                    </form>
                </section>
            </div>
        </>
    );
};

export default CreateAccount;
