import React, { useState, useEffect, useRef } from "react";
import Modal from "../../component/modal";
import { BackArrow, EmailSvg, OtpSentSvg, Spinner } from "../../assest/svgs";
import Title from "../../elements/Title";
import Input from "../../elements/Input";
import Button from "../../elements/Button";
import { Googlesvg, Outlooksvg } from "../../assest/svgs/Login/loginsvg";
import SubTitle from "../../elements/SubTitle";
import logo from "../../assest/svgs/Login/Content.svg";
import Select from "../../elements/Input/Select";
import { PageLabel } from "../../constant/PageLabels";
import OTP from "../../component/OTP";
import { useIsMutating } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { Registerdata } from "../../service/api/auth/register";
import { CheckEmail, GenerateOTPData, VerifyOTPData } from "../../service/api/auth";
import { validateEmail, validateIsNumAndAlp, validateIsRequired, validatePhoneNumber } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import RouteNames from "../../constant/RouteNames";

const OtpInput = ({ }) => {
    const location = useLocation()
    const { state } = location

    const [timer, setTimer] = useState(60);
    const [startTimer, setStartTimer] = useState(false);
    useEffect(() => {
        setStartTimer(true)
        let intervalId;

        if (startTimer) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        if (timer === 0) {
            clearInterval(intervalId);
        }

        // Clear the interval when the component is unmounted or when startTimer is set to false
        return () => clearInterval(intervalId);
    }, [startTimer, timer]);

    const [model, setModel] = useState({
        email: "",
        name: "",
        mobile: "",
        organizationName: "",
        organizationSector: "Select sector",
        organizationSize: "Select size",
        subDomain: "",
        password: "",
        confirmPassword: "",
        tenantId: "567891235678456",
        checked: false,
        subDomain: "",
    });
    // const [checkEmail, setCheckEmail] = useState(false)

    const generateotpapiResp = (data) => {
        if (data?.success === true) {
            setTimer(60)
            setStartTimer(true);
            let title = PageLabel?.modalText?.OTP_TITLE;
            let subtitle = PageLabel?.modalText?.OTP_DESC;
            setTitle(title);
            setSubTitles(subtitle);
            setShowModal(true);
        } else {
            toast.error("Something went wrong Please try After Sometime");
        }
    };
    const verifyotpapiResp = (data) => {
        setIsBtnDisabled(true);
        if (data?.success) {
            navigate("/" + RouteNames.REGISTER + "/" + RouteNames.CREATE_ACCOUNT, { state: { "email": state?.email } })
        }
    };

    const generateotpapi = GenerateOTPData(generateotpapiResp);
    const verifyotpapi = VerifyOTPData(verifyotpapiResp);

    const [errors, setErrors] = useState({
        email: { state: null, errorMessage: "" },
        name: { state: null, errorMessage: "" },
        mobile: { state: null, errorMessage: "" },
        organizationName: { state: null, errorMessage: "" },
        organizationSector: { state: null, errorMessage: "" },
        organizationSize: { state: null, errorMessage: "" },
        subDomain: { state: null, errorMessage: "" },
        password: { state: null, errorMessage: "" },
        confirmPassword: { state: null, errorMessage: "" },
        subDomain: { state: null, errorMessage: "" },
    });
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showOtpNextStep, setShowOtpNextStep] = useState(false);

    const navigate = useNavigate();
    const [count, setcount] = useState(0);
    const [title, setTitle] = useState("");
    const [subtitle, setSubTitles] = useState("");

    const [otp, setOTP] = useState(["", "", "", "", "", ""]);
    const inputRefs = useRef([]);

    // Handle OTP change event
    const handleOTPChange = (e, index) => {
        const value = e.target.value;
        const updatedOTP = [...otp];
        updatedOTP[index] = value;
        setOTP(updatedOTP);
        // If the input value is numeric and not empty,
        // move focus to the next input field
        if (/^\d*$/.test(value) && value.length < 2 && index < 5) {
            const nextInputRef = inputRefs.current[index + 1];
            if (nextInputRef) {
                nextInputRef.focus();
            }
        }
    };

    useEffect(() => {
        const value = otp.join("");
        if (value.length < 6) {
            setIsBtnDisabled(true);
        } else {
            setIsBtnDisabled(false);
        }
    }, [otp, showOtpNextStep]);


    // Handle keydown event for backspace/delete key
    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" || e.key === "Delete") {
            if (index > 0 && e.target.value === "") {
                e.preventDefault();
                const previousInputRef = inputRefs.current[index - 1];
                if (previousInputRef) {
                    previousInputRef.focus();
                }
            }
        }
    };

    const formSubmit = (e) => {
        e.preventDefault();
        if (otp.join("").length == 6) {
            verifyotpapi.mutate({
                email: state?.email,
                otp: 1234,
                // otp.join(''),
                context: "VERIFY_EMAIL",
            });
            return;
        } else {
            return;
        }
    };

    const SVG = () => {
        return <OtpSentSvg />;
    };



    const otpResend = () => {
        if (timer === 0) {
            setOTP(["", "", "", "", "", ""]);
            generateotpapi.mutate({ email: state?.email, context: "VERIFY_EMAIL" });
        } else {
            toast("Please wait for timer to finish");
        }

    };

    // const handleBack = () => {
    //     setModel({
    //         email: "",
    //         name: "",
    //         mobile: "",
    //         organizationName: "",
    //         organizationSector: "Select sector",
    //         organizationSize: "Select size",
    //         subDomain: "",
    //         password: "",
    //         confirmPassword: "",
    //         tenantId: "567891235678456",
    //         checked: false,
    //         subDomain: "",

    //     })
    //     setOTP(["", "", "", "", "", ""]);
    //     setErrors({
    //         email: { state: null, errorMessage: "" },
    //         name: { state: null, errorMessage: "" },
    //         mobile: { state: null, errorMessage: "" },
    //         organizationName: { state: null, errorMessage: "" },
    //         organizationSector: { state: null, errorMessage: "" },
    //         organizationSize: { state: null, errorMessage: "" },
    //         subDomain: { state: null, errorMessage: "" },
    //         password: { state: null, errorMessage: "" },
    //         confirmPassword: { state: null, errorMessage: "" },
    //         subDomain: { state: null, errorMessage: "" },
    //     })
    //     if (count === 0) navigate("/");
    //     else setcount((prev) => prev - 1);

    // };


    let show = false;
    const isMutating = useIsMutating();

    return (
        <>
            <section className="h-full grid items-center justify-center ">
                <div className="min-w-[340px]">
                    <form
                        onSubmit={formSubmit}
                        className={`${isMutating > 0
                            ? "animate-pulse opacity-25 cursor-not-allowed "
                            : ""
                            }`}
                    >
                        <div className="mb-8">
                            <img className="m-auto" src={logo} alt="logo" />
                            <Title className="text-center mt-2  text-3xl font-semibold not-italic">
                                {PageLabel?.formHeaders?.SIGNUP_HEADER}
                            </Title>
                        </div>
                        <Input
                            labeltext={PageLabel?.commonLabels?.EMAIL}
                            placeholder={PageLabel?.inputlabels?.EMAIL_PLACEHOLDER}
                            type={"email"}
                            value={state?.email}
                            // onChange={HandleChange}
                            name={"email"}
                            error={errors?.email?.state}
                            errorMessage={errors?.email?.errorMessage}
                            required
                            disabled={location.pathname === "/signup/otp" ? true : false}
                        />

                        <OTP
                            otp={otp}
                            handleOTPChange={handleOTPChange}
                            handleKeyDown={handleKeyDown}
                            inputRefs={inputRefs}
                            timer={timer}
                            resendClick={otpResend}
                        />

                        <Button
                            type="submit"
                            className=" bg-gray-600 flex gap-2 w-full justify-center shadow-2xl hover:bg-gray-800 mt-4 text-gray-200 rounded-lg"
                            disabled={isBtnDisabled}
                        >
                            {PageLabel?.buttonText?.SIGNUP_UP}

                            {isMutating > 0 ? <Spinner /> : <></>}
                        </Button>
                        {show === true && (
                            <>
                                <Button
                                    type="button"
                                    className=" bg-white flex gap-2 w-full justify-center  text-black hover:bg-gray-100 border border-gray-300"
                                >
                                    <Googlesvg />
                                    {PageLabel?.buttonText?.SIGNUP_UP_GOOGLE}
                                </Button>
                                <Button
                                    type="button"
                                    className=" bg-white flex gap-2 w-full justify-center  text-black hover:bg-gray-100 border border-gray-300"
                                >
                                    <Outlooksvg />
                                    {PageLabel?.buttonText?.SIGNUP_UP_OUTLOOK}
                                </Button>
                            </>
                        )}

                        <SubTitle className={"text-center p-4"}>
                            {PageLabel?.formHeaders?.HAVE_AN_ACCOUNT}
                            <span
                                className="font-bold hover:underline"
                                onClick={() => navigate("/")}
                            >
                                {PageLabel?.buttonText?.SIGNIN}
                            </span>
                        </SubTitle>
                    </form>
                </div>
            </section>
        </>
    );
};

export default OtpInput;
