import React, { useState } from "react";
import Card from "../../../elements/Cards";
import Title from "../../../elements/Title";
import SubTitle from "../../../elements/SubTitle";
import Button from "../../../elements/Button";
import Input from "../../../elements/Input";
import TitleSwitchParagraph from "../../TitleSwitchParagraph";
import { ExportSvg, ImportSvg, DownloadSvg, PlusSvg, OopsEmpty } from '../../../assest/svgs'

const RolePolicyList = () => {

  const [nextPage, setnextPage] = useState(true)

  const handleButtonClick=()=>{
      setnextPage(!nextPage)
  }


  return (
    <>
    {nextPage &&<Card className="p-4 h-screen mt-4">
        <div className="flex justify-between">
          <div>
            <Title>Roles Policy List</Title>
            <SubTitle className="w-4/5">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Delectus
              quibusdam quo consectetur sequi incidunt temporibus optio rem
              animi? Quasi, quam. Lorem ipsum dolor, sit amet consectetur
              adipisicing elit. Doloribus quis asperiores consequuntur
            </SubTitle>
            <div className="mt-1">
              <SubTitle className="flex gap-2">
                <DownloadSvg />
                Download Sample Excel File
              </SubTitle>
            </div>
          </div>
          <div className="flex gap-4">
            <Button className="flex gap-2 border max-h-[40px] ">
              <ExportSvg /> Export
            </Button>
            <Button className="flex gap-2 border max-h-[40px]">
              <ImportSvg />
              Import
            </Button>
          </div>
        </div>
        <div>
          <div className="w-full flex justify-center mt-4">
            <OopsEmpty />
          </div>
          <div className="text-center ">
            <Title>Oops! It’s Empty</Title>
            <SubTitle>Looks like you haven’t added any lead yet..!</SubTitle>
            <div className=" flex justify-center mt-4">
              <Button className="flex gap-2 bg-primaryblack max-w-fit" onClick={handleButtonClick}>
                <PlusSvg />
                <span className="text-[white]">Add</span>
              </Button>
            </div>
          </div>
        </div>
      </Card>}
      

    {!nextPage&&  <div>
      <Card className="my-4 p-4">
        <div>
          <Title className="text-lg font-semibold not-italic mb-3">
            Create Role Policy
          </Title>
          <SubTitle className="text-sm font-medium mb-1">Organization</SubTitle>
          <div className="-mt-3">
            <TitleSwitchParagraph
              subtext={
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, reiciendis fugit qui consequuntur reprehenderit quaerat culpa nam          corrupti error quisquam sed, labore necessitatibus nostrum, eligendi   repellendus facere inventore atque eaque. Deserunt dolorum dolores a aliquam,"
              }
            />
          </div>
        </div>
        <div className="-mt-9">
          <TitleSwitchParagraph subtext={"Level 1"}>
            <div className="grid grid-cols-1 mb-2 md:grid-cols-4 gap-1 w-2/4">
              <Input type="radio" labeltext="Head Office"></Input>
              <Input type="radio" labeltext="School 1"></Input>
              <Input type="radio" labeltext="School 2"></Input>
            </div>
          </TitleSwitchParagraph>

          <TitleSwitchParagraph subtext={"Level 2"}>
            <div className="grid grid-cols-1 mb-2 md:grid-cols-4 gap-1 w-2/4">
              <Input type="radio" labeltext="Department 1"></Input>
              <Input type="radio" labeltext="Department 2"></Input>
              <Input type="radio" labeltext="Department 3"></Input>
            </div>
          </TitleSwitchParagraph>
        </div>
      </Card>
      <Card className="p-4 mt-4">
        <div>
          <Title className="text-lg font-semibold not-italic">Roles</Title>
          <div className="-mt-5">
            <TitleSwitchParagraph
              subtext={
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, reiciendis fugit qui consequuntur reprehenderit quaerat culpa nam          corrupti error quisquam sed, labore necessitatibus nostrum, eligendi   repellendus facere inventore atque eaque. Deserunt dolorum dolores a aliquam,"
              }
            />

            <div className="">
              <div className="grid grid-cols-1 md:grid-cols-5 gap-1 w-5/6">
                <Input type="radio" labeltext="Admin"></Input>
                <Input type="radio" labeltext="Teacher"></Input>
                <Input
                  type="radio"
                  labeltext="Educational Administrators"
                ></Input>
                <Input type="radio" labeltext="Custom Role"></Input>
                <Input type="radio" labeltext="User"></Input>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Card className="p-4 mt-4">
          <Title className="text-lg font-semibold not-italic">Privileges</Title>
          <div className="-mt-5">
            <TitleSwitchParagraph
              subtext={
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, reiciendis fugit qui consequuntur reprehenderit quaerat culpa nam          corrupti error quisquam sed, labore necessitatibus nostrum, eligendi   repellendus facere inventore atque eaque. Deserunt dolorum dolores a aliquam,"
              }
            />
             <div className="grid grid-cols-1 md:grid-cols-5 gap-1 w-5/6">
                <Input type="checkbox" labeltext="Admin"></Input>
                <Input type="checkbox" labeltext="Teacher"></Input>
              </div>
            </div>
      </Card>
      </div>
      }
    </>
  );
};

export default RolePolicyList;
