import RoutesNames from '../constant/RouteNames'
let tabsdata = {
  [RoutesNames.DASHBOARD]: {
    title: 'Dashboard',
    tabmenu: [
      {
        name: 'Staff',
        path: RoutesNames?.STAFF,
      },
      {
        name: 'Student',
        path: RoutesNames?.STUDENT,
      },
    ],
  },
  [RoutesNames.ORGANIZATION]: {
    title: 'Organisation',
    tabmenu: [
      {
        name: 'Profile',
        path: RoutesNames?.PROFILE,
      },
      {
        name: 'Structure',
        path: RoutesNames?.STRUCTURE,
      },
      {
        name: 'Settings',
        path: RoutesNames?.SETTINGS,
      },
      {
        name: 'Subscription',
        path: RoutesNames?.SUBSCRIPTION,
      },
    ],
  },
  [RoutesNames.ROLE_MANAGEMENT]: {
    title: 'Role Management',
    tabmenu: [
      {
        name: 'Role and Privillages*',
        path: RoutesNames.PRIVILLAGES,
      },
      {
        name: 'Designations',
        path: RoutesNames.DESIGNATION,
      },
      {
        name: 'Roles Policy',
        path: RoutesNames.POLICY,
      },
    ],
  },
  [RoutesNames.ONBOARDING]: {
    title: 'On-boarding Process',
    tabmenu: [
      {
        name: 'Profile',
        path: RoutesNames?.PROFILE,
      },
      {
        name: 'Structure',
        path: RoutesNames?.STRUCTURE,
      },
      {
        name: 'Attendance Policy',
        path: RoutesNames?.ATTENDANCE_POLICY,
      },
      {
        name: 'Settings',
        path: RoutesNames?.SETTINGS,
      },
    ],
  },
  [RoutesNames.USER_MANAGEMENT]: {
    title: 'User Management',
    tabmenu: [
      {
        name: 'User Creation',
        path: RoutesNames.CREATION,
      },
      {
        name: 'Transfer/Promotion',
        path: RoutesNames.TRANSFER_PROMOTION,
      },
      {
        name: 'Shifts',
        path: RoutesNames.SHIFTS,
      },
      {
        name: 'Subject/Class Mapping',
        path: RoutesNames.SUBJECT_CLASS_MAPPING,
      },
    ],
  },
  [RoutesNames.ATTENDANCE_POLICY]: {
    title: 'Attendance Policy',
    tabmenu: [
      {
        name: 'Global',
        path: RoutesNames.GLOBAL,
      },
      {
        name: 'Configurable',
        path: RoutesNames.CONFIGURABLE,
      },
    ],
  },
  [RoutesNames.LEAVES]: {
    title: 'Leaves',
    tabmenu: [
      {
        name: 'Org Configuration',
        path: RoutesNames.CONFIGURATION,
      },
      {
        name: 'My Leaves',
        path: RoutesNames.CONFIGURABLE,
      },
    ],
  },
  [RoutesNames.NOTIFICATIONS]: {
    title: 'Notifications',
    tabmenu: [
      {
        name: 'Configuration',
        path: RoutesNames.NOTCONFIGURATION,
      },
      {
        name: 'Notifications',
        path: RoutesNames.NOTIFICATION,
      },
      {
        name: 'Take Action',
        path: RoutesNames.TAKEACTION,
      },
    ],
  },
  [RoutesNames.REPORT_ANALYTICS]: {
    title: 'Reports/Analytics',
    tabmenu: [
      {
        name: 'Attendance',
        path: RoutesNames.ATTENDANCE,
      },
      {
        name: 'Enrolment',
        path: RoutesNames.ENROLLEMENT,
      },
      {
        name: 'Transaction',
        path: RoutesNames.TRANSACTION,
      },
      {
        name: 'Activity',
        path: RoutesNames.ACTIVITY,
      },
      {
        name: 'Usage',
        path: RoutesNames.USAGE,
      },
      {
        name: 'Anomaly',
        path: RoutesNames.ANOMALY,
      },
      {
        name: 'Additional',
        path: RoutesNames.ADDITIONAL,
      }
    ],
  },
}

export default tabsdata
