import React, { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface CardProps {
  className?: string;
  children?: ReactNode;
}


const SkeletonCard: React.FC<CardProps> = (props) => {
  const classes = twMerge("bg-gray-300 rounded-lg animate-pulse w-full", props?.className)
  return (
    <div className={`${classes}`}>{props?.children}</div>
  )
}

export default SkeletonCard