import React, { useState, useEffect, useRef } from "react";
import Input from "../../elements/Input";
import Button from "../../elements/Button";

const DialogTooltip = (props) => {
  const { data, children, keys } = props
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverDirection, setHoverDirection] = useState(null);
  const tooltipContainerRef = useRef(null);
  const tooltipRef = useRef(null);

  const handleClick = (e) => {
    if (!tooltipContainerRef.current.contains(e.target)) {
      setShowTooltip(false);
      setHoverDirection(null);
    } else {
      const rect = e.target.getBoundingClientRect();
      const x = rect.left + (rect.width / 2);
      const y = rect.top + (rect.height / 2);
      const isTop = y < window.innerHeight / 2;
      const isLeft = x < window.innerWidth / 2;
      setShowTooltip(!showTooltip);
      setHoverDirection(isTop && isLeft ? "top-left" : isTop ? "top-right" : isLeft ? "bottom-left" : "bottom-right");
    }
  };

  const handleTooltipClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (showTooltip && tooltipContainerRef.current && !tooltipContainerRef.current.contains(e.target) && !tooltipRef.current.contains(e.target)) {
        setShowTooltip(false);
        setHoverDirection(null);
      }
    };

    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, [showTooltip]);

  return (
    <div className="relative min-w-fit max-w-sm w-1/6 " onClick={handleClick} ref={tooltipContainerRef}>
      <div className="flex justify-center">
        {children}
        
      </div>
      {showTooltip && (
        <div className={`absolute ${hoverDirection ? hoverDirection : "bottom-full"} left-[100%] mb-2 bg-white z-[10000000] shadow-lg rounded-lg py-2 px-4 min-w-max `} ref={tooltipRef} onClick={handleTooltipClick}>
          {data(keys)}
        </div>
      )}
    </div>
  );
};

export default DialogTooltip;
