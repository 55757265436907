import React, { ReactNode, MouseEventHandler } from 'react';
import { twMerge } from 'tailwind-merge';

interface TitleProps {
  className?: string;
  onClick?: MouseEventHandler<HTMLHeadingElement>;
  children?: ReactNode;
}

const Title: React.FC<TitleProps> = (props) => {
  const { className, onClick, children } = props
  const classes = twMerge('font-bold', className);

  return (
    <h1 className={classes} onClick={onClick}>
      {children}
    </h1>
  );
};

export default Title;
