import React from 'react'
import { twMerge } from 'tailwind-merge'


const Select = (props) => {
  const { labeltext, options, value, handleSelection, mandatory, error, errormessage = "Please select an option" } = props
  const classes = twMerge(`w-full mt-2 sm:text-sm`, props.className)


  return (
    <div className={classes}>
      <label htmlFor={labeltext} className="block text-xs  pl-1 py-1 font-medium text-[#555555]">{labeltext}
        {mandatory ? <span className="text-red-600">*</span> : null}

      </label>
      <div className="relative">
        <select className="cursor-pointer block appearance-none w-full bg-gray-50 border border-gray-200 text-gray-700 p-2.5 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state" label='Select a day' onChange={handleSelection}  >
          <option value="" disabled selected hidden>{value}</option>

          {options?.map((option, index) => { return (<option key={index}>{option}</option>) })}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
        </div>
      </div>
      <div className={`input-error ${error === true ? "text-red-600" : "text-green-500"}`}>
        {error === true ? errormessage : ""}
      </div>
    </div>
  )
}

export default Select