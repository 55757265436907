import React from 'react'
import Card from '../../../elements/Cards'
import Tabs from '../../../component/layout/LayoutTabs'
import Title from '../../../elements/Title'
import SubjectClassMappingPresenter from './subject-class-mapping-presenter'
import Select from '../../../elements/Input/Select'

const SubjectClassMapping = () => {
    const { tabMenu, selectedTab, handleTabTBN } = SubjectClassMappingPresenter()
    return (
        <>
            {/* <Title>Subject/Class Mapping</Title> */}
            <Tabs tabMenu={tabMenu} handleTab={handleTabTBN} />
            {selectedTab.component}
        </>
    )
}

export default SubjectClassMapping
