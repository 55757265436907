import React, { useState } from "react";
import Card from "../../../elements/Cards";
import Title from "../../../elements/Title";
import SubTitle from "../../../elements/SubTitle";
import Button from "../../../elements/Button";
import {

  DownloadSvg,
  NextArrowSvg,
  OopsEmpty,
  PlusSvg,
  RolesPolicy1,
} from "../../../assest/svgs";
import { ExportSvg, ImportSvg } from "../../../assest/svgs";
import SkeletonCard from "../../../elements/SkeletonCard";
import AddRemoveCard from "../../AddRemoveCard";
import OnboardingPresenter from "./OnboardingPresenter";
import InputSearch from "../../InputSearch";
import { RolesAndPrivilegesPresenter } from "./RolesAndPivileges/RolesAndPrivilegesPresenter";
import RolesAndPrivilegesTab from "./RolesAndPivileges/RolesAndPrivilegesTab";

const RolesAndPrivileges = () => {
  const {
    MappedTiles,
    EmptyTile,
    inputTile,
    setinputTile,
    datas,
    data,
    onClickNodes,
    NestedList,
    DesignationsMappedTiles,
    DesignationsEmptyTile,
    DesignationsinputTile,
    DesignationssetinputTile,
    isEditable,
  } = OnboardingPresenter();

  const {tabMenu,handleTab,stepcount,selectedTab,goToStep,buttonHandler} = RolesAndPrivilegesPresenter()

  const [nextPage, setnextPage] = useState(true);

  const handleButtonClick = () => {
    setnextPage(!nextPage);
  };

  return (
    <div>
      <>
        {" "}
        {nextPage && (
          <Card className="p-4 h-screen mt-4">
            <div className="flex justify-between">
              <div>
                <Title>Roles and Privileges</Title>
                <SubTitle className="w-4/5">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Delectus quibusdam quo consectetur sequi incidunt temporibus
                  optio rem animi? Quasi, quam. Lorem ipsum dolor, sit amet
                  consectetur adipisicing elit. Doloribus quis asperiores
                  consequuntur
                </SubTitle>
                <div className="mt-1">
                  <SubTitle className="flex gap-2">
                    <DownloadSvg />
                    Download Sample Excel File
                  </SubTitle>
                </div>
              </div>
              <div className="flex gap-4">
                <Button className="flex gap-2 border max-h-[40px] ">
                  <ExportSvg /> Export
                </Button>
                <Button className="flex gap-2 border max-h-[40px]">
                  <ImportSvg />
                  Import
                </Button>
              </div>
            </div>
            <div>
              <div className="w-full flex justify-center mt-4">
                <OopsEmpty />
              </div>
              <div className="text-center ">
                <Title>Oops! It’s Empty</Title>
                <SubTitle>
                  Looks like you haven’t added any lead yet..!
                </SubTitle>
                <div className=" flex justify-center mt-4">
                  <Button
                    className="flex gap-2 bg-primaryblack max-w-fit"
                    onClick={handleButtonClick}
                  >
                    <PlusSvg />
                    <span className="text-[white]">Add</span>
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        )}
      </>
      <>
        {!nextPage && (
          <Card className="p-4 h-screen mt-4">
            <div className="flex justify-between">
              <div>
                <Title>Roles and Privileges</Title>
                <SubTitle className="w-4/5">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Delectus quibusdam quo consectetur sequi incidunt temporibus
                  optio rem animi? Quasi, quam. Lorem ipsum dolor, sit amet
                  consectetur adipisicing elit. Doloribus quis asperiores
                  consequuntur
                </SubTitle>
                <div className="mt-1">
                  <SubTitle className="flex gap-2">
                    <DownloadSvg />
                    Download Sample Excel File
                  </SubTitle>
                </div>
              </div>
            </div>
            <div>
              <AddRemoveCard
                MappedTiles={MappedTiles}
                inputTile={inputTile}
                EmptyTile={EmptyTile}
                setinputTile={setinputTile}
                isEditable={true}
              />
            </div>

            <div className="inline-flex items-center justify-center w-full">
              <hr className="w-full h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
              <span className=" font-medium text-gray-900 left-1/2 ">or</span>
              <hr className="w-full h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
            </div>

            <SubTitle className="px-2">Create Role Name</SubTitle>
            <div className="flex gap-2 p-2">
              <div className="w-1/4">
                <InputSearch />
              </div>
              <Button
                type="button"
                className="border flex gap-1 items-center bg-primaryblack text-white my-0 max-w-fit"
              >
                Submit
              </Button>
            </div>

           <RolesAndPrivilegesTab/>
          </Card>
        )}
      </>
    </div>
  );
};

export default RolesAndPrivileges;
