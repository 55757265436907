import axios from 'axios'
import { toast } from 'react-hot-toast'
import RouteNamesObj from '../constant/RouteNames'
import config from '../constant/config.json';

const unauthroutes = [
  'user/login',
  'user/signup',
  'reset/password',
  'user/logout',
  'generate/otp',
  'verify/otp',
  'checkUser',
]

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

instance.defaults.headers.common['Content-Type'] = 'application/json'
instance.defaults.headers.common['apikey'] = process.env.REACT_APP_API_KEY

instance.interceptors.request.use(
  (request) => {
    const authkey = localStorage.getItem('authdata')

    if (authkey) {
      let data = JSON.parse(authkey)
      const authToken = data?.accessToken
      request.headers['Authorization'] = 'Bearer ' + authToken
      
    } else {
      if (!unauthroutes.includes(request.url)) {
        window.location.replace(
          window.location.protocol + '//' + window.location.host,
        )
      }
    }
    request.headers['tenantId'] = config.TENANT_ID;
    return request
  },
  (error) => {
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { message, code } = error?.response?.data?.errors
    if (code === 401 && message === 'Unauthorized request') {
      toast.error('Token expired Redirecting to login again')
      window.location.replace(
        window.location.protocol + '//' + window.location.host,
      )
    }
    return Promise.reject(error)
  },
)

export default instance
