import React, { useState } from "react";
import Card from "../../elements/Cards";
import Title from "../../elements/Title";
import SubTitle from "../../elements/SubTitle";
import Button from "../../elements/Button";
import { DownloadSvg, OopsEmpty, PlusSvg } from "../../assest/svgs";
import Modal from "../../component/modal";
import UserManagementPresenter from "./user-management-presenter";
import Input from "../../elements/Input";
import Select from "../../elements/Input/Select";
import Table from "../../component/Table";
import InputSearch from "../../component/InputSearch";

const Shifts = () => {
  const {
    handleModal,
    showModal,
    setshowModal,
    handleSubmit,
    shiftData,
    renderSubComponent,
    showCenterModal,
    setShowCenterModal,
    shiftColumn,
    centerModalDataColumns,
    centerModalData
  } = UserManagementPresenter();
  return (
    <>
      <Modal
        type="rightside"
        showModal={showModal}
        setShowModal={setshowModal}
        handleModal={handleModal}
        handleSubmit={handleSubmit}
      >
        <>
          <div className="h-full my-1">
            <form className="mt-1">
              <Title className="text-lg font-semibold not-italic mb-1">
                Create Shifts{" "}
              </Title>
              <SubTitle>
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing.
              </SubTitle>
              <div className="grid grid-cols-1 md:grid-cols-1 gap-y-1">
                <div className="my-3">
                  <SubTitle className="font-medium text-[#555555]">
                    Organization
                  </SubTitle>

                  <Select
                    className="w-3/4 mt-0"
                    defaultOption="Organization 1"
                  />
                </div>
                <div className="w-4/5">
                  <SubTitle className="font-medium text-[#555555]">
                    Shift Name
                  </SubTitle>

                  <Input type="text"></Input>
                  <SubTitle className="font-medium text-[#555555] mt-4">
                    Timings
                  </SubTitle>
                  <div className="flex items-center gap-2 w-full">
                    
                    <Input
                      type="clock"
                      placeholder="HH:MM am"
                      id="clock1"
                    />                    <span>to</span>
                    <Input
                      type="clock"
                      placeholder="HH:MM am"
                      id="clock2"
                    />                  </div>
                </div>
                <div className="w-2/5 mt-4">
                  <SubTitle className="font-medium text-[#555555]">
                    Working hours
                  </SubTitle>
                  <Input
                    type="clock"
                    placeholder="0h:0m"
                    id="clock3"
                  />                   </div>

                <div className="w-4/5 mt-3">
                  <SubTitle className="font-medium text-[#555555]">
                    Break Time
                  </SubTitle>
                  <div className="flex w-3/4">
        <Button className="bg-zinc-500 text-white p-1 w-fit">Fixed</Button>
        <Button className="text-black border-slate-700 p-1 w-fit">Flexible</Button>
      </div>
                  <div className="flex items-center gap-2">
                    <Input
                      type="clock"
                      placeholder="HH:MM am"
                      id="clock4"
                    />                       <span>to</span>
                    <Input
                      type="clock"
                      placeholder="HH:MM am"
                      id="clock5"
                    />                     </div>
                </div>
              </div>
              <div className="flex justify-end gap-3 mt-2">
                <Button
                  type="button"
                  className="flex max-h-[40px] items-center bg-primaryblack text-white max-w-fit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </>
      </Modal>

      <Modal
        type="center"
        showModal={showCenterModal}
        setShowModal={setShowCenterModal}
      // handleModal={handleModal}
      // handleSubmit={handleSubmit}
      >
        <>
          <div className="text-left p-4">
            <div className="mb-1.5">
              <Title className="text-xl">Assign Shifts</Title>
            </div>
            <div className="w-4/6 mb-3">
              <SubTitle className="">
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.
              </SubTitle>
            </div>
            <div>
              <Card className="flex justify-between px-3 py-2 bg-[#F4F4F4]">
                <div>
                  <SubTitle>Shift Name</SubTitle>
                  <Title>Morning Shift</Title>
                </div>
                <div>
                  <SubTitle>Start Time</SubTitle>
                  <Title>05:00 am</Title>
                </div>
                <div>
                  <SubTitle>End Time</SubTitle>
                  <Title>03:00 pm</Title>
                </div>
                <div className="text-center">
                  <SubTitle>Working Hours</SubTitle>
                  <Title>10</Title>
                </div>
                <div className="text-center">
                  <SubTitle>Break</SubTitle>
                  <Title>Fixed (09:30 am - 06:30 pm)</Title>
                </div>
              </Card>
            </div>

            <div className="flex gap-4 items-baseline">
              <div className="w-1/5">
                <Select
                  defaultOption="Select options"
                  labeltext="Assign To"
                ></Select>
              </div>

              <div className="">
                <SubTitle className="font-medium text-[#555555] mb-1">
                  Schedule Shift
                </SubTitle>
                <div className="flex  text-center gap-2">
                  
                <Input
                    type="calender"
                  
                    placeholder="DD-MM-YYYY"
                    id="calender1"
                  />

                  <span className="">to</span>

                  <Input
                    type="calender"
                   
                    placeholder="DD-MM-YYYY"
                    id="calender2"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center">

                <Table
                  data={centerModalData}
                  columns={centerModalDataColumns}
                  pagination="true"
                  search="true"
                />
              
            </div>
            <div className="flex justify-end">
              <div className="flex gap-2">
                <Button
                  type="button"
                  className="border flex gap-1 items-center bg-primaryblack text-white"
                >
                  Submit
                </Button>
              </div>
            </div>

          </div>
        </>
      </Modal>

      <Card className="p-4  mt-4">
        <div className="flex justify-between">
          <div>
            <Title>Shifts</Title>
            <SubTitle className="w-4/5">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Delectus
              quibusdam quo consectetur sequi incidunt temporibus optio rem
              animi? Quasi, quam. Lorem ipsum dolor, sit amet consectetur
              adipisicing elit. Doloribus quis asperiores consequuntur
            </SubTitle>
            <div className="mt-1">
              <SubTitle className="flex gap-2 underline underline-offset-1">
                <DownloadSvg />
                Download Sample Excel File
              </SubTitle>
            </div>
          </div>
          <div className=" flex justify-center mt-4">
            <Button
              className="flex gap-2 bg-primaryblack "
              onClick={handleModal}
            >
              <PlusSvg />
              <span className="text-[white]">Add</span>
            </Button>
          </div>
        </div>
        <div>
          <div className="w-full flex justify-center mt-4">
            <OopsEmpty />
          </div>
          <div className="text-center ">
            <Title>Oops! It’s Empty</Title>
            <SubTitle>Looks like you haven’t added any lead yet..!</SubTitle>
            <div className=" flex justify-center mt-4">
              <Button className="flex gap-2 bg-primaryblack max-w-fit"
                onClick={() => setShowCenterModal((prev) => !prev)}
              >
                <PlusSvg />
                <span className="text-[white]">Add</span>
              </Button>
            </div>
          </div>
        </div>
      </Card>
      <Card className="p-4 flex justify-center">
          <Table
            data={shiftData}
            columns={shiftColumn}
            
            pagination="true"
            search="true"
          />
        
      </Card>
    </>
  );
};

export default Shifts;
