const Routes = {
  HOME: '',
  LOGIN_SUBMIT: "loginsubmit",
  // ONBOARDING:"/onboarding/",
  DASHBOARD: 'dashboard',
  STAFF: 'staff',
  STUDENT: 'student',

  ORGANIZATION: 'organization',
  PROFILE: 'profile',
  SETTINGS: 'settings',
  STRUCTURE: 'structure',
  SUBSCRIPTION: 'subscription',

  ROLE_MANAGEMENT: 'role',
  PRIVILLAGES: 'privillages',
  DESIGNATION: 'designation',
  POLICY: 'policy',

  USER_MANAGEMENT: 'usermanagement',
  CREATION: 'creation',
  TRANSFER_PROMOTION: 'transfer_or_promotion',
  SHIFTS: 'shifts',
  SUBJECT_CLASS_MAPPING: 'subject_or_class_mapping',

  ATTENDANCE_POLICY: 'attendancyPolicy',
  GLOBAL: 'global',
  CONFIGURABLE: 'configurable',

  LEAVES: 'leaves',
  CONFIGURATION: 'orgconfiguration',
  MYLEAVES: 'myleaves',

  NOTIFICATIONS: 'notifications',
  NOTCONFIGURATION: 'notconfiguration',
  NOTIFICATION: 'notification',
  TAKEACTION: 'takeaction',

  REPORT_ANALYTICS: 'reportanalytics',
  ATTENDANCE: 'attendance',
  ENROLLEMENT: 'enrolment',
  TRANSACTION: 'transaction',
  ACTIVITY: 'activity',
  USAGE: 'usage',
  ANOMALY: 'anomaly',
  ADDITIONAL: 'additional',


  HOLIDAYS: 'holidays',
  DATA_MANAGEMENT: 'datamanagement',
  ONBOARDING: 'onboarding',

  FORGOT_PASSWORD: 'forgotpassword',
  FORGOT_PASSWORD_OTP: "otp",
  SET_NEW_PASSWORD: "newpassword",


  GET_USERID: 'getuserid',
  GET_USER_ID_OTP: "otp",


  REGISTER: 'signup',
  OTP: "otp",
  CREATE_ACCOUNT: "createaccount",
  SUBMIT_FORM: "submitsignupform"

}

// type RouteNames = keyof typeof Routes;

const RouteNames = Object.freeze(Routes)

export default RouteNames
