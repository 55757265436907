import React from 'react'
import { Outlet } from 'react-router-dom'
import TabLayout from '../../component/layout/LayoutTabs'
import RouteNames from '../../constant/RouteNames'
import Card from '../../elements/Cards'

const OnboardingLayout = () => {
  return (
    <Card>
        <TabLayout type={RouteNames.ONBOARDING} islayout={false} flag={true}/>
        <Outlet />
    </Card>
  )
}

export default OnboardingLayout;