import config from '../constant/config.json';
const {
  v1: uuidv1,
} = require('uuid');

export const generateRandomNumber = () => {
  const randomNumber = () => uuidv1();
  return randomNumber;
}

const destructureWeeksInExceptionals = (week) => {
  let finalWeek = week.replace(/{/g, '');
  finalWeek = finalWeek.replace(/}/g, '');
  return finalWeek.split(',');
}

export const preprocessExceptionalDays = (workingDays) => {
  const finalExceptionalDays = workingDays.map((item) => {
    return {
      ...item,
      exceptionalDayMonthTo: config.MONTHS[item.exceptionalDayMonthTo.toUpperCase()],
      exceptionalDayMonthFrom: config.MONTHS[item.exceptionalDayMonthFrom.toUpperCase()],
      exceptionalDayDay: config.WEEKS[item.exceptionalDayDay.toUpperCase()],
      exceptionalDayStatus: config.STATUS_VALUES.ACTIVE,
      exceptionalWeekDays: item?.exceptionalWeekDays?.map(i => i.isSelected ? i.name : undefined).filter(j => j !== undefined),
    }
  });
  return finalExceptionalDays;
}

export const filterFetchedAttendancePolicies = (data, types) => {
  return data.filter(item => types.includes(item.attendancePolicyStngType));
}

export const mapWeekIndexByWeekDay = (day) => {
  return Object.keys(config.WEEKS).find(key => config.WEEKS[key].toString() === day);
}

export const convertObjectValuesAsKeys = (object) => {
    var ret = {};
    for(var key in object){
      ret[object[key]] = key;
    }
    return ret;
}

export const postprocessWorkingDays = (workingDays) => {
  const finalWorkingDays = workingDays.map((item) => {
    return {
      ...item,
      day: mapWeekDayByWeekIndex(item.workingDayDay),
      index: item.workingDayDay,
      start: item.workingDayFrom,
      end: item.workingDayTo,
      selected: item.workingDayStatus === config.STATUS_VALUES.ACTIVE.toString(),
      status: item.workingDayStatus,
    }
  });
  return finalWorkingDays;
}
export const postprocessBreakTimings = (breaks) => {
  const finalBreakTimings = {};
  if (breaks) {
    breaks.map((item) => {
      finalBreakTimings[item.breakTimeId] = {
        ...item,
      }
      return item;
    });
  }
  return finalBreakTimings;
}

export const preprocessWorkingDays = (workingDays) => {
  const finalWorkingDays = workingDays.map((item) => {
    return {
      ...item,
      workingDayId: item.workingDayId,
      exceptionalDayMonthTo: item.day,
      workingDayDay: item.index,
      workingDayFrom: item.start,
      workingDayTo: item.end,
      workingDayStatus: item.selected ? config.STATUS_VALUES.ACTIVE : config.STATUS_VALUES.ARCHIVED,
    }
  });
  return finalWorkingDays;
}

export const postprocessGeoFencing = (key, geoLocationData) => {
  if (key === config.GEO_LOCATION_TYPES.GEO_FENCING) {
    if(geoLocationData?.length > 0) {
      const coOrds = geoLocationData[0].geolocationCoords;
      return {
          geolocationId: geoLocationData[0].geolocationId,
          latitude:coOrds.latitude,
          longitude: coOrds.longitude,
          geolocationRange: geoLocationData[0].geolocationRange,
        }
    } else return {};
  } else {
    const finalMultiGeos = {};
  if (geoLocationData) {
    geoLocationData.map((item) => {
      finalMultiGeos[item.geolocationId] = {
        ...item,
        latitude: item.geolocationCoords.latitude,
        longitude: item.geolocationCoords.longitude,
     }
      return item;
    });
  }
  return finalMultiGeos;
  }
}

export const preprocessGeoFencing = (key, geoLocationData) => {
  if (key === config.GEO_LOCATION_TYPES.GEO_FENCING) {
    return [
      {
        geolocationId: geoLocationData.geolocationId,
        geolocationType: key,
        geolocationCoords: {
          latitude: geoLocationData.latitude,
          longitude: geoLocationData.longitude
        },
        geolocationStatus: config.STATUS_VALUES.ACTIVE,
        geolocationRange: geoLocationData.geolocationRange,
      }
    ]
  } else if(key === config.GEO_LOCATION_TYPES.MULTI_GEO ||
    key === config.GEO_LOCATION_TYPES.POLY_GEO
  ) {
    const finalMultiGeos = geoLocationData.map((multiGeo) => {
      return {
        ...multiGeo,
        geolocationType: key,
        geolocationCoords: {
          latitude: multiGeo.latitude,
          longitude: multiGeo.longitude
        },
      }
    });
    return finalMultiGeos;
  }
}

export const preprocessAPSettings = (data) => {
  if(data) {
    const finalAPSettings = [];
    data.map((item) => {
      if(item) {
        item.map((i) => {
          finalAPSettings.push({
            attdPolicyStngId: i?.attdPolicyStngId,
            attendancePolicyStngType: i?.attendancePolicyStngType,
            attendancePolicyStngValue: convertStringToUppercase(i.attendancePolicyStngValue),
          }); 
          return i; 
        });
      }
      return item;
    });
    return finalAPSettings;
  }
  return [];
}

export const mapMonthByMonthIndex = (index) => {
  return Object.keys(config.MONTHS).find(key => config.MONTHS[key].toString() === index);
}
export const mapWeekDayByWeekIndex = (index) => {
  let weekValues = convertObjectValuesAsKeys(config.WEEKS);
  let finalWeekValues = Object.keys(weekValues).reduce((ac,k) => ({...ac,[k]:convertStringToSentenceCase(weekValues[k].slice(0,3))}),{})
  return finalWeekValues[index];
}

const assignWeekDaysForAnExceptional = (exceptionalWeekdays) => {
  let weeks = [];
  Object.keys(config.WEEKS_FOR_MONTH).map((item) => {
    weeks.push({
      name: item,
      isSelected: false,
    });
    return item;
  });
  weeks = weeks.map((i) => {
    if (exceptionalWeekdays.includes(i.name)) {
      return {
        ...i,
        isSelected: true,
      }
    }
    return i;
  });
  return weeks;
}
export const postprocessExceptionalDays = (workingDays) => {
  const finalExceptionalDays = {};
  if (workingDays) {
    workingDays.map((item) => {
      finalExceptionalDays[item.exceptionalDayId] = {
        ...item,
        exceptionalDayMonthTo: convertStringToSentenceCase(mapMonthByMonthIndex(item.exceptionalDayMonthTo)),
        exceptionalDayMonthFrom: convertStringToSentenceCase(mapMonthByMonthIndex(item.exceptionalDayMonthFrom)),
        exceptionalDayDay: convertStringToSentenceCase(mapWeekIndexByWeekDay(item.exceptionalDayDay)),
        exceptionalWeekDays: assignWeekDaysForAnExceptional(destructureWeeksInExceptionals(item.exceptionalWeekDays)),
      }
      return item;
    });
  }
  return finalExceptionalDays;
}
export function CaptchaGenerator(lengthOtp = 4) {
  var charsArray = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  var captcha = []
  for (var i = 0; i < lengthOtp; i++) {
    //below code will not allow Repetition of Characters
    var index = Math.floor(Math.random() * charsArray.length + 1) //get the next character from the array
    if (captcha.indexOf(charsArray[index]) === -1)
      captcha.push(charsArray[index])
    else i--
  }
  let test = captcha.filter((x) => x !== undefined)
  if (test.length === 3) {
    captcha = [...captcha, Math.floor(Math.random() * 9)]
  }

  const canv = document.createElement('canvas')
  canv.width = 72
  canv.height = 40
  var ctx = canv.getContext('2d')
  ctx.font = '25px Georgia'
  ctx.strokeText(captcha.join(''), 0, 30)

  // Get the image data from the canvas
  const imageData = canv.toDataURL()
  return { imageData, code: captcha?.join('') }
}

export function validateIsNumAndAlp(value) {
  let error
  const numericPattern = new RegExp(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
  )
  if (!numericPattern.test(value)) {
    error = {
      state: true,
      errorMessage:
        'Password should have one upper-case, one lower-case, a digit and a non-alphanumeric character',
    }
    // error = 'Password should be 8 characters or longer with at least one upper-case, one lower-case, a digit, and a alphanumeric number'
  } else {
    error = { state: false, errorMessage: '' }
  }
  return error
}

export function validateEmail(value) {
  let error
  if (!value || value === undefined || value.toString().trim() === '') {
    error = { state: true, errorMessage: 'required' }
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = { state: true, errorMessage: 'Invalid Email Address' }
  } else {
    error = { state: false, errorMessage: '' }
  }
  return error
}

export function validatePhoneNumber(value) {
  let error;
  const phonePattern = /^\d{10}$/; // Assuming a 10-digit phone number format

  if (!phonePattern.test(value)) {
    error = {
      state: true,
      errorMessage: 'Phone number should be a 10-digit number',
    };
  } else {
    
    error = { state: false, errorMessage: '' };
  }

  return error;
}

export const convertStringToSentenceCase = (str) => str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());

export const convertStringToUppercase = (str) => str?.toUpperCase();


export function validateIsRequired(value) {
  let error;
  if (value === null || value === undefined || value.toString().trim() === "") {
    error = {
      state: true,
      errorMessage: 'Required',
    };
  }else {
    error = { state: false, errorMessage: '' };
  }

  return error;
}

