import React from "react";
import Card from "../../../elements/Cards";
import Title from "../../../elements/Title";
import SubTitle from "../../../elements/SubTitle";
import Input from "../../../elements/Input";
import TitleParagraph from "../../TitleSwitchParagraph";
import TitleSwitchParagraph from "../../TitleSwitchParagraph";

const OrganisationSettings = () => {
  return (
    <>
      <Card className="my-4 p-4 w-full">
        <Title className="text-left text-lg">Settings</Title>
        <TitleSwitchParagraph
          text={"Manual attendance"}
          subtext={
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, reiciendis fugit qui consequuntur reprehenderit quaerat culpa nam          corrupti error quisquam sed, labore necessitatibus nostrum, eligendi   repellendus facere inventore atque eaque. Deserunt dolorum dolores a aliquam, fugit quis exercitationem aspernatur natus quod sed dolor  laboriosam ipsum quae accusantium, nulla est? Animi?"
          }
          toggle
        >
          <div className="grid grid-cols-1 md:grid-cols-4 gap-3 w-full md:h-4/6 lg:w-3/6 lg:grid-cols-4">
            <Input
              labeltext={"Auto Approval"}
              placeholder={"••••••••"}
              type={"radio"}
              // value={model.orgname}
              // onChange={HandleChange}
              name={"orgname"}
            />
            <Input
              labeltext={"with Manual Approval"}
              placeholder={"••••••••"}
              type={"radio"}
              // value={model.orgname}
              // onChange={HandleChange}
              name={"orgname"}
            />
          </div>
        </TitleSwitchParagraph>

        <TitleSwitchParagraph
          text={"Attendance Regularization"}
          subtext={
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, reiciendis fugit qui consequuntur reprehenderit quaerat culpa nam corrupti error quisquam sed, labore necessitatibus nostrum, eligendi repellendus facere inventore atque eaque. Deserunt dolorum dolores a aliquam, fugit quis exercitationem aspernatur natus quod sed dolor laboriosam ipsum quae accusantium, nulla est? Animi?"
          }
          toggle
        >
          {/* <div className="w-7/12"> */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-3 w-full md:h-4/6 lg:w-3/6 lg:grid-cols-4">
            <Input
              labeltext={"Yes"}
              placeholder={"••••••••"}
              type={"radio"}
              // value={model.orgname}
              // onChange={HandleChange}
              name={"orgname"}
            />
            <Input
              labeltext={"No"}
              placeholder={"••••••••"}
              type={"radio"}
              // value={model.orgname}
              // onChange={HandleChange}
              name={"orgname"}
            />
          </div>

          {/* </div> */}
        </TitleSwitchParagraph>

        <TitleSwitchParagraph
          text={"SSO"}
          subtext={
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, reiciendis fugit qui consequuntur reprehenderit quaerat culpa nam corrupti error quisquam sed, labore necessitatibus nostrum, eligendi repellendus facere inventore atque eaque. Deserunt dolorum dolores a aliquam, fugit quis exercitationem aspernatur natus quod sed dolor laboriosam ipsum quae accusantium, nulla est? Animi?"
          }
          toggle
        >
          <div className="grid grid-cols-1 md:grid-cols-4 gap-3 w-full md:h-4/6 lg:w-3/6 lg:grid-cols-4">
            <div>
              <Input
                labeltext={"SAML"}
                placeholder={"••••••••"}
                type={"checkbox"}
                // value={model.orgname}
                // onChange={HandleChange}
                name={"orgname"}
              />
            </div>
            <div>
              <Input
                labeltext={"LADP"}
                placeholder={"••••••••"}
                type={"checkbox"}
                // value={model.orgname}
                // onChange={HandleChange}
                name={"orgname"}
              />
            </div>
            <div>
              <Input
                labeltext={"Microsoft Azure"}
                placeholder={"••••••••"}
                type={"checkbox"}
                // value={model.orgname}
                // onChange={HandleChange}
                name={"orgname"}
              />
            </div>
            <div>
              <Input
                labeltext={"Login with Google"}
                placeholder={"••••••••"}
                type={"checkbox"}
                // value={model.orgname}
                // onChange={HandleChange}
                name={"orgname"}
              />
            </div>
          </div>
        </TitleSwitchParagraph>

        <TitleSwitchParagraph
          text={"Push Notification for App"}
          subtext={
            "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups"
          }
          toggle
        ></TitleSwitchParagraph>

        <TitleSwitchParagraph
          text={"Alerts/Notifications for users"}
          subtext={
            "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups"
          }
          toggle
        ></TitleSwitchParagraph>

        <TitleSwitchParagraph
          text={"MFA"}
          subtext={
            "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups"
          }
          toggle
        ></TitleSwitchParagraph>

        <TitleSwitchParagraph
          text={"Unique id for login app for web"}
          subtext={
            "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups"
          }
          toggle
        >
          <div className="grid grid-cols-1 md:grid-cols-4 gap-3 w-full md:h-4/6 lg:w-3/6 lg:grid-cols-4">
            <Input
              labeltext={"Email ID"}
              placeholder={"••••••••"}
              type={"checkbox"}
              // value={model.orgname}
              // onChange={HandleChange}
              name={"orgname"}
            />
            <Input
              labeltext={"Phone Number"}
              placeholder={"••••••••"}
              type={"checkbox"}
              // value={model.orgname}
              // onChange={HandleChange}
              name={"orgname"}
            />
            <Input
              labeltext={"Employee ID/Staff ID"}
              placeholder={"••••••••"}
              type={"checkbox"}
              // value={model.orgname}
              // onChange={HandleChange}
              name={"orgname"}
            />
          </div>
        </TitleSwitchParagraph>
      </Card>
    </>
  );
};

export default OrganisationSettings;
