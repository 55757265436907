import React from "react";
import Title from "../../../elements/Title";
import Button from "../../../elements/Button";
import {
  BackwordArrowsvg,
  NextArrowSVG,
  Ticksvg,
} from "../../../assest/svgs";
import Tabs from "../../layout/LayoutTabs";
import Card from "../../../elements/Cards";
import OnboardingPresenter from './OnboardingPresenter'

const Onboarding = (props) => {
  const {
    showModal,
    setShowModal,
    handleModal,
    title,
  } = props;
const {tabMenu,handleTab,stepcount,selectedTab,goToStep,buttonHandler} = OnboardingPresenter()

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full mt-16 ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none h-90vh p-4">
                <div className="flex flex-col  w-full h-full">
                  <div className="sticky top-0 w-full h-5 flex items-center justify-between mb-2">
                    <Title className="text-2xl">{title}</Title>
                    <div className="flex justify-between gap-4 items-center">
                      <div>
                        <div className="flex items-center justify-between px-1">
                          <div className="text-[12px] font-bold opacity-70">
                            Onboarding Process
                          </div>
                          <div className="text-[9px] flex items-center gap-1">
                            <div>{stepcount + 1}</div>
                            of
                            <div>7</div>
                          </div>
                        </div>
                        <div>
                          <div className=" border  grid grid-cols-7 h-[8px] rounded bg-[#ECECEC] overflow-hidden divide-x">
                            {[1, 1, 1, 1, 1, 1, 1].map((step, index) => {
                              if (index < stepcount)
                                return (
                                  <div
                                  key={index}
                                    className="w-8 bg-orange-300  h-full hover:bg-orange-400 cursor-pointer"
                                    onClick={() => goToStep(index)}
                                  ></div>
                                );
                              if (stepcount === index)
                                return (
                                  <div className="w-8 bg-orange-300  h-full hover:bg-orange-400  rounded-r"
                                  key={index}
                                  ></div>
                                );
                              return (
                                <div className="bg-white w-8 h-full rounded-r" key={index}></div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setShowModal(false);
                        }}
                      >
                        <Ticksvg />
                      </div>
                    </div>
                  </div>
                  {/* {props?.children} */}
                  
                  
                  <>
                    <Tabs
                      tabMenu={tabMenu}
                      handleTab={handleTab}
                      selectedTab={stepcount}
                    />
                    <Card
                      className="h-[80vh] overflow-hidden p-4 flex-grow overflow-y-auto "
                    >
                      {selectedTab?.component}
                    </Card>
                  </>


                  <div className="sticky bottom-0 w-full flex justify-between  ">
                    <div>
                      <Button
                        type="button"
                        className="border bg-white hover:bg-slate-100"
                      >
                        Save Draft
                      </Button>
                    </div>
                    <div className="flex gap-2">
                      {stepcount !== 0 && (
                        <Button
                          type="button"
                          className="border bg-white hover:bg-slate-100 flex gap-1 items-center"
                          onClick={() => buttonHandler(-1)}
                        >
                          <BackwordArrowsvg />
                          Back
                        </Button>
                      )}
                      {stepcount !== 3 && (
                        <Button
                          type="button"
                          className="border bg-white hover:bg-slate-100"
                          onClick={() => buttonHandler(1)}
                        >
                          Skip
                        </Button>
                      )}
                      <Button
                        type="button"
                        className="border flex gap-1 items-center bg-primaryblack text-white"
                        onClick={() => buttonHandler(1)}
                      >
                        {stepcount !== 3 ? "Next" : "Submit"}
                        <NextArrowSVG />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-80 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Onboarding;
