import React from "react";
import { CloseSvg, Ticksvg } from "../../assest/svgs";

const RightSide = (props) => {
  const { showModal, handleModal, title, subtitle, setShowModal } = props;
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-end  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative max-w-fit min-w-[240px] w-5/12 ">
              {/*content*/}
              <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-full p-5">
                
                <CloseSvg onClick={handleModal} />

              
                {props?.children}

              </div>
            </div>
          </div>
          <div className="opacity-80 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default RightSide;
