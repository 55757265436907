import React, { useEffect, useState } from "react";
import {
  Crosssvg,
  DownArrowSvg,
  MinusSvg,
  PlusSvg,
} from "../../../assest/svgs";
import Input from "../../../elements/Input";
import { TreeNode } from "react-organizational-chart";
import DialogTooltip from "../../Dialog";
import Button from "../../../elements/Button";
import OrganisationProfile from "./OrganisationProfile";
import OrganisationStructure from "./OrganisationStructure";
import RolesAndPrivileges from "./RolesAndPrivileges";
import RolePolicyList from "./RolePolicyList";
import AttendancePolicy from "./AttendancePolicy";
import UserCreation from "./UserCreation";
import OrganisationSettings from "./OrganisationSettings";
import DashboardFeatures from "./RolesAndPivileges/DashboardFeatures";
import OneOneAppFeatures from "./RolesAndPivileges/OneOneAppFeatures";
import OneNAppFeatures from "./RolesAndPivileges/OneNAppFeatures";
import NNAppFeatures from "./RolesAndPivileges/NNAppFeatures";

const OnboardingPresenter = () => {

 

  const {
    MappedTiles: DesignationsMappedTiles,
    EmptyTile: DesignationsEmptyTile,
    inputTile: DesignationsinputTile,
    setinputTile: DesignationssetinputTile,
  } = AddremoveDesignations();

  const {
    MappedTiles,
    EmptyTile,
    inputTile,
    setinputTile,
    isEditable,
    setisEditable,
  } = AddremoveTemplates();

  const {
    datas,
    data,
    onClickNodes,
    NestedList,
    isEditable: isEditableTree,
    setisEditable: setisEditableTree,
  } = AddremoveTree();

  const [stepcount, setstepcount] = useState(0);

  const buttonHandler = (step) => {
    if (!(step === -1 && stepcount === 0)) goToStep(stepcount + step);
  };

  const goToStep = (index) => {
    // if (index === 2) setisEditable((prev) => !prev);
    setisEditable(prev=> !prev)
    if (index > 6) return;
    setstepcount(index);
    setselectedTab(tabMenu[index]);
  };

  // TABS COMPONENT
  const tabmenu = [
    { name: "Organisation Profile", component: <OrganisationProfile /> },
    { name: "Organisation Structure", component: <OrganisationStructure /> },
    { name: "Roles and Privileges", component: <RolesAndPrivileges /> },
    { name: "Role Policy", component: <RolePolicyList /> },
    { name: "Attendance Policy", component: <AttendancePolicy /> },
    { name: "User Creation", component: <UserCreation /> },
    { name: "Organisation Settings", component: <OrganisationSettings /> },
  ];

  // const secondTabMenu = [
  //   { name: "Dashboard Features", component: <DashboardFeatures /> },
  //   { name: "1:1 App Features", component: <OneOneAppFeatures /> },
  //   { name: "1:N App Features", component: <OneNAppFeatures /> },
  //   { name: "N:N App Features", component: <NNAppFeatures /> },
  // ]

  // const [secondTabMenu, setsecondTabMenu] = useState(setabMenu)

  // const [secondselectedTab, setsecondselectedTab] = useState(secondTabMenu[secondStepCount])

  

  const [tabMenu, setabMenu] = useState(tabmenu);
  const [selectedTab, setselectedTab] = useState(tabmenu[stepcount]);

  const handleTab = (params) => {
    let currentstep;
    const temp = [...tabmenu].map((i, idx) => {
      if (i?.name === params?.name) {
        currentstep = idx;
      }
      return i;
    });
    setabMenu([...temp]);
    goToStep(currentstep);
  };

  return {
    isEditable,
    MappedTiles,
    EmptyTile,
    inputTile,
    setinputTile,
    datas,
    data,
    onClickNodes,
    NestedList,
    DesignationsMappedTiles,
    DesignationsEmptyTile,
    DesignationsinputTile,
    DesignationssetinputTile,
    tabMenu,
    handleTab,
    stepcount,
    selectedTab,
    goToStep,
    buttonHandler,
    isEditableTree,
    setisEditableTree,
    setisEditable,
  };
};

const AddremoveTree = () => {
  const [isEditable, setisEditable] = useState(false);

  const [data, setData] = useState({
    id: 0,
    key: "Root",
    children: [
      { id: 1, key: "Child 1", children: [{ id: 2, key: "Grand child 1" }] },
      {
        id: 3,
        key: "Child 2",
        children: [
          {
            id: 4,
            key: "Grand child 2",
            children: [{ id: 5, key: "Great Grand child 3" }],
          },
        ],
      },
      {
        id: 6,
        key: "Child 3",
        children: [
          { id: 7, key: "Grand child 3" },
          { id: 8, key: "Grand child 4" },
        ],
      },
    ],
  });

  const onClickNodes = (id) => {
    const newObj = {
      id: parseInt(Math.random() * 1000000),
      key: `Child ${parseInt(Math.random() * 100)}`,
    };
    addChildObject(id?.id, newObj);
  };

  const deleteNode = (node) => {
    let nodeId = node?.id;
    const removeNode = (nodes) => {
      return nodes.filter((node) => {
        if (node.id === nodeId) {
          return false; // Exclude the node from the resulting array
        } else if (node.children) {
          node.children = removeNode(node.children);
        }
        return true; // Include the node in the resulting array
      });
    };

    setData((prevData) => {
      const newData = { ...prevData };
      newData.children = removeNode(newData.children);
      return newData;
    });
  };

  const updateNode = (id, newKey) => {
    const findAndModifyKey = (node) => {
      if (node.id === id) {
        // Update the key value
        return { ...node, key: newKey };
      }

      if (node.children) {
        // Recursively search in children
        return {
          ...node,
          children: node.children.map((child) => findAndModifyKey(child)),
        };
      }

      return node;
    };

    // Find and modify the key in the data object
    const updatedData = findAndModifyKey(data);
    setData(updatedData);
  };

  const NestedList = ({ data }) => {
    return data.map((item, index) => {
      const { key, id } = item;
      return (
        <TreeNode
          label={
            <div className="w-full"
              key={index}
              >
              <div className=" w-fit mx-auto min-w-fit max-w-full w-30%">
                <div className="w-full flex justify-center">
                  <DownArrowSvg />
                </div>
                <div className="w-fit   relative">
                  {isEditable === true ? (
                    <div className="absolute -right-2 -top-2 z-10">
                      <MinusSvg handleClick={() => deleteNode(item)} />
                    </div>
                  ) : (
                    <></>
                  )}
                  <div>
                    <DialogTooltip data={datas} keys={item}>
                      <div className="p-4 w-fit mx-auto min-w-fit max-w-full w-30%  bg-primaryblack text-white rounded-lg z-0 opacity-90">
                        {key}
                      </div>
                    </DialogTooltip>
                  </div>
                </div>
                {isEditable === true ? (
                  <div className="w-full flex justify-center my-1 ">
                    <div
                      className="cursor-pointer"
                      onClick={() => onClickNodes(item)}
                    >
                      <PlusSvg />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          }
        >
          {item?.children && <NestedList data={item?.children} />}
        </TreeNode>
      );
    });
  };

  const addChildObject = (id, object) => {
    const findChild = (node) => {
      if (node.id === id) {
        if (node.children) node.children.push(object);
        else node.children = [object];
        return true;
      }
      if (node.children) {
        return node.children.some(findChild);
      }
      return false;
    };
    setData((prevData) => {
      const newData = { ...prevData };
      findChild(newData);
      return newData;
    });
  };

  const [NodeText, setNodeText] = useState("");
  const HandleNodeText = (e) => {
    setNodeText(e?.target?.value);
  };

  const UpdateNodeText = (keyid) => {
    updateNode(keyid, NodeText);
  };

  const datas = (key) => {
    return (
      <>
        <div className=" flex gap-3 items-center">
          <div>
            <Input
              type="text"
              placeholder={key?.key}
              value={NodeText}
              onChange={HandleNodeText}
              labeltext="Name the Tile"
            />
          </div>
          <div onClick={() => UpdateNodeText(key?.id)}>
            <Button
              type="button"
              className="border bg-primaryblack text-white h-fit mt-6"
            >
              Save
            </Button>
          </div>
        </div>
      </>
    );
  };

  return {
    datas,
    data,
    onClickNodes,
    NestedList,
    isEditable,
    setisEditable,
  };
};

const AddremoveTemplates = () => {
  const [tags, setTags] = useState(["Template 1", "Template 2", "Template 3"]);
  const [inputTile, setinputTile] = useState(() => tags?.length === 0);
  const [isEditable, setisEditable] = useState(true);
  const [input, setInput] = useState("");
  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };
  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();
    if (
      key === "Enter" &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
      setinputTile(false);
    }
  };

  const deleteTag = (index) => {
    if (tags?.length === 1) setinputTile(true);
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };
  const Tile = (tag, index) => {
    return (
      <div className="relative  border bg-[#F4F4F4]  mr-2.5 mx-0 my-[7px] pr-[5px] px-2.5 py-0 rounded-[5px] border-solid  max-w-lg min-h-[80px] min-w-[160px]  break-all">
        <div className=" flex items-center w-full h-full justify-center">
          {tag}
        </div>
        {isEditable === true ? (
          <button
            onClick={() => deleteTag(index)}
            className="absolute -top-3 -right-3 cursor-pointer p-1.5 "
          >
            <MinusSvg />
          </button>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const EmptyTile = () => {
    return (
      <div className="flex items-center justify-end gap-2 text-center mr-2.5 mx-0 my-[7px] border bg-[#F4F4F4] max-w-[160px] min-h-[80px] rounded">
        <Input
          className="w-5/6 bg-white text-center border rounded"
          value={input}
          placeholder="New Tile 1"
          onKeyDown={onKeyDown}
          onChange={onChange}
        />
      </div>
    );
  };

  const MappedTiles = (params) => {
    const data = tags.map((tag, index) => Tile(tag, index));
    return data;
  };

  return {
    MappedTiles,
    EmptyTile,
    inputTile,
    setinputTile,
    isEditable,
    setisEditable,
  };
};

const AddremoveDesignations = () => {
  const [tags, setTags] = useState(["Principal", "Vice Principal", "HOD"]);
  const [inputTile, setinputTile] = useState(() => tags?.length === 0);

  const [input, setInput] = useState("");
  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };
  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();
    if (
      key === "Enter" &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
      setinputTile(false);
    }
  };

  const deleteTag = (index) => {
    if (tags?.length === 1) setinputTile(true);
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };
  const Tile = (tag, index) => {
    return (
      <div
        className="relative flex items-center  bg-primaryblack text-white
          mr-2.5 mx-0 my-[7px] pr-[5px] px-2.5 py-0 rounded-[5px] border-solid  min-h-[40px]  break-all"
      >
        <div>
          <Input className="w-fit text-white" type="checkbox" labeltext={tag} />
        </div>
        {
          <div onClick={() => deleteTag(index)} className="ml-2 pr-4 ">
            <Crosssvg />
          </div>
        }
      </div>
    );
  };

  const EmptyTile = () => {
    return (
      <div className="flex items-center justify-end gap-2 text-center mr-2.5 mx-0 my-[7px] border bg-primaryblack  rounded p-2">
        <Input
          className="w-5/6 bg-white text-center border rounded h-full"
          value={input}
          placeholder="New Tile 1"
          onKeyDown={onKeyDown}
          onChange={onChange}
        />
      </div>
    );
  };

  const MappedTiles = (params) => {
    const data = tags.map((tag, index) => Tile(tag, index));
    return data;
  };

  return {
    MappedTiles,
    EmptyTile,
    inputTile,
    setinputTile,
  };
};

export default OnboardingPresenter;
