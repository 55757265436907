const Apis = {
  LOGIN: 'user/login',
  SIGNUP: "user/signup",
  RESETPASSWORD:"reset/password",
  LOGOUT:"user/logout",
  GENERATEOTP:"generate/otp",
  VERIFYOTP:"verify/otp",
  ONBOARDING_FETCH: "onboarding/fetch",
  ONBOARDING_GETPROFILE: "getOrgProfile",
  ONBOARDING_UPDATEPROFILE: "updateOrgProfile",
  ONBOARDING_GET_TEMPLATE: "getTemplates",
  ONBOARDING_ADD_TEMPLATE: "addTemplate",
  ONBOARDING_UPDATE_TEMPLATE: "updateTemplate",  
  ONBOARDING_TEMPLATE_GET_HIERARCHYNODE: "getHierarchyNode",
  ONBOARDING_TEMPLATE_ADD_UPDATE_HIERARCHYNODE: "addOrUpdateHierarchyNode",
  ONBOARDING_GET_SETTINGS_TEMPLATE: "getSettingsTemplate",
  ONBOARDING_GET_SETTINGS: "getSettings",
  ONBOARDING_UPDATE_SETTINGS: "addSettings",
  GET_EXCEPTIONAL_DAYS: "getExceptionalDays",
  GET_ATTENDANCE_POLICY_IDS_BY_TYPE: "getAttendancePolicyIdByOrgId",
  ADD_OR_UPDATE_EXCEPTIONAL_DAY: "addOrUpdateExceptionalDays",
  UPDATE_ATTENDANCE_POLICY: "updateAttendancePolicy",
  ADD_OR_UPDATE_GEO_LOCATION: "addOrUpdateGeoLocation",
  GET_GEO_LOCATION_BY_TYPE: "getGeoLocationByType",
  ADD_OR_UPDATE_WORKING_DAYS: "addOrUpdateWorkingDays",
  GET_WORKING_DAYS: "getWorkingDays",
  CHECK_USER: "checkUser",
  ADD_OR_UPDATE_BREAK_TIMINGS: "addOrUpdateBreakTime",
  GET_BREAK_TIMINGS: "getBreakTime",
  ADD_OR_UPDATE_ATTENDANCE_POLICY_SETTINGS: "addOrUpdateAttendancePolicySettings",
  GET_ATTENDANCE_POLICY_SETTINGS: "getAttendancePolicySettings"
}

const ApiNames = Object.freeze(Apis);

export default ApiNames;
