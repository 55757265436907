import React, { useState } from 'react'
import { PlusSvg } from '../../assest/svgs'

const AddRemoveCard = (props) => {

    const {
        MappedTiles,
        inputTile,
        setinputTile,
        EmptyTile,
        isEditable
    } = props
    
    return (
        <div>
            <div className=" flex item-center w-full flex-wrap  text-[black] ">
                {MappedTiles()}
                {(inputTile) && EmptyTile()}
                {/* {isEditable===true? */}
                <div className=' flex items-center  ' onClick={() => { setinputTile(true) }}>
                    <PlusSvg />
                </div>
                {/* // :<></> */}
            </div>
        </div>
    )
}

export default AddRemoveCard
