import React from "react";

import { RolesAndPrivilegesPresenter } from "./RolesAndPrivilegesPresenter";
import Tabs from "../../../layout/LayoutTabs";
import Card from "../../../../elements/Cards";

const RolesAndPrivilegesTab = () => {
  const { tabMenu, selectedTab, handleTab } = RolesAndPrivilegesPresenter();
  return (
    <Card className="p-4 bg-white">
      <Tabs tabMenu={tabMenu} handleTab={handleTab} />
      {selectedTab.component}
    </Card>
  );
};

export default RolesAndPrivilegesTab;
