import React, { useState, useEffect } from "react";

import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  flexRender,
} from "@tanstack/react-table";
import Pagination from "./Pagination";
import SearchForTable from "./tableSearch";

const Table = (props) => {
  const { data, columns: col, pagination: paginate ,search:inputSearch} = props;

  const [cols, setcols] = useState(() => (col === undefined ? [] : [...col]));
  const [globalFilter, setGlobalFilter] = useState("");
  const [tabledata, setTableData] = useState(() => [...data]);
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    if (cols?.length === 0) {
      const tempcol =
        data &&
        Object.keys(data[0])
          .map((i, idx) => {
            if (i === "subRows") return;
            if (typeof data[0][i] !== "object")
              return {
                header: i,
                accessorKey: i,
              };
            return {
              header: i,
              accessorKey: i,
              cell: (props) => {
                return <div>{props.getValue()}</div>;
              },
            };
          })
          .filter((i) => i != undefined);
      setcols(tempcol);
    }
  }, []);

  const table = useReactTable({
    data: tabledata,
    columns: cols,
    state: {
      expanded,
      globalFilter
    },
    onExpandedChange: setExpanded,
    onGlobalFilterChange: setGlobalFilter,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });


  useEffect(() => {
    if (props?.fulldata === true)
      table?.setPageSize(Number(data?.length + 1000));
  }, [props?.fulldata]);

  return (
    <div className="p-2">
      <div className="h-2" />
      {inputSearch && (<div>
       <SearchForTable globalFilter={globalFilter} setGlobalFilter={setGlobalFilter}/>
      </div>)}
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      width:
                        header.getSize() !== 0 ? header.getSize() : undefined,
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                className={row
                  .getVisibleCells()
                  .map((cell) =>
                    cell?.column?.columnDef?.isborder
                      ? "border-b-[.1px] border-[#ffffff]"
                      : ""
                  )}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      style={{
                        width:
                          cell.column.getSize() !== 0
                            ? cell.column.getSize()
                            : undefined,
                      }}
                      className={
                        cell?.column?.columnDef?.isborder &&
                        "border-r-[0.5px] border-[#CBCBCB]"
                      }
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="h-2" />
      {paginate  && <Pagination table={table} />}
      <div>{table.getRowModel().rows.length} Rows</div>
    </div>
  );
};

export default Table;
