import React, { useEffect, useState } from "react";
import { Datepicker, Timepicker, Input, initTE } from "tw-elements";
let tmStartedWithDate12h
const Clock = (props) => {
  
  useEffect(() => {
    initTE({ Datepicker, Timepicker, Input });
    const pickerStartedWithDate12h = document.getElementById(
      props?.id
    );
    tmStartedWithDate12h = new Timepicker(pickerStartedWithDate12h, {
      //   defaultTime: new Date(),

    });

    pickerStartedWithDate12h.addEventListener("input", props.onChange);

    return () => {
      pickerStartedWithDate12h.removeEventListener("input", props.onChange);
    };

  }, []);

  return (
    <div
    className="relative w-fit my-1 py-1"
    id={props?.id}
    data-te-input-wrapper-init>
      <input
        type="text"
        className="peer text-xs  block min-h-[auto]  w-28 border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-0 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
        id="form11"
        data-te-toggle="timepicker"
        {...props}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  )
}

export default Clock