import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { useRoutes } from "react-router-dom";
import { routes } from "./routes";
import * as Sentry from "@sentry/react"
import { useSelector } from 'react-redux';

function App() {
  
  const dynamicImages = useSelector((state) => state.MainReducer.dynamicImages);

  let element = useRoutes(routes);

  
  useEffect(() => {
    if (dynamicImages && dynamicImages?.logo) {
      const linkElement = document.querySelector('link[rel=icon]');
      linkElement.href = dynamicImages?.logo;
    }
  }, [dynamicImages])
  
  
  return <>
    {element}
  </>;
}



export default Sentry.withProfiler(App);
