import * as actionTypes from '../Actions/types';
const backdrops = sessionStorage.getItem("backdrops");

const INITIAL = {
    dynamicImages: backdrops ? JSON.parse(backdrops) : {},
    onboardingSteps: {
        "profile": {
            "name": "Organization Profile",
            "stepId": null,
            "status": null,
            "updatedAt": ""
        },
        "structure": {
            "name": "Organization Structure",
            "stepId": null,
            "status": null,
            "updatedAt": ""
        },
        "attendancyPolicy": {
            "name": "Attendance Policy",
            "stepId": null,
            "status": null,
            "updatedAt": ""
        },
        "settings": {
            "name": "Settings",
            "stepId": null,
            "status": null,
            "updatedAt": ""
        },
        "rolesAndPrivileges": {
            "name": "Roles and Privileges",
            "stepId": null,
            "status": null,
            "updatedAt": ""
        },
        "rolePolicy": {
            "name": "Role Policy",
            "stepId": null,
            "status": null,
            "updatedAt": ""
        },
        "userCreation": {
            "name": "User Creation",
            "stepId": null,
            "status": null,
            "updatedAt": ""
        },
        "totalSteps": 7,
        "completedSteps": 0,
        "pendingSteps": 7,
        "percentage": 0
    }
}

const MainReducer = (state = INITIAL, action) => {
    switch (action.type) {
        case actionTypes.DYNAMIC_IMAGE_LOGIN:
            return { ...state, dynamicImages: action.payload }

        case actionTypes.ONBAORDING_STEPS:
            return { ...state, onboardingSteps: action.payload }

        default:
            return state;
    }
}

export default MainReducer;