import { useState } from "react";
import Card from "../../../elements/Cards";

import SubjectClassMapping from "./index";
import SubjectMaster from "./SubjectMaster";
import ClassMapping from "./ClassMapping";

export default function SubjectClassMappingPresenter() {
  const tabmenu = [
    {
      name: "Subject Master",
      component: (
          <SubjectMaster></SubjectMaster>
      ),
      selected: true,
    },
    { 
      name: "Class Mapping",
      component: <ClassMapping></ClassMapping>,
      selected: false,
    },

  ];

  const [tabMenu, setabMenu] = useState(tabmenu);
  const [selectedTab, setselectedTab] = useState(tabmenu[0]);

  const handleTabTBN = (params) => {
    const temp = [...tabmenu].map((i, idx) => {
      if (i?.name === params?.name) i.selected = true;
      else i.selected = false;
      return i;
    });
    setabMenu([...temp]);
    setselectedTab(params);
  };

  return { tabMenu, selectedTab, handleTabTBN };
}
