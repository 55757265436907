// import React from "react";

// const Pagination = (props) => {
//   const { table } = props;

 
//   return (
//     <div>
//       <div className="flex items-center gap-2">
//         <button
//           className="border rounded p-1"
//           onClick={() => table.previousPage()}
//           disabled={!table.getCanPreviousPage()}
//         >
//           {"<"}
//         </button>
//         <button
//           className="border rounded p-1"
//           onClick={() => table.nextPage()}
//           disabled={!table.getCanNextPage()}
//         >
//           {">"}
//         </button>

//         <span className="flex items-center gap-1">
//           <div>Page</div>
//           <strong>
//             {table.getState().pagination.pageIndex + 1} of{" "}
//             {table.getPageCount()}
//           </strong>
//         </span>
//         <span className="flex items-center gap-1">
//           | Go to page:
//           <input
//             type="number"
//             defaultValue={table.getState().pagination.pageIndex + 1}
//             onChange={(e) => {
//               const page = e.target.value ? Number(e.target.value) - 1 : 0;
//               table.setPageIndex(page);
//             }}
//             className="border p-1 rounded w-16"
//           />
//         </span>
//         <select
//           value={table.getState().pagination.pageSize}
//           onChange={(e) => {
//             table.setPageSize(Number(e.target.value));
//           }}
//         >
//           {[10, 20, 30, 40, 50].map((pageSize) => (
//             <option key={pageSize} value={pageSize}>
//               Show {pageSize}
//             </option>
//           ))}
//         </select>

//         <div>
//           {Array(table.getPageCount())
//             .fill(5)
//             .map((item, ndex) => {
//               return <h1>hi</h1>
//             })}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Pagination;


// Sandy - working logic

// ------

// import React from "react";

// const Pagination = (props) => {
//   const { table } = props;
//   const pageIndex = table.getState().pagination.pageIndex;
//   const pageCount = table.getPageCount();

//   const getVisiblePageNumbers = () => {
//     const currentPage = pageIndex + 1;
//     const visiblePages = [];

//     // Previous three page numbers
//     for (let i = currentPage - 3; i < currentPage; i++) {
//       if (i > 0) {
//         visiblePages.push(i);
//       }
//     }

//     // Current page number
//     visiblePages.push(currentPage);

//     // Next three page numbers
//     for (let i = currentPage + 1; i <= currentPage + 3; i++) {
//       if (i <= pageCount) {
//         visiblePages.push(i);
//       }
//     }

//     return visiblePages;
//   };

//   return (
//     <div>
//       <div className="flex items-center gap-2">
//         <button
//           className="border rounded p-1"
//           onClick={() => table.previousPage()}
//           disabled={!table.getCanPreviousPage()}
//         >
//           {"<"}
//         </button>
//         <button
//           className="border rounded p-1"
//           onClick={() => table.nextPage()}
//           disabled={!table.getCanNextPage()}
//         >
//           {">"}
//         </button>

//         <span className="flex items-center gap-1">
//           <div>Page</div>
//           <strong>
//             {pageIndex + 1} of {pageCount}
//           </strong>
//         </span>
//         <span className="flex items-center gap-1">
//           | Go to page:
//           <input
//             type="number"
//             defaultValue={pageIndex + 1}
//             onChange={(e) => {
//               const page = e.target.value ? Number(e.target.value) - 1 : 0;
//               table.setPageIndex(page);
//             }}
//             className="border p-1 rounded w-16"
//           />
//         </span>
//         <select
//           value={table.getState().pagination.pageSize}
//           onChange={(e) => {
//             table.setPageSize(Number(e.target.value));
//           }}
//         >
//           {[10, 20, 30, 40, 50].map((pageSize) => (
//             <option key={pageSize} value={pageSize}>
//               Show {pageSize}
//             </option>
//           ))}
//         </select>

//         <div>
//           {getVisiblePageNumbers().map((pageNumber) => (
//             <button
//               key={pageNumber}
//               className={`border rounded p-1 ${
//                 pageNumber === pageIndex + 1 ? "bg-blue-500 text-white" : ""
//               }`}
//               onClick={() => table.setPageIndex(pageNumber - 1)}
//             >
//               {pageNumber}
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Pagination;

//----
//

// import React from "react";

// const Pagination = (props) => {
//   const { table } = props;
  
//   const getPageNumbers = () => {
//     const currentPageIndex = table.getState().pagination.pageIndex;
//     const pageCount = table.getPageCount();
//     const minPages = 6;

//     // If the total number of pages is less than or equal to the minimum number of pages to display,
//     // return an array of all page numbers
//     if (pageCount <= minPages) {
//       return Array(pageCount).fill().map((_, index) => index + 1);
//     }

//     const pageNumbers = [];

//     // Always include the first page
//     pageNumbers.push(1);

//     if (currentPageIndex <= 3) {
//       // Display sequential page numbers if current page index is less than or equal to 3
//       for (let i = 2; i <= Math.min(pageCount - 1, minPages - 2); i++) {
//         pageNumbers.push(i);
//       }
//       if (pageCount > minPages) {
//         pageNumbers.push("...");
//       }
//       pageNumbers.push(pageCount);
//     } else if (currentPageIndex >= pageCount - 3) {
//       // Display sequential page numbers if current page index is greater than or equal to the last 3 pages
//       pageNumbers.push(1);
//       if (currentPageIndex > pageCount - minPages + 2) {
//         pageNumbers.push("...");
//       }
//       for (let i = pageCount - minPages + 3; i <= pageCount; i++) {
//         pageNumbers.push(i);
//       }
//     } else {
//       // Display ellipsis (...) for the middle pages
//       pageNumbers.push("...");
//       for (let i = currentPageIndex - 1; i <= currentPageIndex + 1; i++) {
//         pageNumbers.push(i);
//       }
//       pageNumbers.push("...");
//       pageNumbers.push(pageCount);
//     }

//     return pageNumbers;
//   };
  
//   // Rest of the code remains the same...

//   return (
//     <div>
//       <div className="flex items-center gap-2">
//         <button
//           className="border rounded p-1"
//           onClick={() => table.previousPage()}
//           disabled={!table.getCanPreviousPage()}
//         >
//           {"<"}
//         </button>
//         <button
//           className="border rounded p-1"
//           onClick={() => table.nextPage()}
//           disabled={!table.getCanNextPage()}
//         >
//           {">"}
//         </button>

//         <span className="flex items-center gap-1">
//           <div>Page</div>
//           <strong>
//             {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
//           </strong>
//         </span>
//         <span className="flex items-center gap-1">
//           | Go to page:
//           <input
//             type="number"
//             defaultValue={table.getState().pagination.pageIndex + 1}
//             onChange={(e) => {
//               const page = e.target.value ? Number(e.target.value) - 1 : 0;
//               table.setPageIndex(page);
//             }}
//             className="border p-1 rounded w-16"
//           />
//         </span>
//         <select
//           value={table.getState().pagination.pageSize}
//           onChange={(e) => {
//             table.setPageSize(Number(e.target.value));
//           }}
//         >
//           {[10, 20, 30, 40, 50].map((pageSize) => (
//             <option key={pageSize} value={pageSize}>
//               Show {pageSize}
//             </option>
//           ))}
//         </select>

//         <div>
//           {getPageNumbers().map((pageNumber, index) => (
//             <span key={index}>
//               {pageNumber === "..." ? (
//                 <span className="px-2">...</span>
//               ) : (
//                 <button
//                   className={`border rounded p-1 ${
//                     pageNumber === table.getState().pagination.pageIndex + 1 ? "bg-blue-500 text-white" : ""
//                   }`}
//                   onClick={() => table.setPageIndex(pageNumber - 1)}
//                 >
//                   {pageNumber}
//                 </button>
//               )}
//             </span>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Pagination;


// ----

import React from "react";
import { BackArrow, BackArrowSvg, BackwordArrowsvg, ForwardArrowsvg, NextArrowSVG, NextArrowSvg, TableBackArrowSVG } from "../../assest/svgs";

const Pagination = (props) => {
  const { table } = props;

  const currentPage = table.getState().pagination.pageIndex + 1;
  const pageCount = table.getPageCount();

  const renderPageNumbers = () => {
    const pageNumbers = [];

    // Calculate the range of page numbers to display
    let startPage = Math.max(1, currentPage - 3);
    let endPage = Math.min(pageCount, currentPage + 3);

    // Adjust startPage and endPage if there are fewer than 6 pages
    if (endPage - startPage < 6) {
      if (currentPage <= 3) {
        endPage = Math.min(startPage + 5, pageCount);
      } else {
        startPage = Math.max(endPage - 5, 1);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers.map((pageNumber) => (
      <button
        key={pageNumber}
        className={`border rounded p-1 w-8  ${pageNumber === currentPage ? "bg-black text-white" : ""}`}
        onClick={() => {
          table.setPageIndex(pageNumber - 1);
        }}
      >
        {pageNumber}
      </button>
    ));
  };

  return (
    <div>
      <div className="flex items-center gap-2">
        <button
          className="flex items-center gap-2 p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <BackArrowSvg/>
          {"Previous"}
        </button>
        {renderPageNumbers()}
        <button
          className="flex items-center gap-2"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}

        >
          {"Next"}
          <NextArrowSVG/>
        </button>

        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {currentPage} of {pageCount}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Go to page:
          
          <input
            type="number"
            defaultValue={currentPage}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className="border p-1 rounded w-16"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>         

    </div>
  );
};

export default Pagination;



