import Title from "../../../elements/Title";
import TitleSwitchParagraph from "../../TitleSwitchParagraph";
import Select from "../../../elements/Input/Select";
import { PlusSvg } from "../../../assest/svgs";
import Card from "../../../elements/Cards";
import Input from "../../../elements/Input";
import Button from "../../../elements/Button";
import Map from "../../Map";

const AttendancePolicy = () => {

  const laat = "17.385044";
  const loong = "78.486671";
  return (
    <div>
      <Card>

        <Title>Work Schedule</Title>
        <Title className="text-sm py-2">Working Days</Title>
        <div className="text-xs">
          Select the working days of your organization. You can configure the
          timings to each day or for all.
        </div>
        <div className="w-1/2 text-center">
          <div className=" py-2 grid grid-cols-7 border-gray-300  text-sm w-full">
            <div className="border border-gray-300 bg-zinc-500 rounded-s-md p-3 text-white">
              Mon
            </div>
            <div className="border border-gray-300  p-3 text-black">Tue</div>
            <div className="border border-gray-300  p-3 text-black">Wed</div>
            <div className="border border-gray-300  p-3 text-black">Thu</div>
            <div className="border border-gray-300 bg-zinc-500 p-3 text-white">
              Fri
            </div>
            <div className="border border-gray-300 bg-zinc-500 p-3 text-white">
              Sat
            </div>
            <div className="border border-gray-300  rounded-e-md p-3 text-black">
              Sun
            </div>
          </div>
        </div>
        <div>
          <div className="w-3/4 mt-3 p-2">
            <div className="flex items-center gap-2">
              <div className="w-10">Mon</div>
              <Input type="time" placeholder="HH:MM"></Input>
              <span>to</span>
              <Input type="time" placeholder="HH:MM"></Input>
            </div>
          </div>
          <div className="w-3/4 mt-3 p-2">
            <div className="flex items-center gap-2">
              <div className="w-10">Fri</div>
              <Input type="time" placeholder="HH:MM"></Input>
              <span>to</span>
              <Input type="time" placeholder="HH:MM"></Input>
            </div>
          </div>
          <div className="w-3/4 mt-3 p-2">
            <div className="flex items-center gap-2">
              <div className="w-10">Sat</div>
              <Input type="time" placeholder="HH:MM"></Input>
              <span>to</span>
              <Input type="time" placeholder="HH:MM"></Input>
            </div>
          </div>
        </div>
        <TitleSwitchParagraph className="w-1/4" text={"Exceptional Days"} subtext={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, reiciendis fugit qui consequuntur reprehenderit quaerat culpa nam          corrupti error quisquam sed, labore necessitatibus nostrum, eligendi   repellendus facere inventore atque eaque. Deserunt dolorum dolores a aliquam, fugit quis exercitationem aspernatur natus quod sed dolor  laboriosam ipsum quae accusantium, nulla est? Animi?"} toggle />

        <Title className="text-sm">Exceptional 1</Title>

        <div className=" w-3/4 grid grid-cols-3 gap-2 pt-2">
          <div>
            <div className="text-xs">Exception Type</div>
            <Select className="mt-0" defaultOption="Select type" />
          </div>
          <div>
            <div className="text-xs">Exception Type</div>
            <Select className="mt-0" defaultOption="Month From" />
          </div>
          <div>
            <div className="text-xs">Exception Type</div>
            <Select className="mt-0" defaultOption="Month to" />
          </div>
        </div>

        <div className="flex gap-2 pt-3">
          <div>
            <div className="text-xs">
              Day
            </div>
            <div>
              <Select className="mt-0 w-[360px]" defaultOption="Select type" />
            </div>
          </div>
          <div>
            <div className="text-xs">
              Week
            </div>
            <div>
              <div className=" text-center">
                <div className=" py-2 grid grid-cols-5 border-gray-300 text-sm w-full">
                  <div className="border border-gray-300 bg-zinc-500 rounded-s-md p-3 text-white w-24">
                    Mon
                  </div>
                  <div className="border border-gray-300  p-3 text-black">Tue</div>
                  <div className="border border-gray-300  p-3 text-black">Wed</div>
                  <div className="border border-gray-300  p-3 text-black">Thu</div>
                  <div className="border border-gray-300 bg-zinc-500 p-3 rounded-e-md text-white">
                    Fri
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="text-xs">
              Time
            </div>
            <div>
              <div className="w-3/4 py-2">
                <div className="flex items-center gap-2">
                  <Input type="time" placeholder="HH:MM"></Input>
                  <span>to</span>
                  <Input type="time" placeholder="HH:MM"></Input>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div className="flex">
          <PlusSvg />
          <div className="text-xs px-2">Add Exceptional Day</div>
        </div>

        <TitleSwitchParagraph className="w-1/4" text={"Break Time"} subtext={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, reiciendis fugit qui consequuntur reprehenderit quaerat culpa nam          corrupti error quisquam sed, labore necessitatibus nostrum, eligendi   repellendus facere inventore atque eaque. Deserunt dolorum dolores a aliquam, fugit quis exercitationem aspernatur natus quod sed dolor  laboriosam ipsum quae accusantium, nulla est? Animi?"} toggle />

        <div className="flex">
          <Button className="bg-zinc-500">Fixed</Button>
          <Button className="text-black border-slate-700">Flexible</Button>
        </div>
        <div className="w-3/4 mt-3">
          <div className="flex items-center gap-2">
            <Input type="time" placeholder="HH:MM"></Input>
            <span>to</span>
            <Input type="time" placeholder="HH:MM"></Input>
          </div>
        </div>

        <div className="flex pt-3">
          <PlusSvg />
          <div className="text-xs px-2">Add Fixed Time</div>
        </div>

        <TitleSwitchParagraph className="w-1/4" text={"No of Hours Present"} subtext={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, reiciendis fugit qui consequuntur reprehenderit quaerat culpa nam          corrupti error quisquam sed, labore necessitatibus nostrum, eligendi   repellendus facere inventore atque eaque. Deserunt dolorum dolores a aliquam, fugit quis exercitationem aspernatur natus quod sed dolor  laboriosam ipsum quae accusantium, nulla est? Animi?"} toggle />

        <div className="w-3/4">
          <Input className="w-1/12" type="time" placeholder="HH:MM"></Input>
        </div>

        <TitleSwitchParagraph className="w-1/4" text={"No of Hours for Half Day Present"} subtext={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, reiciendis fugit qui consequuntur reprehenderit quaerat culpa nam          corrupti error quisquam sed, labore necessitatibus nostrum, eligendi   repellendus facere inventore atque eaque. Deserunt dolorum dolores a aliquam, fugit quis exercitationem aspernatur natus quod sed dolor  laboriosam ipsum quae accusantium, nulla est? Animi?"} toggle />

        <div className="w-3/4">
          <Input className="w-1/12" type="time" placeholder="HH:MM"></Input>
        </div>

        <TitleSwitchParagraph className="w-1/4" text={"No of Periods Per Day"} subtext={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, reiciendis fugit qui consequuntur reprehenderit quaerat culpa nam          corrupti error quisquam sed, labore necessitatibus nostrum, eligendi   repellendus facere inventore atque eaque. Deserunt dolorum dolores a aliquam, fugit quis exercitationem aspernatur natus quod sed dolor  laboriosam ipsum quae accusantium, nulla est? Animi?"} toggle />

        <div className="w-3/4">
          <div className="flex items-center">

            <Input className="w-10" type="number"></Input>
            <div className="text-xs text-center p-2">per day</div>
          </div>
        </div>

        <div className="w-3/4 mt-2">
          <div className="flex items-center">
            <Input className="w-1/12" type="time" placeholder="HH:MM"></Input>
            <div className="text-xs text-center p-2">Duration of each period</div>
          </div>
        </div>

        <TitleSwitchParagraph className="w-1/4" text={"Minimum Attendance Percentage(%)"} subtext={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, reiciendis fugit qui consequuntur reprehenderit quaerat culpa nam          corrupti error quisquam sed, labore necessitatibus nostrum, eligendi   repellendus facere inventore atque eaque. Deserunt dolorum dolores a aliquam, fugit quis exercitationem aspernatur natus quod sed dolor  laboriosam ipsum quae accusantium, nulla est? Animi?"} toggle />

        <div>
          <div className="text-xs">
            Time Period
          </div>
          <div className="flex">
            <div className="w-1/4">
              <Select className="mt-0" defaultOption="Select type" />
            </div>
            <div>
              <div className="w-3/4">
                <div className="flex items-center">

                  <div className=" flex items-center">

                    <Input className="w-1/3 p-2" type="number"></Input>
                    <div className=" w-1/3 text-xs text-center">percentage(%)</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>

      <Card className="border border-gray-300 shadow-md">
        <Title>Geo Location anupam</Title>
        <TitleSwitchParagraph className="w-1/4" text={"Geo Fencing"} subtext={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, reiciendis fugit qui consequuntur reprehenderit quaerat culpa nam corrupti error quisquam sed"} toggle />

        <div className="grid grid-cols-3 gap-4 w-8/12">
          <div className="col-span-1">
            <div className="text-xs">Longitude</div>
            <Input className="py-2" type={"text"}></Input>
          </div>
          <div className="col-span-1">
            <div className="text-xs">Lattitude</div>
            <Input className="py-2" type={"text"}></Input>
          </div>
          <div className="col-span-1">
            <div className="text-xs">Location Restriction</div>
            <div className="flex border border-gray-300 h-9 rounded-md mt-2">
              <div className="w-10/12"></div>
              <div className="w-1/3 bg-gray-200 text-sm  text-center flex justify-center items-center">meters</div>
            </div>
          </div>
        </div>

        <div className=" my-4">
          {/* <SkeletonCard className="w-full h-60"/> */}
          <Map zoom={15} center={{ lat: parseFloat(laat), lng: parseFloat(loong) }} />
        </div>

        <TitleSwitchParagraph className="w-1/4" text={"Multi Geo Coordinates"} subtext={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, reiciendis fugit qui consequuntur reprehenderit quaerat culpa nam          corrupti error quisquam sed"} toggle />

        <div className="grid grid-cols-3 gap-4 w-8/12">
          <div className="col-span-1">
            <div className="text-xs">Longitude</div>
            <Input className="py-2" type={"text"}></Input>
          </div>
          <div className="col-span-1">
            <div className="text-xs">Lattitude</div>
            <Input className="py-2" type={"text"}></Input>
          </div>
          <div className="col-span-1">
            <div className="text-xs">Location Restriction</div>
            <div className="flex border border-gray-300 h-9 rounded-md mt-2">
              <div className="w-10/12"></div>
              <div className="w-1/3 bg-gray-200 text-sm  text-center flex justify-center items-center">meters</div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 w-8/12">
          <div className="col-span-1">
            <div className="text-xs">Longitude</div>
            <Input className="py-2" type={"text"}></Input>
          </div>
          <div className="col-span-1">
            <div className="text-xs">Lattitude</div>
            <Input className="py-2" type={"text"}></Input>
          </div>
          <div className="col-span-1">
            <div className="text-xs">Location Restriction</div>
            <div className="flex border border-gray-300 h-9 rounded-md mt-2">
              <div className="w-10/12"></div>
              <div className="w-1/3 bg-gray-200 text-sm  text-center flex justify-center items-center">meters</div>
            </div>
          </div>
        </div>
        <div className="flex">
          <PlusSvg />
          <div className="text-xs px-2">Add Multi Geo</div>
        </div>

      </Card>

      <Card className="border border-gray-300 shadow-md mt-4">
        <Title>Shift Management</Title>

        <TitleSwitchParagraph className="w-1/4" text={"Shift Mapping"} subtext={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, reiciendis fugit qui consequuntur reprehenderit quaerat culpa nam          corrupti error quisquam sed, labore necessitatibus nostrum, eligendi   repellendus facere inventore atque eaque. Deserunt dolorum dolores a aliquam, fugit quis exercitationem aspernatur natus quod sed dolor  laboriosam ipsum quae accusantium, nulla est? Animi?"} toggle />

        <div className="text-xs text-blue-600 underline">Click here to go to Shift Mapping Page</div>

      </Card>


    </div>

  );
};

export default AttendancePolicy;
