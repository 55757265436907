import React from "react";
import Card from "../../elements/Cards";
import Title from "../../elements/Title";
import SubTitle from "../../elements/SubTitle";
import Button from "../../elements/Button";
import {
  BackArrow,
  PlusSvg,
} from "../../assest/svgs";
import InputSearch from "../../component/InputSearch";
import Modal from "../../component/modal";
import UserManagementPresenter from "./user-management-presenter";
import Input from "../../elements/Input";
import TitleSwitchParagraph from "../../component/TitleSwitchParagraph";
import Select from "../../elements/Input/Select";
import Tables from "../../component/Table";

const TransferPromotion = () => {
  const { handleModal, showModal, setshowModal, handleSubmit, showPromotionDiv, setshowPromotionDiv, renderSubComponent, transferPromotion,transferpromotionColumns } =
    UserManagementPresenter();
  return (
    <>
      <Modal
        type="rightside"
        showModal={showModal}
        setShowModal={setshowModal}
        handleModal={handleModal}
        handleSubmit={handleSubmit}
      >
        <>
          <div className="h-full my-5">
            <form className="mt-1">
              <Title className="text-lg font-semibold not-italic mb-1">
                Create Transfer/Promotion
              </Title>
              <SubTitle>
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing.
              </SubTitle>
              <div className="grid grid-cols-1 md:grid-cols-1 gap-y-1w-2/3">
                <div className="mt-3">
                  <div className="mb-4">
                    <SubTitle className="font-medium text-[#555555]">Plese Select</SubTitle>

                    <div className="grid grid-cols-1 mb-2 md:grid-cols-4 gap-x-12">
                      <Input type="radio" labeltext="Transfer"></Input>
                      <Input type="radio" labeltext="Promotion"></Input>
                    </div>
                  </div>

                  <div className="mt-4">
                  <SubTitle className="font-medium text-[#555555]">Plese Select</SubTitle>
                    <div className="grid grid-cols-1 mb-2 md:grid-cols-4 gap-x-16">
                      <Input type="radio" labeltext="Student"></Input>
                      <Input type="radio" labeltext="staff"></Input>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4">
              <SubTitle className="font-medium text-[#555555]">Transfer From</SubTitle>

                <Select className="w-2/3 mt-0" defaultOption="Select option" />
              </div>

              <div className="mt-4">
              <SubTitle className="font-medium text-[#555555]">Transfer To</SubTitle>

                <Select className="w-2/3 mt-0" defaultOption="Select option" />
              </div>

              <div className="flex flex-col md:flex-row justify-end  gap-3 mt-5">
                <Button className="flex border max-h-[40px] max-w-fit" onClick={handleSubmit}>
                  Submit & Add New
                </Button>
                <Button
                  type="button"
                  className="flex max-h-[40px] items-center bg-primaryblack text-white max-w-fit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </>
      </Modal>

      <Card className="p-4 mt-4">
        <div className="flex justify-between">
          <div>
            <Title className="">Transfer/Promotion</Title>
            <SubTitle className="w-4/5">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Delectus
              quibusdam quo consectetur sequi incidunt temporibus optio rem
              animi? Quasi, quam. Lorem ipsum dolor, sit amet consectetur
              adipisicing elit. Doloribus quis asperiores consequuntur
            </SubTitle>
          </div>
          <div className="flex gap-4">
            <Button
              type="button"
              className="flex gap-2 max-h-[40px] items-center bg-primaryblack text-white"
              onClick={() => {
                handleModal()
                setshowPromotionDiv(false)
              }}
            >
              <PlusSvg />
              Add
            </Button>
          </div>
        </div>

        {showPromotionDiv && <div className="">
          <div className="flex gap-x-2 items-center">
            <BackArrow />
            <SubTitle className="text-lg font-semibold not-italic mb-3 mt-4">View Transfer/Promotion</SubTitle>
          </div>
          <div className="grid gap-3 mb-3">
            {[1, 1, 1, 1].map((i, idx) => {
              return <Card className="flex justify-between px-3 py-2 bg-[#F4F4F4]">
                <div>
                  <SubTitle>Transaction ID</SubTitle>
                  <Title>NE120443</Title>
                </div>
                <div>
                  <SubTitle>Transaction Type</SubTitle>
                  <Title>Transfer</Title>
                </div>
                <div>
                  <SubTitle>Performed By</SubTitle>
                  <Title>Person Name</Title>
                </div>
                <div className="text-center">
                  <SubTitle>Date</SubTitle>
                  <Title>28/03/2023</Title>
                </div>
              </Card>
            })}


          </div>
        </div>}
        <div className="flex justify-center overflow-x-auto">
         <Tables
            data={transferPromotion}
            columns={transferpromotionColumns}
            pagination="true"
            search="true"
          />
        </div>
      </Card>
    </>
  );
};

export default TransferPromotion;
