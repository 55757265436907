import React, { useState, useRef, useEffect } from "react";
import Button from "../../elements/Button";
import Input from "../../elements/Input";
import Title from "../../elements/Title";
import SubTitle from "../../elements/SubTitle";
import Modal from "../../component/modal";
import { BackArrow, Spinner, } from "../../assest/svgs";
import OTP from "../../component/OTP";
import { PageLabel } from "../../constant/PageLabels";
import { useIsMutating } from "@tanstack/react-query";
import { GenerateOTPData, VerifyOTPData } from "../../service/api/auth";
import {
    EmailSvg,
    Locksvg,
} from "../../assest/svgs";
import { validateEmail } from "../../utils";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import RouteNames from "../../constant/RouteNames";

const UserIdOtp = () => {
    const location = useLocation()
    const { state } = location
    const [timer, setTimer] = useState(60);
    const [startTimer, setStartTimer] = useState(false);

    useEffect(() => {
        setStartTimer(true)
        let intervalId;

        if (startTimer) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        if (timer === 0) {
            clearInterval(intervalId);
        }

        // Clear the interval when the component is unmounted or when startTimer is set to false
        return () => clearInterval(intervalId);
    }, [startTimer, timer]);

    const [count, setcount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [resendClick, setResendClick] = useState(false);

    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const [subtitle, setSubTitles] = useState("");
    const [forgotpwdModel, setforgotpwdModel] = useState({
        email: state?.email,
    });

    const [errors, setErrors] = useState({
        email: { state: null, errorMessage: "" },
    });


    const [isBtnDisabled, setIsBtnDisabled] = useState(true);

    const modalImg = () => {
        switch (location.pathname) {
            case "/" + RouteNames.GET_USERID:
                return Locksvg();
            case "/" + RouteNames.GET_USERID + "/" + RouteNames.GET_USER_ID_OTP:
                return EmailSvg();
            default:
                break;
        }
    };


    const formSubmit = (e) => {
        e.preventDefault();
        if (otp.join("").length == 6) {
            verifyotpapi.mutate({
                email: forgotpwdModel?.email,
                otp: 1234,
                //  otp.join(''),
                context: "GET_USER_ID",
            });
            return;
        } else {
            return
        }

    };

    const handleModal = () => {
        setShowModal(false);
        navigate("/");
    };

    const generateotpapiResp = (data) => {
        if (data?.success === true) {
            setIsBtnDisabled(true)
            setStartTimer(true);
            setTitle("OTP Sent");
            setSubTitles(
                "A One-Time-Password sent to your registered Email ID. Please check."
            );
            setShowModal(true);
            setTimer(60);
        } else {
            toast.error("Something went wrong Please try After Sometime");
        }
    };
    const verifyotpapiResp = (data) => {
        if (data?.success === true) {
            setResendClick(false);
            setTitle("Verified Succesfully");
            setSubTitles("Your User ID has been sent to your registered email ID.");
            setShowModal(true);
        } else {
            toast.error("Something went wrong Please try After Sometime");
        }
    };

    const generateotpapi = GenerateOTPData(generateotpapiResp);
    const verifyotpapi = VerifyOTPData(verifyotpapiResp);



    const [otp, setOTP] = useState(["", "", "", "", "", ""]);

    const inputRefs = useRef([]);

    // Handle OTP change event
    const handleOTPChange = (e, index) => {
        const value = e.target.value;
        const updatedOTP = [...otp];
        updatedOTP[index] = value;
        setOTP(updatedOTP);

        // If the input value is numeric and not empty,
        // move focus to the next input field
        if (/^\d*$/.test(value) && value.length < 2 && index < 5) {
            const nextInputRef = inputRefs.current[index + 1];
            if (nextInputRef) {
                nextInputRef.focus();
            }
        }
    };

    // Handle keydown event for backspace/delete key
    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" || e.key === "Delete") {
            if (index > 0 && e.target.value === "") {
                e.preventDefault();
                const previousInputRef = inputRefs.current[index - 1];
                if (previousInputRef) {
                    previousInputRef.focus();
                }
            }
        }
    };



    const otpResend = () => {
        setTimer(60)
        setResendClick(true);
        if (timer === 0) {
            setOTP(["", "", "", "", "", ""]);
            generateotpapi.mutate({
                email: forgotpwdModel?.email,
                context: "VERIFY_EMAIL",
            });
        } else {
            toast("Please wait for timer to finish");
        }
    };

    const handleBack = () => {

        setforgotpwdModel({
            email: "",
        })
        setOTP(["", "", "", "", "", ""])
        setErrors({
            email: { state: null, errorMessage: "" },
        })
        if (count === 0) navigate("/");
        else setcount((prev) => prev - 1);
    };

    useEffect(() => {
        const value = otp.join("");

        if (value.length < 6) {
            setIsBtnDisabled(true);
        } else {
            setIsBtnDisabled(false);
        }
    }, [otp]);

    const isMutating = useIsMutating()


    return (
        <>
            <Modal
                type="confirmation"
                showModal={showModal}
                setShowModal={setShowModal}
                handleModal={handleModal}
                title={title}
                SVG={modalImg}
                subtitle={subtitle}
            />
            <section className="w-full grid items-center ">
                <form className="mt-20 p-4" onSubmit={formSubmit}>
                    <div
                        className="flex items-center gap-1  w-fit cursor-pointer mb-6"
                        onClick={handleBack}
                    >
                        <BackArrow />
                        <SubTitle className="font-semibold ">Back</SubTitle>
                    </div>
                    <Title className="text-3xl font-semibold not-italic ">
                        Verify OTP
                    </Title>
                    <SubTitle className="text-['#555555']">
                        Please enter the OTP received to your email/phone number
                    </SubTitle>
                    <div className="">
                        <OTP
                            otp={otp}
                            handleOTPChange={handleOTPChange}
                            handleKeyDown={handleKeyDown}
                            inputRefs={inputRefs}
                            timer={timer}
                            resendClick={otpResend}
                        />
                    </div>

                    <div className="flex justify-end">
                        <Button className="bg-primaryblack text-white w-1/6 max-w-fit"
                            disabled={isBtnDisabled}
                        >
                            {PageLabel?.formHeaders?.VERIFY_OTP}
                            {isMutating > 0 ? <Spinner /> : <></>}

                        </Button>
                    </div>
                </form>
            </section>
        </>
    );
};

export default UserIdOtp;
