import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface CardProps {
  className?: string;
  children?: ReactNode;
}

const Card: React.FC<CardProps> = (props) => {
    const { className, children }=props
  const classes = className ? twMerge('p-2 bg-white rounded-lg', className) : 'p-2 bg-white rounded-lg';

  return <div className={`shadow ${classes}`}>{children}</div>;
};

export default Card;
