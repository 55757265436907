import React from "react";

import ConfirmationModal from "./Confirmation";
import WelcomeDashboard from "./WelcomeDashboard";
import Onboarding from "./Onboarding";
import RightSide from "./RightSide";
import Center from "./Center";
import Node from "./Node";
import ModalPortal from "./PortalModalLogout";
import Loader from "./loader";
import LargeModal from "./LargeModal";
// import Toaster from "./Toaster";

const Modal = (props) => {
  const { showModal, type } = props;

  const modelBody = (type, propsParams) => {
    switch (type) {
      case "welcome":
        return <WelcomeDashboard {...propsParams} />;
      case "logout":
        return <ModalPortal {...propsParams} />;
        // case "toaster":
        //   return <Toaster {...propsParams} />;
        
      case "confirmation":
        return <ConfirmationModal {...propsParams} />;
      case "center":
        return <Center {...propsParams} />;
        case "large":
          return <LargeModal {...propsParams} />;
        
      case "loader":
        return <Loader {...propsParams} />;

      case "onboarding":
        return <Onboarding {...propsParams} />;
      case "rightside":
        return <RightSide {...propsParams} />;
      case "node":
        return <Node {...propsParams} />;

      default:
        return <div>NO DATA</div>;
    }
  };

  return (
    <>
      {showModal ? modelBody(type, props) : null}
    </>
  );
};

export default Modal;
