import React from 'react'
import { twMerge } from 'tailwind-merge'
import { Searchsvgs } from '../../assest/svgs'


const InputSearch = (props) => {

    const classes = twMerge("w-full pl-3 pr-10 py-2 border-2 border-gray-200 rounded-xl hover:border-gray-300 focus:outline-none focus:border-blue-500 transition-colors",props?.className)
    
    return (
        <div className="relative mt-1">
            <input type="text" id="password" className={classes} placeholder="Search"
            onChange={props?.onChange}
            value={props?.value}
            
            />
            <button className="block w-7 h-7 text-center text-xl leading-0 absolute top-2 right-2 text-gray-400 focus:outline-none hover:text-gray-900 transition-colors">
                <Searchsvgs />
            </button>
        </div>)
}

export default InputSearch