import React from 'react'
import Title from '../../elements/Title'
import SubTitle from '../../elements/SubTitle'
import Button from '../../elements/Button'
import { Ticksvg,ModalBodysvg } from "../../assest/svgs";


const WelcomeDashboard = (props) => {
    const { showModal, handleModal, title, subtitle, setShowModal } = props

    return (
        <>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative  max-w-5xl min-w-[20rem] w-3/5 ">

                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none  p-4">
                                <div className="relative flex-auto text-center">
                                    <div className='absolute right-2 cursor-pointer'
                                        onClick={() => { setShowModal(false) }}
                                    >
                                        <Ticksvg />
                                    </div>

                                    <Title
                                        className=" text-lg mt-2"
                                    >{title}</Title>

                                    <div className="m-auto w-11/12">

                                        <SubTitle className="text-center">{subtitle}</SubTitle>
                                    </div>
                                    <div className='bg-[#F4F4F4] w-full py-2 mx-2 rounded-lg mt-2'>
                                        <SubTitle className="">Let us take you to</SubTitle>
                                        <Title
                                            className="text-[14px]"
                                        >Onboarding Process</Title>
                                    </div>
                                    <div className=' relative max-w-lg m-auto'>
                                        <ModalBodysvg />
                                        
                                    </div>
                                    <div className='w-full flex justify-center'>

                                        <Button
                                            onClick={()=>setShowModal(false)}
                                            type="button"
                                            className=" bg-gray-800 flex gap-2  justify-center shadow-2xl hover:bg-gray-600 text-gray-200 max-w-fit"
                                        >Lets get started</Button>

                                    </div>


                                </div>


                            </div>

                        </div>
                    </div>
                    <div className="opacity-80 fixed inset-0 z-40 bg-black"></div>

                </>
            ) : null}
        </>)
}

export default WelcomeDashboard