import { useMutation } from '@tanstack/react-query'
import { getAPI, postAPI } from '../../ApiMethods'
import API_NAMES from '../../../constant/API_Names'
import { toast } from 'react-hot-toast'

export const Registerdata = (onSuccess, onError) => {
  return useMutation({
    mutationKey: [API_NAMES?.SIGNUP],
    mutationFn: async (payload) => {
      const response = await postAPI(API_NAMES?.SIGNUP, payload)
      return response
    },
    onSuccess: (data, variable, context) => {
      onSuccess(data)
    },
    onError: (error) => {
      if (error?.response?.data?.success === false) {
        toast.error(error?.response?.data?.errors?.message)
      } else {
        toast.error(error?.response?.data?.errors?.message)
      }
    },
    retry: 0,
    staleTime: Infinity,
  })
}
