import React from 'react'

const EnrollmentStatus = () => {
  return (
    <div>
        <div className="m-8 pt-6 animate-pulse">
              <div className="w-full h-2/3 flex gap-4">
                <div className="w-5  h-5 bg-black rounded-lg"></div>
                <div className=" w-16 text-sm rounded-lg font-light">
                  Completed
                </div>
                <div className="w-20 border-red-400 h-5 bg-gray-300 rounded-lg"></div>
              </div>
              <div className="w-full mt-2 h-2/3 flex gap-4">
                <div className="w-5  h-5 bg-slate-400 rounded-lg"></div>
                <div className="w-16 text-sm rounded-lg font-light">
                  Pending
                </div>
                <div className="w-20 border-red-400 h-5 bg-gray-300 rounded-lg"></div>
              </div>
            </div>
    </div>
  )
}

export default EnrollmentStatus