import React, { useState } from "react";



const DashboardFeatures = () => {
  return (
    <div>
      <div>hi</div>
        
    </div>
  );
};

export default DashboardFeatures;
