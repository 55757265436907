import React from "react";
import { Ticksvg } from "../../assest/svgs";

const Center = (props) => {
  const { showModal, setShowModal } = props;

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative min-w-[420px] w-10/12 max-w-lg">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none  p-4  overflow-y-auto">
                <div className="relative w-full">
                  <div
                    className="absolute right-2 cursor-pointer"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    <Ticksvg />
                  </div>
                  {props?.children}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-80 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Center;
