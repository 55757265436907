import { useState, useRef, useEffect } from "react";
import Button from "../../elements/Button";
import Input from "../../elements/Input";
import Title from "../../elements/Title";
import SubTitle from "../../elements/SubTitle";
import Modal from "../../component/modal";
import { BackArrow, Locksvg, Spinner } from "../../assest/svgs";
import OTP from "../../component/OTP";
import { useLocation, useNavigate } from "react-router-dom";
import { PageLabel } from "../../constant/PageLabels";
import { useIsMutating } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import {
    GenerateOTPData,
    ResetPasswordData,
    VerifyOTPData,
} from "../../service/api/auth";
import { validateEmail, validateIsNumAndAlp } from "../../utils";
import RouteNames from "../../constant/RouteNames";

const SetNewPassword = () => {
    const location = useLocation()
    const { state } = location


    const [count, setcount] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const [subtitle, setSubTitles] = useState("");
    const [resendClick, setResendClick] = useState(false);
    const [validForm, setvalidForm] = useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [errors, setErrors] = useState({
        email: { state: null, errorMessage: "" },
        password: { state: null, errorMessage: "" },
        cnfpassword: { state: null, errorMessage: "" },
    });
    const [forgotpwdModel, setforgotpwdModel] = useState({
        email: state?.email,
        password: "",
        cnfpassword: "",
    });

    const handleBack = () => {
        navigate('/' + RouteNames?.FORGOT_PASSWORD)
    };

    const HandleChange = (e) => {
        const { name, value } = e?.target;

        if (name == "email") {
            const validEmail = validateEmail(value);
            setvalidForm((prev) => validEmail?.state);
            setErrors((prevState) => ({
                ...prevState,
                [name]: validEmail,
            }));
        }
        if (name == "password" || name == "cnfpassword") {
            const validPassword = validateIsNumAndAlp(value);
            setvalidForm((prev) => validPassword?.state);
            setErrors((prevState) => ({
                ...prevState,
                [name]: validPassword,
            }));
        }

        if (name == "checked")
            setforgotpwdModel({
                ...forgotpwdModel,
                [name]: !setforgotpwdModel?.checked,
            });
        else setforgotpwdModel({ ...forgotpwdModel, [name]: value });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        if (forgotpwdModel?.password === forgotpwdModel?.cnfpassword) {
            if (
                errors?.password?.state === false &&
                errors?.cnfpassword?.state === false
            ) {
                resetpwdapi.mutate({
                    userName: forgotpwdModel?.email,
                    password: forgotpwdModel?.password,
                });
            } else {
                return
            }
        } else {
            toast.error("Password and Confirm Password should be same");
        }
        return;

    };

    const handleModal = () => {
        setShowModal(false);
        navigate("/");
    };

    const resetpwdapiResp = (data) => {
        if (data?.success === true) {
            setTitle("Password Set Successfully");
            setSubTitles(
                "Redirecting to Login Page. Please login with your new password."
            );
            setShowModal(true);
        } else {
            toast.error("Something went wrong Please try After Sometime");
        }
    };

    const resetpwdapi = ResetPasswordData(resetpwdapiResp);

    const [otp, setOTP] = useState(["", "", "", "", "", ""]);

    const inputRefs = useRef([]);


    // Handle keydown event for backspace/delete key
    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" || e.key === "Delete") {
            if (index > 0 && e.target.value === "") {
                e.preventDefault();
                const previousInputRef = inputRefs.current[index - 1];
                if (previousInputRef) {
                    previousInputRef.focus();
                }
            }
        }
    };
    useEffect(() => {
        const value = otp.join("");

        if (value.length < 6) {
            setIsBtnDisabled(true);
        } else {
            setIsBtnDisabled(false);
        }
    }, [otp]);



    useEffect(() => {
        if (
            errors?.password?.state === false &&
            errors?.cnfpassword?.state === false
        ) {
            setIsBtnDisabled(false);
        }

    }, [forgotpwdModel]);


    const isMutating = useIsMutating();
    return (
        <>
            <Modal
                type="confirmation"
                showModal={showModal}
                setShowModal={setShowModal}
                handleModal={handleModal}
                title={title}
                SVG={Locksvg}
                subtitle={subtitle}
            />
            <section className="w-full grid items-center">
                <form className="mt-20 p-4" onSubmit={formSubmit}>
                    <div
                        className="flex items-center gap-1  w-fit cursor-pointer mb-6"
                        onClick={handleBack}
                    >
                        <BackArrow />
                        <SubTitle className="font-semibold ">
                            {" "}
                            {PageLabel?.buttonText?.BACK}
                        </SubTitle>
                    </div>

                    <Title className="text-3xl font-semibold not-italic">
                        {" "}
                        {PageLabel?.formHeaders?.SET_PWD}
                    </Title>
                    <SubTitle className="my-3">
                        {PageLabel?.formHeaders?.SET_UP_YOUR_PASSWORD}

                        <div className="flex items-center gap-1 mt-2 cursor-pointer">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillrule="evenodd"
                                    cliprule="evenodd"
                                    d="M8.00026 2.56644C4.99951 2.56644 2.56693 4.99903 2.56693 7.99977C2.56693 11.0005 4.99951 13.4331 8.00026 13.4331C11.001 13.4331 13.4336 11.0005 13.4336 7.99977C13.4336 4.99903 11.001 2.56644 8.00026 2.56644ZM1.43359 7.99977C1.43359 4.3731 4.37359 1.43311 8.00026 1.43311C11.6269 1.43311 14.5669 4.3731 14.5669 7.99977C14.5669 11.6264 11.6269 14.5664 8.00026 14.5664C4.37359 14.5664 1.43359 11.6264 1.43359 7.99977ZM8.00026 6.76644C8.31322 6.76644 8.56693 7.02014 8.56693 7.33311V10.6664C8.56693 10.9794 8.31322 11.2331 8.00026 11.2331C7.6873 11.2331 7.43359 10.9794 7.43359 10.6664V7.33311C7.43359 7.02014 7.6873 6.76644 8.00026 6.76644ZM8.00026 5.99977C8.36845 5.99977 8.66693 5.70129 8.66693 5.33311C8.66693 4.96492 8.36845 4.66644 8.00026 4.66644C7.63207 4.66644 7.33359 4.96492 7.33359 5.33311C7.33359 5.70129 7.63207 5.99977 8.00026 5.99977Z"
                                    fill="#1F2937"
                                />
                            </svg>
                            <span className="text-[#4BC8FE]">
                                {PageLabel?.commonLabels?.PRIVACY_POLICY}
                            </span>
                        </div>
                    </SubTitle>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 ">
                        <Input
                            labeltext={PageLabel?.inputlabels?.NEW_PWD}
                            type={"password"}
                            value={forgotpwdModel.password}
                            error={errors?.password?.state}
                            errorMessage={errors?.password?.errorMessage}
                            onChange={HandleChange}
                            name={"password"}
                            required
                        />

                        <Input
                            labeltext={PageLabel?.inputlabels?.CNF_PWD}
                            type={"password"}
                            value={forgotpwdModel.cnfpassword}
                            error={errors?.cnfpassword?.state}
                            errorMessage={errors?.cnfpassword?.errorMessage}
                            onChange={HandleChange}
                            name={"cnfpassword"}
                            required
                        />
                    </div>
                    <div className="flex justify-end my-2">
                        <Button className="bg-primaryblack text-white w-1/6 max-w-fit"
                            disabled={isBtnDisabled}
                        >
                            {PageLabel?.buttonText?.SUBMIT}
                            {isMutating > 0 ? <Spinner /> : <></>}
                        </Button>
                    </div>
                </form>
            </section>
        </>
    );
};

export default SetNewPassword;
