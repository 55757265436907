import React from 'react'

const EnrollmentCircle = (props) => {
  return (
    <div>
                   <div className="w-full flex justify-center my-8 animate-pulse">
              <div className="w-48 md:w-24 md:h-24 lg:w-48 lg:h-48 h-48 bg-gray-100 rounded-full flex items-center justify-between border-black border-8">
                <div className="w-full h-full border-4 rounded-full">
                  <div className="w-full h-full  border-8 rounded-full border-gray-500 flex items-center justify-center">
                    <div className="flex-row items-center justify-center">
                      <div className="font-normal text-sm">Total {props?.text}</div>
                      <div className=" h-5 bg-gray-300 border-red-500 items-center justify-center rounded-lg"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </div>
  )
}

export default EnrollmentCircle