import React, { ReactNode, MouseEventHandler } from 'react';
import { twMerge } from 'tailwind-merge';

interface SubTitleProps {
  className?: string;
  onClick?: MouseEventHandler<HTMLHeadingElement>;
  children?: ReactNode;
}

const SubTitle: React.FC<SubTitleProps> = (props) => {
  const { className, onClick, children }=props
  const classes = twMerge('text-xs font-thin', className);

  return (
    <h1 className={classes} onClick={onClick}>
      {children}
    </h1>
  );
};

export default SubTitle;
