import React, { useEffect } from "react";
import { twMerge } from "tailwind-merge";
import {
    Datepicker,
    Input,
    initTE,
} from "tw-elements";
const Calender = (props) => {
    useEffect(() => {
        new initTE({ Datepicker, Input});
        const datepickerWithFilter = document.getElementById(props.id);
        const filterFunction = (date) => {
            const isSaturday = date.getDay() === 6;
            const isSunday = date.getDay() === 0;
            return !isSaturday && !isSunday;
        }

        new Datepicker(datepickerWithFilter, { filter: filterFunction });

    }, []);


    return (

        <>
        <label
              htmlFor={props.labeltext}
              className={twMerge("decoration-solid ml-2 text-xs font-medium text-gray-900 dark:text-gray-300 hover:cursor-pointer", props.className)}
            >
              {props.labeltext}
            </label>
            <div
            className="relative mb-3 w-fit"
            id={props.id}
            data-te-input-wrapper-init>
            <input
                type="text"
                className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                placeholder={"Select a date"}
                {...props}
                data-te-datepicker-toggle-ref
            />
        </div>
        </>
       
    )
}

export default Calender