import React from 'react'
import Title from '../../../elements/Title'
import SubTitle from '../../../elements/SubTitle'
import Input from '../../../elements/Input'
import SkeletonCard from '../../../elements/SkeletonCard'

const OrganisationProfile = () => {
    let error = false
    let errorMessage = "hi i am error"
    return (
        < >
            <div className='px-2'>

                <Title className=" text-sm ">
                    Basic Details
                </Title>
                <SubTitle className=" w-3/6">
                    To onboard your organization, we require basic information. We keep all information confidential and use it to tailor our services to meet your business needs.                </SubTitle>
                <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                    <Input
                        labeltext={"Organization Name"}
                        placeholder={"••••••••"}
                        type={"text"}
                        error={error}
                        errorMessage={errorMessage}
                        // value={model.orgname}
                        // onChange={HandleChange}
                        name={"orgname"}
                    />
                    <Input
                        labeltext={"Main Branch Location"}
                        placeholder={"••••••••"}
                        type={"text"}
                        // value={model.orgname}
                        // onChange={HandleChange}
                        name={"orgname"}
                    />
                    <Input
                        labeltext={"Admin Name"}
                        placeholder={"••••••••"}
                        type={"text"}
                        // value={model.orgname}
                        // onChange={HandleChange}
                        name={"orgname"}
                    />
                    <Input
                        labeltext={"Offical Email ID"}
                        placeholder={"••••••••"}
                        type={"text"}
                        // value={model.orgname}
                        // onChange={HandleChange}
                        name={"orgname"}
                    />
                    <Input
                        labeltext={"Offical Mobile Number"}
                        placeholder={"••••••••"}
                        type={"text"}
                        // value={model.orgname}
                        // onChange={HandleChange}
                        name={"orgname"}
                    />
                    <Input
                        labeltext={"Offical Landline Number"}
                        placeholder={"••••••••"}
                        type={"text"}
                        // value={model.orgname}
                        // onChange={HandleChange}
                        name={"orgname"}
                    />
                    <Input
                        labeltext={"School Address 1"}
                        placeholder={"••••••••"}
                        type={"text"}
                        // value={model.orgname}
                        // onChange={HandleChange}
                        name={"orgname"}
                    />
                    <Input
                        labeltext={"School Address 2"}
                        placeholder={"••••••••"}
                        type={"text"}
                        // value={model.orgname}
                        // onChange={HandleChange}
                        name={"orgname"}
                    />

                    <Input
                        labeltext={"Longitude"}
                        placeholder={"••••••••"}
                        type={"text"}
                        // value={model.orgname}
                        // onChange={HandleChange}
                        name={"orgname"}
                    />
                    <Input
                        labeltext={"Latitude"}
                        placeholder={"••••••••"}
                        type={"text"}
                        // value={model.orgname}
                        // onChange={HandleChange}
                        name={"orgname"}
                    />

                </div>
                <div className=" my-4">
                    <SkeletonCard className="w-full h-60" />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-2">
                    <Input
                        type="file"
                        labeltext="hi"
                        placeholder="Organisation image logo"
                        filesize="max. 1000*900 px"
                    />
                    <Input
                        type="file"
                        labeltext="hi"
                        placeholder="login image"
                        filesize="max. 1000*900 px"
                    />
                </div>



            </div>
        </>
    )
}

export default OrganisationProfile