const Node = (props) => {
    const { showModal, setShowModal} = props;
    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}

                    >
                        <div className="relative ">
                            {/*content*/}
                            <div
                                onClick={e => {
                                    // do not close modal if anything inside modal content is clicked
                                    e.stopPropagation();
                                }}
                                className="border-0 rounded-lg shadow-lg relative flex w-full bg-white outline-none focus:outline-none  p-4  overflow-y-auto">
                                <div className="relative flex-auto text-center">
                                    {props?.children}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-80 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
};

export default Node;
