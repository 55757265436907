import React from "react";
import Title from "../../elements/Title";
import SubTitle from "../../elements/SubTitle";
import { CloseSvg } from "../../assest/svgs";

const ConfirmationModal = (props) => {
  const { showModal, handleModal, title, subtitle, redirectTitle, seconds } = props;


  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto max-w-[390px] min-w-xl w-6/12">
              <div className="border-[12px] border-[#FFFFFF] bg-white rounded-full w-fit m-auto relative top-10 z-50">
                <div className={`rounded-full w-fit m-auto`}>
                  {props?.SVG()}
                </div>
              </div>

              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                <div className=" font-semibold w-full m-auto mt-2  flex justify-end pr-2">
                  {" "}
                  <CloseSvg onClick={handleModal} />
                </div>
                <div className="relative p-6 flex-auto text-center">
                  {redirectTitle && <Title className="font-normal mb-2">{redirectTitle}</Title>}
                  {seconds && <Title className="font-normal mb-2">{seconds}</Title>}
                  <Title className="text-xl">{title}</Title>
                  <SubTitle className="font-normal">{subtitle}</SubTitle>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ConfirmationModal;
